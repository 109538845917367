import React, { Component } from 'react';
//import Queue from './Queue'
// import Results from './Results'
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TablePagination from '@material-ui/core/TablePagination';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import HSBar from 'react-horizontal-stacked-bar-chart';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import './Results.css';
import Resultchart from './Resultchart';
import './MyDashboard.css';
//import Webdatarockstable from './WebDataRocksTable'
var DetailedExecutionReportApi = '';
class MyDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      queveresult: [],
      filter: '',
      offset: 0,
      // parPage: 5,
      //currentPage: 0,
      rowsPerPage: 5,
      page: 0,
      filteredData: [],
      is_Queue_data: false,
      results: [],
      currentPage: 1,
      perPage: 10,
      is_Api_Result: false,
      lastTenId: [],
      children: [],
      datanew: [],
      DetailedExecutionReportApi: '',
    };
    this.deleteRow = this.deleteRow.bind(this);
    this.handleChangepage = this.handleChangepage.bind(this);
  }
  handleChangepage(event) {
    this.setState({
      currentPage: Number(event.target.innerText),
    });
  }

  deleteRow(index) {
    let queveresult = [...this.state.queveresult];
    queveresult.splice(index, 1);
    this.setState({ queveresult: queveresult });
  }

  handleChange = (event) => {
    this.setState({ filter: event.target.value });
  };
  handleSortAsc = (event, sortKey) => {
    const data = this.state.queveresult;
    data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    this.setState({ queveresult: data });
  };
  handleSortDsc = (event, sortKey) => {
    const data = this.state.queveresult;
    data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    this.setState({ queveresult: data });
  };
  handleChangePage = (event, newPage) => {
    //console.log("newPage", newPage);
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };
  formateJson = () => {
    const allData = [];
    const data = this.state.datanew;
    const os = Object.keys(data);
    os.forEach((key) => {
      //console.log("os keys", data[key]);
      const ecArr = data[key].execution_combination.split('-');
      data[key].Env = ecArr[0];
      data[key].Suite = ecArr[1];
      data[key].Browser = ecArr[2];
      data[key].osName = ecArr[3];
      allData.push(data[key]);
    });
    this.setState({ queveresult: allData });
  };
  storealldata = () => {
    const dashboard = [];
    let userEmail = sessionStorage.getItem('email');

    this.state.queveresult.forEach((val, ind, arr) => {
      if (val.email === userEmail) {
        dashboard.push(val);
      }
    });

    this.setState({ queveresult: dashboard });
  };
  formateJSONDashboard = (moduleData, priorityData, ind) => {
    const priorityDatalabal = priorityData.ColumnKeys;
    const percentageValue1 = priorityData.Values;

    var priorityDataPass = [];
    var priorityDataError = [];
    var priorityDataFail = [];
    var priorityDataLabels = [];

    const removeNullValue1 = (arr1) => {
      return arr1.map((e) => (e == null ? 0 : e));
    };
    percentageValue1.forEach((val, i) => {
      const array = removeNullValue1(val);
      if (i === 0) {
        priorityDataPass.push(...array);
      }
      if (i === 1) {
        priorityDataError.push(...array);
      }
      if (i === 2) {
        priorityDataFail.push(...array);
      }
    });
    for (const dataObj of priorityDatalabal) {
      priorityDataLabels.push(dataObj[0] + ' ' + dataObj[1]);
    }
    const priorityObject = {
      labals: priorityDataLabels,
      columnData: [priorityData.RowKeys.toString()],
      data: [
        [...priorityDataPass],
        [...priorityDataError],
        [...priorityDataFail],
      ],
    };
    const label = [moduleData.ColumnKeys.toString()];
    const percentageValue = moduleData.Values;

    var moduleDataPass = [];
    var moduleDataError = [];
    var moduleDataFail = [];

    const removeNullValue = (arr) => {
      return arr.map((e) => (e == null ? 0 : e));
    };
    percentageValue.forEach((val, i) => {
      const array = removeNullValue(val);
      if (i === 0) {
        moduleDataPass.push(...array);
      }
      if (i === 1) {
        moduleDataError.push(...array);
      }
      if (i === 2) {
        moduleDataFail.push(...array);
      }
    });

    const moduleObject = {
      labals: label.toString().split(','),
      columnData: [moduleData.RowKeys.toString()],
      data: [[...moduleDataPass], [...moduleDataError], [...moduleDataFail]],
    };

    const commansprops = { moduleObject, priorityObject };
    var array = [...this.state.children, <Resultchart text={commansprops} />];
    if (this.state.children.length === 1) {
      this.setState({
        children: [],
      });
      setTimeout(() => {
        this.setState({
          children: [<Resultchart text={commansprops} />],
        });
      }, 0);
    } else if (this.state.children.length === 0) {
      this.setState({
        children: [array],
      });
    }
  };

  apicall = (ind) => {
    let { index } = this.state;
    let seekTableUrl = JSON.parse(sessionStorage.getItem('selectedSiteData'));
    let moduleUrl = seekTableUrl.SeektableURL.Module;
    let priorityUrl = seekTableUrl.SeektableURL.Priority;
    let detailUrl = seekTableUrl.SeektableURL.DetailedExecutionReport;
    if (ind !== index) {
      index = ind;
      let priorityResult = '';
      let moduleApi = `${moduleUrl}/export/json?report_parameters={"executionId":["${ind}"]}`;
      let priorityApi = `${priorityUrl}/export/json?report_parameters={"executionId":["${ind}"]}`;
      DetailedExecutionReportApi = `${detailUrl}?report_parameters={"executionId":["${ind}"]}`;
      const moduleHttp = axios.get(moduleApi);
      const priorityHttp = axios.get(priorityApi);
      priorityHttp.then((result) => {
        priorityResult = result.data;
        moduleHttp.then((result) => {
          let moduleResult = result.data;
          this.formateJSONDashboard(moduleResult, priorityResult, ind);
        });
      });
    } else {
      index = ind;
    }
  };
  componentDidMount() {
    var user = sessionStorage.getItem('email');
    var site = sessionStorage.getItem('selectedSite');
    axios
      .get(window.configs.REPORT_SERVICE_URL + 'execution-status/' + site)
      .then((result) => {
        var data = result.data;
        this.setState({
          datanew: data,
          is_Queue_data: true,
        });
      })
      .catch((error) =>
        this.setState({
          error,
          is_Queue_data: false,
        })
      );
    let one =
      window.configs.USER_SERVICE_URL + `user-executions/` + site + `/` + user;
    const requestone = axios.get(one);
    requestone.then((result) => {
      this.setState(
        {
          lastTenId: result.data,
        },
        () => {
          this.state.lastTenId.forEach((val, i, arr) => {
            var site = sessionStorage.getItem('selectedSite');
            axios
              .get(
                window.configs.REPORT_SERVICE_URL +
                  'report-details/' +
                  site +
                  '/' +
                  val +
                  '?'
              )
              .then((result) => {
                var rjson1 = result.data;

                this.setState({
                  is_Api_Result: true,
                  results: [...this.state.results, rjson1],
                });
              })
              .catch((error) =>
                this.setState({
                  error,
                  //is_Api_Result: false
                })
              );
          });
        }
      );
    });
    setTimeout(() => {
      this.formateJson();
      this.storealldata();
    }, 1000);
  }
  render() {
    const { results, currentPage, perPage } = this.state;
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    const selectedResult = results.slice(indexOfFirst, indexOfLast);
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(results.length / perPage); i++) {
      pageNumbers.push(i);
    }

    const lowercasedFilter = this.state.filter.toLowerCase();
    let filterday = [...this.state.filteredData];

    filterday = this.state.queveresult.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toLowerCase().includes(lowercasedFilter)
      );
    });
    const myStyel = {
      fontSize: 'small',
      color: 'rgba(0, 0, 0, 0.87)',
    };
    return (
      <React.Fragment>
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg1" id="TestResult">
                My Dashboard
              </label>
            </div>
          </div>
        </div>
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg1" id="TestResult">
                View Scheduled Tests
              </label>
            </div>
          </div>
        </div>

        {this.state.is_Queue_data === true && (
          <div className="card">
            <div className="card-body" style={{ padding: '0px' }}>
              <div className="MainSearch">
                <label>
                  <input
                    type="search"
                    className="search"
                    placeholder="Search"
                    value={this.state.filter}
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <TableContainer component={Paper}>
                <table arial-label="simple table" className="QueueTable">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ position: 'relative', left: '7px' }}
                      >
                        Env
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortAsc(e, 'Env')}
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortDsc(e, 'Env')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        Suite
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortAsc(e, 'Suite')}
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortDsc(e, 'Suite')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        OS
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortAsc(e, 'osName')}
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortDsc(e, 'osName')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        Browser
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortAsc(e, 'Browser')}
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortDsc(e, 'Browser')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        User
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortAsc(e, 'user')}
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortDsc(e, 'user')}
                        />
                      </TableCell>
                      <TableCell align="center">
                        Request Time
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) =>
                            this.handleSortAsc(e, 'trigger_date_time')
                          }
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) =>
                            this.handleSortDsc(e, 'trigger_date_time')
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        Scheduled Time
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) =>
                            this.handleSortAsc(e, 'scheduled_date_time')
                          }
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) =>
                            this.handleSortDsc(e, 'scheduled_date_time')
                          }
                        />
                      </TableCell>
                      <TableCell align="center">
                        Status
                        <ArrowUpwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortAsc(e, 'status')}
                        />
                        <ArrowDownwardIcon
                          style={myStyel}
                          onClick={(e) => this.handleSortDsc(e, 'status')}
                        />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filterday
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map(
                        (result, i) =>
                          result.status !== 'started' && (
                            <TableRow key={i}>
                              <TableCell>
                                <span className="env"> {result.Env}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="tag">{result.Suite}</span>
                              </TableCell>
                              <TableCell align="center">
                                <img
                                  className="queuepageimg"
                                  alt="os"
                                  src={`../images/${
                                    result.osName
                                      .toLowerCase()
                                      .search('windows') !== -1
                                      ? 'windows'
                                      : result.osName
                                          .toLowerCase()
                                          .search('linux') !== -1
                                      ? 'linux'
                                      : 'ios'
                                  }.png `}
                                  title={`${
                                    result.osName
                                      .toLowerCase()
                                      .search('windows') !== -1
                                      ? 'windows'
                                      : result.osName
                                          .toLowerCase()
                                          .search('linux') !== -1
                                      ? 'linux'
                                      : 'ios'
                                  }`}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <img
                                  className="queuepageimg"
                                  alt="browser"
                                  src={`../images/${
                                    result.Browser.charAt(0).toLowerCase() +
                                    result.Browser.slice(1)
                                  }.png`}
                                  title={`${
                                    result.Browser.charAt(0).toLowerCase() +
                                    result.Browser.slice(1)
                                  }`}
                                />
                              </TableCell>
                              <TableCell align="center">
                                <span className="tag">{result.user}</span>
                              </TableCell>
                              <TableCell align="center">
                                {result.trigger_date_time}
                              </TableCell>
                              <TableCell align="center">
                                {result.scheduled_date_time}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    width: '100%',
                                    height: '38px',
                                    color: '#1976D2 !important',
                                  }}
                                  onClick={(i) => this.deleteRow(i)}
                                  className="btn-cust"
                                >
                                  {result.status}
                                  <HighlightOffIcon className="cancel_svg" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </table>
                {this.state.queveresult.length === 0 && (
                  <div style={{ textAlign: 'left', marginLeft: '30px' }}>
                    Nothing's queued for a moment
                  </div>
                )}
              </TableContainer>
              <div></div>

              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={this.state.queveresult.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
        {this.state.is_Queue_data === false && (
          <div className="card error_queue">Waiting to get Queue list...</div>
        )}

        <br />
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg1">Last 10 Results</label>
            </div>
          </div>
        </div>
        {this.state.is_Api_Result === true && (
          <Card className="card" style={{ paddingTop: '10px' }} id="accordion">
            {selectedResult.map((resultList, index) => (
              <Card
                key={index}
                className="subcard"
                style={{ fontSize: '16px', cursor: 'pointer' }}
                data-toggle="collapse"
                href={'#collapseExample' + index}
                onClick={() => this.apicall(resultList.executionId)}
              >
                <CardContent>
                  <div className="row">
                    <div className="col-sm-10 left-align">
                      <span className="info">
                        {resultList.execution_date}-
                        {resultList.execution_start_time}
                      </span>
                      <span className="info">{resultList.environment}</span>
                      <span className="info"> {resultList.testsuitname}</span>
                      <span className="info"> {resultList.user_name}</span>
                      <span className="info">
                        {' '}
                        Count: {resultList.totalcount}
                      </span>
                    </div>

                    <div className="col-sm-2 right-align">
                      <img
                        alt="os"
                        src={`../images/${
                          resultList.os_name.toLowerCase().search('windows') !==
                          -1
                            ? 'windows'
                            : resultList.os_name
                                .toLowerCase()
                                .search('linux') !== -1
                            ? 'linux'
                            : 'ios'
                        }.png`}
                        title={`${
                          resultList.os_name.toLowerCase().search('windows') !==
                          -1
                            ? 'windows'
                            : resultList.os_name
                                .toLowerCase()
                                .search('linux') !== -1
                            ? 'linux'
                            : 'ios'
                        }`}
                      />
                      <img
                        alt="browser"
                        src={`../images/${
                          resultList.browser.charAt(0).toLowerCase() +
                          resultList.browser.slice(1)
                        }.png`}
                        title={`${
                          resultList.browser.charAt(0).toLowerCase() +
                          resultList.browser.slice(1)
                        }`}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="a1 col-sm-10">
                      <HSBar
                        showValueIn
                        height={25}
                        data={[
                          {
                            value: 8 + Number(`${resultList.pass_percent}`),
                            description: `${resultList.pass_percent}%`,
                            color: '#009EEB',
                            name: 'Pass',
                          },
                          {
                            value: 8 + Number(`${resultList.error_percent}`),
                            description: `${resultList.error_percent}%`,
                            color: '#003C5B',
                            name: 'Error',
                          },
                          {
                            value: 8 + Number(`${resultList.fail_percent}`),
                            description: `${resultList.fail_percent}%`,
                            color: '#8B0000',
                            name: 'Fail',
                          },
                        ]}
                      />
                    </div>
                    <div className="a2 col-sm-2 right-align">
                      <span
                        className="execution-time"
                        title="Total execution time"
                      >
                        {resultList.execution_time}
                      </span>
                    </div>
                  </div>
                  <div
                    className="collapse"
                    id={'collapseExample' + index}
                    data-parent="#accordion"
                  >
                    <div>
                      {this.state.children.map((child) => child)}
                    </div>
                  </div>
                </CardContent>
              </Card>
            ))}
          </Card>
        )}
        {this.state.is_Api_Result === false && (
          <div className="card error_result">Waiting to get Result...</div>
        )}
        <div className="pagination_r">
          <Pagination
            count={pageNumbers.length}
            onChange={this.handleChangepage}
            color="primary"
          />
        </div>
      </React.Fragment>
    );
  }
}

export default MyDashboard;
