import React, { Component } from 'react'
class SiteLogo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            siteUrl: sessionStorage.getItem("selectedSiteLogo")
        }
    }
    callmountandupdate() {
        this.updatesiteUrl();
    }
    updatesiteUrl() {
        this.setState({ siteUrl: sessionStorage.getItem("selectedSiteLogo") });
    }
    componentDidMount() {
        this.setState({ siteUrl: sessionStorage.getItem("selectedSiteLogo") });
    }
    render() {
        let url = this.state.siteUrl;
        url = sessionStorage.getItem("selectedSiteLogo")
        return (
            <div>
                {
                    url ? <img alt="katespade" src={url} className="customer-logo"></img> : ""
                }
            </div>


        )
    }
}
export default SiteLogo