import React, { Component } from 'react'

class Analytics extends Component {
    constructor(props) {
        super(props)
        this.state = {
            displayName: "",
            reportUrl:""
        }
        window.AnalyticsComponent = this;
    }
    componentDidMount() {
        this.rerenderComponent();
    }
    rerenderComponent(){
        var project = sessionStorage.getItem('selectedProjectIndex');
        var site = sessionStorage.getItem('selectedSiteIndex');
        //this is menu code
        var analytics_code = this.props.location.pathname.split('/')[2].split('+')[2];
        //this is submenu code
        var page_code = this.props.location.pathname.split('/')[2].split('+')[3];
        var displayName = '';
        var reportURL = '';
        var projectDetails =
            JSON.parse(sessionStorage.getItem('UserDetails')) != null
                ? JSON.parse(sessionStorage.getItem('UserDetails')).data.User.rights
                    .Projects
                : {};

        var menus = projectDetails[project].sites[site].menus
        if (projectDetails.length > 0 && menus !== undefined) {
            menus.forEach((item)=>{
                if (item.code === analytics_code) {
                    let submenuList =item.submenus;
                    submenuList.forEach(element => {
                        if (element.submenuCode === page_code) {
                            displayName = element.displayName
                            reportURL = element.reportURL
                        }
                    });
                }
            })
            
            sessionStorage.setItem('reportDisplayName' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex, displayName);
            sessionStorage.setItem('reportUrl' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex, reportURL);
            this.props.location.displayName = displayName
            this.props.location.reportUrl = reportURL
            this.setState({
                displayName: displayName,
                reportUrl:reportURL
            });
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.location.reportUrl) {
            sessionStorage.setItem('reportDisplayName' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex, nextProps.location.displayName);
            sessionStorage.setItem('reportUrl' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex, nextProps.location.reportUrl);
        }
        if (sessionStorage.getItem('reportDisplayName' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex)) {
            nextProps.location.displayName = sessionStorage.getItem('reportDisplayName' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex);
            nextProps.location.reportUrl = sessionStorage.getItem('reportUrl' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex);
        }
        return true;
    }

    render() {
        // console.log("prop",this.props.location.aboutProps);
        return (

            <div className='analytics'>
                <div className="card  custcard">
                    <div className="cord-body">
                        <div className="testHeading">
                            <label className="headermsg1" id="TestResult" aria-label={this.state.displayName}>{this.props.location.displayName}</label>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-body iframe-hold">
                        <iframe src={this.props.location.reportUrl} title="trends" className="trends"></iframe>
                    </div>
                </div>
            </div>
        )
    }
}

export default Analytics