import React, { useState } from "react";
import "./EditProject.css";
import axios from "axios";
import captions from "../../config/captions.json";
import Checkbox from "@mui/material/Checkbox";
import { UIButton, UITextField } from "../UIComponents";
import { checkUserName, checkValidNonEmptyValues } from "../checkFields";
import FormControlLabel from "@mui/material/FormControlLabel";

function EditUserBasicDetails(props) {
  const [userData, setUserData] = useState(props.userData);
  const [userEdit, setUserEdit] = useState(false);
  const [userApply, setUserApply] = useState(false);

  const [userFName, setUserFName] = useState(props.userData.FirstName);
  const [userLName, setUserLName] = useState(props.userData.LastName);
  const [userCompName, setUserCompName] = useState(props.userData.CompanyName);
  const [userStatus, setUserStatus] = useState(
    props.userData.Status === "active" ? true : false
  );

  const [basicUserData, setBasicUserData] = useState({});
  const [msg, setMsg] = useState("");
  const [fNameValid, setFNameValid] = useState(true);
  const [lNameValid, setLNameValid] = useState(true);
  const [headerData] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  });

  function editUserBasic() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;
    axios
      .patch(url + "/updateuser/" + props.userEmail, basicUserData, {
        headers: headerData,
      })
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;
          setUserData(responseData.User)
          setUserFName(responseData.User.FirstName);
          setUserLName(responseData.User.LastName);
          setUserCompName(responseData.User.CompanyName);
          setUserStatus(responseData.User.Status === "active"? true:false);
          setBasicUserData({});
          setMsg("");
          setUserEdit(!userEdit);
          setUserApply(!userApply);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setMsg(captions.error.USER_EDIT_VALIDATION);
          } else if (error.response.status === 404) {
            setMsg(captions.error.USER_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMsg(captions.error.USER_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
          }
          setUserEdit(true);
          setUserApply(!userApply);
        } else {
          resetUserData();
          setMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function resetUserData() {
    setUserFName(userData.FirstName);
    setUserLName(userData.LastName);
    setUserCompName(userData.CompanyName);
    setUserStatus(userData.Status);
    setMsg("");
    setFNameValid(true);
    setLNameValid(true);
    setBasicUserData({});
  }

  return (
    <div>
      <div className="row">
        <div
          className="col text-right"
          style={{
            //backgroundColor: "rgba(0, 0, 0, .5)",
            backgroundColor: "rgba(0, 0, 0, .09)",
            margin: "5px 18px 0px 18px",
          }}
        >
          {userEdit ? (
            <div>
              <span>{msg}</span>
              <UIButton
                variant="text"
                title={"Apply"}
                disabled={
                  checkValidNonEmptyValues(
                    [fNameValid, lNameValid, userApply],
                    [userFName, userLName, userCompName, userStatus]
                  )
                    ? false
                    : true
                }
                onClick={() => {
                  editUserBasic();
                }}
              />
              <UIButton
                variant="text"
                title={"Cancel"}
                onClick={() => {
                  setUserEdit(!userEdit);
                  resetUserData();
                }}
              />
            </div>
          ) : (
            <UIButton
              variant="text"
              title={"Edit"}
              onClick={() => {
                setUserEdit(!userEdit);
                setUserApply(false);
              }}
            />
          )}
        </div>
      </div>
      <div className="row border m-1">
        <div className="row m-1">
          <div className="col mb-5">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-2">First Name</span>

              <UITextField
                disabled={userEdit ? false : true}
                value={userFName}
                placeholder="Please enter first name"
                onChange={(e) => {
                  setMsg("");
                  checkUserName(e.target.value)
                    ? setFNameValid(true)
                    : setFNameValid(false);
                  setUserFName(e.target.value);
                  setBasicUserData({
                    ...basicUserData,
                    FirstName: e.target.value.trim(),
                  });
                  setUserApply(true);
                }}
                helperText={
                  !fNameValid && userFName !== ""
                    ? captions.validation.USER_NAME
                    : ""
                }
                error={!fNameValid && userFName !== ""}
                width={290}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-2">Last Name</span>
              <UITextField
                disabled={userEdit ? false : true}
                value={userLName}
                placeholder="Please enter last name"
                onChange={(e) => {
                  setMsg("");
                  checkUserName(e.target.value)
                    ? setLNameValid(true)
                    : setLNameValid(false);
                  setUserLName(e.target.value);
                  setBasicUserData({
                    ...basicUserData,
                    LastName: e.target.value.trim(),
                  });
                  setUserApply(true);
                }}
                helperText={
                  !lNameValid && userLName !== ""
                    ? captions.validation.USER_NAME
                    : ""
                }
                error={!lNameValid && userLName !== ""}
                width={290}
              />
            </div>
          </div>
          <div className="col mb-5">
            <div className="d-flex mt-4 ml-5">
              <span
                className="title-prompts mt-2 ml-5"
                style={{ width: "48%" }}
              >
                Company Name
              </span>

              <UITextField
                disabled={userEdit ? false : true}
                value={userCompName}
                placeholder="Please enter company name"
                onChange={(e) => {
                  setMsg("");
                  setUserCompName(e.target.value);
                  setBasicUserData({
                    ...basicUserData,
                    CompanyName: e.target.value.trim(),
                  });
                  setUserApply(true);
                }}
                error={userCompName === ""}
                width={310}
              />
            </div>
            <div className="d-flex mt-4 ml-4">
              <span className="title-prompts mt-2" style={{ width: "48%" }}>
                {" "}
                Active User
              </span>
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                control={
                  <Checkbox
                    color={userEdit ? "primary" : "default"}
                    checked={userStatus}
                    onChange={(e) => {
                      setUserApply(true);
                      setUserStatus(e.target.checked);
                      setBasicUserData({
                        ...basicUserData,
                        Status: e.target.checked ? "active" : "inactive",
                      });
                    }}
                    disabled={userEdit ? false : true}
                  />
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserBasicDetails;
