import React, { Component } from 'react'

class ApiMonitor extends Component {
    render() {
        return (
            <div>
                <h1>API Monitor</h1>
            </div>
        )
    }
}

export default ApiMonitor