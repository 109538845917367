import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
// import { GoogleLogin } from 'react-google-login';
import { setUserSession, getUserSession } from './UserSession';
import { createBrowserHistory } from 'history';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

import App from '../App';
import axios from 'axios';
// import { Route } from 'react-router-dom';
//let clientId = process.env.REACT_APP_CLIENT_ID || '760456517579-nqc509q701g0ohnioo6el4e290fc3ilq.apps.googleusercontent.com';
let clientId = process.env.REACT_APP_CLIENT_ID || '329709251713-gstfhm86tqoir1khtglnn93h8oeqo6sg.apps.googleusercontent.com';
// let clientId = process.env.REACT_APP_CLIENT_ID || '20634874835-j5afl2emb3qmu1d43om1kjbrro56j02o.apps.googleusercontent.com';
const history = createBrowserHistory();
class GLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      auth: false
    };

  }

  googleIdVerification = (credentialResponse)=>{
    var data = {token:credentialResponse.credential, clientId:credentialResponse.clientId}
    axios
    .post(window.configs.BASE_API_URL+window.configs.LOGIN_SERVICE_VERIFY_GOOGLEID_URL, data)
    .then((result) => {
      if (result.status === 200) {
        var responseData = result.data.data;
        this.success(credentialResponse,responseData);
      } 
    })
    .catch((error) => {
      this.props.setValidationMsg("Your access is denied")
    });
  }

  success = (response, tokenData) => {
    debugger;
    console.log('success-->', response); //eslint-disable-line
    var base64Payload = response.credential.split('.')[1];
    var payload = Buffer.from(base64Payload, 'base64');
    let responsePayload = JSON.parse(payload.toString());
    // let responsePayload = decodeJwtResponse(response.credential);

    console.log("ID: " + responsePayload.sub);
    console.log('Full Name: ' + responsePayload.name);
    console.log('Given Name: ' + responsePayload.given_name);
    console.log('Family Name: ' + responsePayload.family_name);
    console.log("Image URL: " + responsePayload.picture);
    console.log("Email: " + responsePayload.email);
    let serviceURL = window.configs.USER_SERVICE_URL + 'user-details/' + responsePayload.email;
    setUserSession.setItem('email', responsePayload.email);
    // localStorage.setItem('rioLogginStatus', 'Y');
    setUserSession.setItem('imageUrl', responsePayload.picture);
    setUserSession.setItem('givenName', responsePayload.given_name);
    setUserSession.setItem('serviceUrl', serviceURL);
    sessionStorage.setItem('selectedProjectIndex', 0);
    sessionStorage.setItem('selectedSiteIndex', 0);
    axios
      .get(
        // "http://localhost:3000/sample.json"
        window.configs.USER_SERVICE_URL +
        'user-details/' +
        responsePayload.email
      )
      .then((result) => {
        // axios.get( "http://localhost:5000/api/user" ).then(result => {
        sessionStorage.setItem('UserDetails', JSON.stringify(result));
        setUserSession.setItem('authToken', tokenData);
        this.setState({ auth: true })
        // history.push('/')
        // window.location.href = '/';
      })
      .catch((error) => {
       this.props.setValidationMsg("Your access is denied")
      });
  }
  error = (response) => {
    console.error(response); // eslint-disable-line
  };

  loading = (response) => {
    console.log("loading"); // eslint-disable-line
  };



  render() {

    var isSessionActive = getUserSession.getItem('email') == null ? false : true;
    let auth = this.state.auth;

    if (!auth) {
      return (

        // <GoogleLogin
        //   theme="dark"
        //   onRequest={this.loading}
        //   onSuccess={this.success}
        //   onFailure={this.error}
        //   clientId={clientId}
        //   prompt={'consent'}
        //   immediate={'false'}
        //   cookiePolicy={'single_host_origin'}
        // />
         <GoogleOAuthProvider clientId={clientId}>
          <GoogleLogin
            onSuccess={credentialResponse => {
              this.googleIdVerification(credentialResponse) 
            }}
            useOneTap
            onError={()=>{
              console.log("Login Failed")
            }}
            width={"400px"}
          />
        </GoogleOAuthProvider> 
      );
    } else {
      return <Redirect to="/" rerenderComponent={true} />;
    }
  }
}

export default withRouter(GLogin);