import React, { useState } from "react";
import "./EditProject.css";

import axios from "axios";
import captions from "../../config/captions.json";
import {
  UIDropDown,
  UIButton,
  UITextField,
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
  UIModal,
  UIDialog,
} from "../UIComponents";
import {
  checkNameField,
  checkAlphaNumNameField,
  checkValidNonEmptyValues,
  checkEmptyValues,
  checkNonEmptyValues,
  checkShortNameField,
  checkHttpUrl,
  checkLogoUrl,
} from "../checkFields";
import pageAccess_menu from "../../config/pageAccess_menu.json";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";

function EditSiteMenu(props) {
  const [menuTxt, setMenuTxt] = useState("");
  const [submenuTxt, setSubmenuTxt] = useState("");
  const [menuCode, setMenuCode] = useState("");
  const [subCode, setSubCode] = useState("");
  const [menuName, setMenuName] = useState("");
  const [menuShortName, setMenuShortName] = useState("");
  const [menuErrMsg, setMenuErrMsg] = useState("");
  const [, setDisplaySubmenu] = useState(false);
  const [menuAddModalOpen, setMenuAddModalOpen] = useState(false);
  const [menuEditModalOpen, setMenuEditModalOpen] = useState(false);
  const [, setMenuCodeValid] = useState(true);
  const [menuNameValid, setMenuNameValid] = useState(true);
  const [menuShortNameValid, setMenuShortNameValid] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const [menuEdit, setMenuEdit] = useState(false);
  const [basicMenuData, setBasicMenuData] = useState({});
  const [menuCodeList, setMenuCodeList] = useState(
    pageAccess_menu.menuList.map((i) => i.menuCode)
  );
  const [submenuCodeList, setSubmenuCodeList] = useState([]);
  const [submenuCodeDB, setSubmenuCodeDB] = useState([]);
  const [propsMenuData, setPropsMenuData] = useState(
    "menus" in props.siteData ? props.siteData.menus : []
  );
  const [submenuAddModalOpen, setSubmenuAddModalOpen] = useState(false);
  const [submenuEditModalOpen, setSubmenuEditModalOpen] = useState(false);
  const [submenuName, setSubmenuName] = useState("");
  const [submenuRUrl, setSubmenuRUrl] = useState("");
  const [submenuLogo, setSubmenuLogo] = useState("");
  const [, setSubmenuCode] = useState("");
  const [basicSubmenuData, setBasicSubmenuData] = useState({});
  const [submenuData, setSubmenuData] = useState([]);
  const [submenuEdit, setSubmenuEdit] = useState(false);
  const [, setSubmenuCodeValid] = useState(true);
  const [submenuNameValid, setSubmenuNameValid] = useState(true);
  const [submenuRUrlValid, setSubmenuRUrlValid] = useState(true);
  const [submenuLogoValid, setSubmenuLogoValid] = useState(true);
  const [menuDelOpen, setMenuDelOpen] = useState(false);
  const [submenuDelOpen, setSubmenuDelOpen] = useState(false);
  const [headerData] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  });

  function goToMenuAddModal() {
    return (
      <UIModal
        open={menuAddModalOpen}
        handleClose={menuAddModalOpen}
        title={"Add Menu"}
        fields={
          <div className="col">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-3">Menu Code</span>
              <UIDropDown
                value={menuCode}
                onChange={(e) => {
                  setMenuName("");
                  setMenuShortName("");
                  setMenuCode(e.target.value);
                  setMenuErrMsg("");
                }}
                placeholder="Select menu code"
                minWidth={310}
                textAlign={"left"}
                maxHeight={350}
                list={menuCodeList.filter((x) => {
                  var pList = propsMenuData.map((i) => i.code);
                  return !pList.includes(x);
                })}
              />
            </div>
            {menuCode !== "" ? (
              <div>
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-1">Menu Name</span>
                  <UITextField
                    value={menuName}
                    placeholder="Please enter menu name"
                    onChange={(e) => {
                      checkNameField(e.target.value)
                        ? setMenuNameValid(true)
                        : setMenuNameValid(false);
                      setMenuName(e.target.value);
                      setMenuErrMsg("");
                    }}
                    helperText={
                      !menuNameValid && menuName !== ""
                        ? captions.validation.MENU_NAME
                        : ""
                    }
                    error={!menuNameValid && menuName !== ""}
                    width={310}
                  />
                </div>
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-1">Menu Short Name</span>
                  <UITextField
                    value={menuShortName}
                    placeholder="Please enter menu short name"
                    onChange={(e) => {
                      checkShortNameField(e.target.value)
                        ? setMenuShortNameValid(true)
                        : setMenuShortNameValid(false);
                      setMenuShortName(e.target.value);
                      setMenuErrMsg("");
                    }}
                    helperText={
                      !menuShortNameValid && menuShortName !== ""
                        ? captions.validation.MENU_SHORT_CODE
                        : ""
                    }
                    error={!menuShortNameValid && menuShortName !== ""}
                    width={310}
                  />
                </div>
              </div>
            ) : null}
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  addMenu();
                }}
                disabled={
                  checkValidNonEmptyValues(
                    [menuNameValid, menuShortNameValid],
                    [menuCode, menuName, menuShortName]
                  )
                    ? false
                    : true
                }
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetMenu();
                  setMenuAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">
              {menuErrMsg}
            </span>
          </div>
        }
      />
    );
  }

  function goToSubmenuDelDialog(menuCode, submenuCode) {
    return (
      <UIDialog
        open={submenuDelOpen}
        handleClose={submenuDelOpen}
        title={"Delete Confirmation"}
        content={"Are you sure, want to delete the submenu? "}
        handleNo={() => {
          setSubmenuDelOpen(false);
        }}
        handleYes={() => {
          delSubmenu(menuCode, submenuCode);
        }}
      />
    );
  }

  function goToMenuDelDialog(menuCode) {
    return (
      <UIDialog
        open={menuDelOpen}
        handleClose={menuDelOpen}
        title={"Delete Confirmation"}
        content={
          "Are you sure, want to delete the menu? It will delete all its submenus"
        }
        handleNo={() => {
          setMenuDelOpen(false);
        }}
        handleYes={() => {
          delMenu(menuCode);
        }}
      />
    );
  }

  function goToSubmenuAddModal(menuCode) {
    return (
      <UIModal
        open={submenuAddModalOpen}
        handleClose={submenuAddModalOpen}
        title={"Add Submenu"}
        fields={
          <div className="col mr-5">
            <div className="d-flex mt-3">
              <span className="title-prompts mt-3">Submenu Code</span>
              <UIDropDown
                value={subCode}
                onChange={(e) => {
                  setSubmenuName("");
                  setSubmenuRUrl("");
                  setSubmenuLogo("");
                  setSubCode(e.target.value);
                  setMenuErrMsg("");
                }}
                placeholder="Select submenu code"
                minWidth={420}
                textAlign={"left"}
                maxHeight={350}
                list={submenuCodeList[0].filter((x) => {
                  var sList = submenuCodeDB.map((i) => i.submenuCode);
                  return !sList.includes(x);
                })}
              />
            </div>
            {subCode !== "" ? (
              <div>
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-1">Submenu Name</span>
                  <UITextField
                    value={submenuName}
                    placeholder="Please enter submenu name"
                    onChange={(e) => {
                      checkAlphaNumNameField(e.target.value)
                        ? setSubmenuNameValid(true)
                        : setSubmenuNameValid(false);
                      setSubmenuName(e.target.value);
                      setMenuErrMsg("");
                    }}
                    helperText={
                      !submenuNameValid && submenuName !== ""
                        ? captions.validation.SUBMENU_NAME
                        : ""
                    }
                    error={!submenuNameValid && submenuName !== ""}
                    width={420}
                  />
                </div>
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-1">{menuCode!== "apps" ? "Submenu Report URL" : "Submenu Page Path"}</span>
                  {menuCode!== "apps"
                  ?
                    <UITextField
                      value={submenuRUrl}
                      placeholder="Please enter report url"
                      onChange={(e) => {
                        checkHttpUrl(e.target.value)
                          ? setSubmenuRUrlValid(true)
                          : setSubmenuRUrlValid(false);
                        setSubmenuRUrl(e.target.value);
                        setMenuErrMsg("");
                      }}
                      helperText={
                        !submenuRUrlValid && submenuRUrl !== ""
                          ? captions.validation.SUBMENU_R_URL
                          : ""
                      }
                      error={!submenuRUrlValid && submenuRUrl !== ""}
                      width={420}
                    />
                    :
                    <UITextField
                      value={submenuRUrl}
                      placeholder="Please enter page path"
                      onChange={(e) => {
                        setSubmenuRUrlValid(true)
                        setSubmenuRUrl(e.target.value);
                        setMenuErrMsg("");
                      }}
                      helperText={
                        submenuRUrl == ""
                          ? captions.validation.SUBMENU_PATH
                          : ""
                      }
                      error={submenuRUrl == ""}
                      width={420}
                    />
                    }
                </div>
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-1">Submenu logo</span>
                  <UITextField
                    value={submenuLogo}
                    placeholder="Please enter submenu logo"
                    onChange={(e) => {
                      checkLogoUrl(e.target.value)
                        ? setSubmenuLogoValid(true)
                        : setSubmenuLogoValid(false);
                      setSubmenuLogo(e.target.value);
                      setMenuErrMsg("");
                    }}
                    helperText={
                      !submenuLogoValid && submenuLogo !== ""
                        ? captions.validation.SUBMENU_LOGO
                        : ""
                    }
                    error={!submenuLogoValid && submenuLogo !== ""}
                    width={420}
                  />
                </div>
              </div>
            ) : null}
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  addSubmenu(menuCode);
                }}
                disabled={
                  checkValidNonEmptyValues(
                    [submenuNameValid, submenuRUrlValid, submenuLogoValid],
                    [subCode, submenuName, submenuRUrl, submenuLogo]
                  )
                    ? false
                    : true
                }
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetSubmenu();
                  setSubmenuAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">
              {menuErrMsg}
            </span>
          </div>
        }
      />
    );
  }

  function goToMenuEditModal() {
    return (
      <UIModal
        open={menuEditModalOpen}
        handleClose={menuEditModalOpen}
        title={"Edit Menu"}
        fields={
          <div className="col">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Menu Name</span>
              <UITextField
                value={menuName}
                placeholder="Please enter menu name"
                onChange={(e) => {
                  checkNameField(e.target.value)
                    ? setMenuNameValid(true)
                    : setMenuNameValid(false);
                  setMenuName(e.target.value);
                  setMenuErrMsg("");
                  setMenuEdit(true);
                  setBasicMenuData({
                    ...basicMenuData,
                    displayName: e.target.value,
                  });
                }}
                helperText={
                  !menuNameValid && menuName !== ""
                    ? captions.validation.MENU_NAME
                    : ""
                }
                error={!menuNameValid && menuName !== ""}
                width={310}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Menu Short Name</span>
              <UITextField
                value={menuShortName}
                placeholder="Please enter menu short name"
                onChange={(e) => {
                  checkShortNameField(e.target.value)
                    ? setMenuShortNameValid(true)
                    : setMenuShortNameValid(false);
                  setMenuShortName(e.target.value);
                  setMenuErrMsg("");
                  setMenuEdit(true);
                  setBasicMenuData({
                    ...basicMenuData,
                    shortDisplayName: e.target.value,
                  });
                }}
                helperText={
                  !menuShortNameValid && menuShortName !== ""
                    ? captions.validation.MENU_SHORT_CODE
                    : ""
                }
                error={!menuShortNameValid && menuShortName !== ""}
                width={310}
              />
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  editMenu();
                }}
                disabled={
                  checkValidNonEmptyValues(
                    [menuNameValid, menuShortNameValid, menuEdit],
                    [menuName, menuShortName]
                  )
                    ? false
                    : true
                }
                title={"Apply"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetMenu();
                  //setMenuTxt("");
                  setMenuEditModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">
              {menuErrMsg}
            </span>
          </div>
        }
      />
    );
  }

  function goToSubmenuEditModal() {
    return (
      <UIModal
        open={submenuEditModalOpen}
        handleClose={submenuEditModalOpen}
        title={"Edit Submenu"}
        fields={
          <div className="col">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Submenu Name</span>
              <UITextField
                value={submenuName}
                placeholder="Please enter submenu name"
                onChange={(e) => {
                  checkAlphaNumNameField(e.target.value)
                    ? setSubmenuNameValid(true)
                    : setSubmenuNameValid(false);
                  setSubmenuName(e.target.value);
                  setMenuErrMsg("");
                  setSubmenuEdit(true);
                  setBasicSubmenuData({
                    ...basicSubmenuData,
                    displayName: e.target.value,
                  });
                }}
                helperText={
                  !submenuNameValid && submenuName !== ""
                    ? captions.validation.SUBMENU_NAME
                    : ""
                }
                error={!submenuNameValid && submenuName !== ""}
                width={420}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">{menuData[0] !== undefined && menuData[0].code !== "apps" ? "Submenu Report URL":"Submenu Page Path"}</span>
              { menuData[0] !== undefined && menuData[0].code !=="apps"
                ?
                <UITextField
                  value={submenuRUrl}
                  placeholder="Please enter submenu report URL"
                  onChange={(e) => {
                    checkHttpUrl(e.target.value)
                      ? setSubmenuRUrlValid(true)
                      : setSubmenuRUrlValid(false);
                    setSubmenuRUrl(e.target.value);
                    setMenuErrMsg("");
                    setSubmenuEdit(true);
                    setBasicSubmenuData({
                      ...basicSubmenuData,
                      reportURL: e.target.value,
                    });
                  }}
                  helperText={
                    !submenuRUrlValid && submenuRUrl !== ""
                      ? captions.validation.SUBMENU_R_URL
                      : ""
                  }
                  error={!submenuRUrlValid && submenuRUrl !== ""}
                  width={420}
                />
                :
                <UITextField
                  value={submenuRUrl}
                  placeholder="Please enter submenu page path"
                  onChange={(e) => {
                    setSubmenuRUrlValid(true)
                    setSubmenuRUrl(e.target.value);
                    setMenuErrMsg("");
                    setSubmenuEdit(true);
                    setBasicSubmenuData({
                      ...basicSubmenuData,
                      reportURL: e.target.value,
                    });
                  }}
                  helperText={
                    submenuRUrl == ""
                      ? captions.validation.SUBMENU_PATH
                      : ""
                  }
                  error={submenuRUrl == ""}
                  width={420}
                />

              }
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Submenu Logo</span>
              <UITextField
                value={submenuLogo}
                placeholder="Please enter logo url"
                onChange={(e) => {
                  checkLogoUrl(e.target.value)
                    ? setSubmenuLogoValid(true)
                    : setSubmenuLogoValid(false);
                  setSubmenuLogo(e.target.value);
                  setMenuErrMsg("");
                  setSubmenuEdit(true);
                  setBasicSubmenuData({
                    ...basicSubmenuData,
                    logo: e.target.value,
                  });
                }}
                helperText={
                  !submenuLogoValid && submenuLogo !== ""
                    ? captions.validation.SUBMENU_LOGO
                    : ""
                }
                error={!submenuLogoValid && submenuLogo !== ""}
                width={420}
              />
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  editSubmenu();
                }}
                disabled={
                  checkValidNonEmptyValues(
                    [
                      submenuNameValid,
                      submenuRUrlValid,
                      submenuLogoValid,
                      submenuEdit,
                    ],
                    [submenuName, submenuRUrl, submenuLogo]
                  )
                    ? false
                    : true
                }
                title={"Apply"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetSubmenu();
                  setSubmenuEditModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">
              {menuErrMsg}
            </span>
          </div>
        }
      />
    );
  }

  function resetMenu() {
    setMenuCode("");
    setMenuName("");
    setMenuShortName("");
    setMenuCodeValid(true);
    setMenuNameValid(true);
    setMenuShortNameValid(true);
    setMenuErrMsg("");
    setMenuEdit(false);
    setBasicMenuData({});
  }

  function resetSubmenu() {
    setSubmenuCode("");
    setSubCode("");
    setSubmenuName("");
    setSubmenuRUrl("");
    setSubmenuLogo("");
    setSubmenuNameValid(true);
    setSubmenuRUrlValid(true);
    setSubmenuLogoValid(true);
    setSubmenuCodeValid(true);
    setMenuErrMsg("");
    setSubmenuEdit(false);
    setBasicSubmenuData({});
  }

  function addMenu() {
    if (
      checkValidNonEmptyValues(
        [menuNameValid, menuShortNameValid],
        [menuCode, menuName, menuShortName]
      ) &&
      menuErrMsg === ""
    ) {
      var data = {
        menus: [
          {
            code: menuCode.trim(),
            displayName: menuName.trim(),
            shortDisplayName: menuShortName.trim(),
          }
        ]
        
      };
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
      axios
        .patch(
          url +
            "/" +
            props.projectId +
            "/" +
            props.siteData.siteid +
            "/addmenu",
          data,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status === "success") {
            setMenuCode("");
            setMenuName("");
            setMenuShortName("");
            setMenuTxt("");
            setMenuAddModalOpen(false);
            var responseData = result.data.info;
            responseData.sites.map((item) => {
              if (item.siteid === props.siteData.siteid && "menus" in item) {
                setPropsMenuData(item.menus);
              }
            });
          } else {
            setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 409) {
              setMenuErrMsg(captions.error.MENU_ADD_DUPLICATE);
            } else if (error.response.status === 400) {
              setMenuErrMsg(captions.error.SITE_ADD_VALIDATION);
            } else if (error.response.status === 404) {
              setMenuErrMsg(captions.error.SITE_VALUE_ERROR);
            } else if (error.response.status === 401) {
              setMenuErrMsg(captions.error.MENU_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
            }
          } else {
            setMenuErrMsg(captions.error.INTERNAL_ERROR);
            setMenuCode("");
            setMenuName("");
            setMenuShortName("");
          }
        });
    } else {
      setMenuErrMsg(captions.error.FIELDS_EMPTY);
    }
  }

  function addSubmenu(mCode) {
    if (
      checkValidNonEmptyValues(
        [submenuNameValid, submenuRUrlValid, submenuLogoValid],
        [subCode, submenuName, submenuRUrl, submenuLogo, mCode]
      ) &&
      menuErrMsg === ""
    ) {
      var data = {
        submenus:[
          {
            submenuCode: subCode.trim(),
            displayName: submenuName.trim(),
            reportURL: submenuRUrl.trim(),
            logo: submenuLogo.trim(),
          }
        ]
      };
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
      axios
        .patch(
          url +
            "/" +
            props.projectId +
            "/" +
            props.siteData.siteid +
            "/" +
            mCode.trim() +
            "/addsubmenu",
          data,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status === "success") {
            setSubmenuName("");
            setSubmenuRUrl("");
            setSubmenuLogo("");
            setSubmenuTxt("");
            setSubCode("");
            setSubmenuAddModalOpen(false);
            var responseData = result.data.info;
            var mData = [];
            responseData.sites.map((item) => {
              if (item.siteid === props.siteData.siteid && "menus" in item) {
                setPropsMenuData(item.menus);

                item.menus.map((i) => {
                  if (i.code === mCode) {
                    mData.push(i);
                  }
                });
                setMenuData(mData);
                setSubmenuCodeDB(mData[0].submenus);
              }
            });
          } else {
            setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 409) {
              setMenuErrMsg(captions.error.MENU_ADD_DUPLICATE);
            } else if (error.response.status === 400) {
              setMenuErrMsg(captions.error.SITE_ADD_VALIDATION);
            } else if (error.response.status === 404) {
              setMenuErrMsg(captions.error.SITE_VALUE_ERROR);
            } else if (error.response.status === 401) {
              setMenuErrMsg(captions.error.MENU_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
            } else {
              setMenuErrMsg(captions.error.INTERNAL_ERROR);
              setSubmenuCode("");
              setSubmenuName("");
              setSubmenuRUrl("");
              setSubmenuLogo("");
              setSubCode("");
            }
          } else {
            setMenuErrMsg(captions.error.INTERNAL_ERROR);
            setSubCode("");
            setSubmenuCode("");
            setSubmenuName("");
            setSubmenuRUrl("");
            setSubmenuLogo("");
          }
        });
    } else {
      setMenuErrMsg(captions.error.FIELDS_EMPTY);
    }
  }

  function editSubmenu() {
    var mCode = menuData[0].code;
    var data = {
      submenus : [basicSubmenuData]
    }
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .patch(
        url +
          "/" +
          props.projectId +
          "/" +
          props.siteData.siteid +
          "/" +
          mCode +
          "/updatesubmenu",
          data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          var mData = [];
          responseData.sites.map((item) => {
            if (item.siteid === props.siteData.siteid && "menus" in item) {
              setPropsMenuData(item.menus);

              item.menus.map((i) => {
                if (i.code === mCode) {
                  mData.push(i);
                }
              });
              setMenuData(mData);
            }
          });
          setBasicSubmenuData({});
          setSubmenuCode("");
          setSubmenuName("");
          setSubmenuRUrl("");
          setSubmenuLogo("");
          setSubmenuEditModalOpen(false);

          setSubmenuTxt("");
        } else {
          setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setMenuErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setMenuErrMsg(captions.error.SITE_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMenuErrMsg(captions.error.MENU_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
          }
        } else {
          setMenuErrMsg(captions.error.INTERNAL_ERROR);

          setSubmenuCode("");
          setSubmenuName("");
          setSubmenuRUrl("");
          setSubmenuLogo("");
        }
      });
  }

  function editMenu() {
    var data = {
      menus:[basicMenuData]
    }
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .patch(
        url +
          "/" +
          props.projectId +
          "/" +
          props.siteData.siteid +
          "/updatemenu",
          data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;
          setMenuCode("");
          setMenuName("");
          setMenuShortName("");
          setMenuEditModalOpen(false);
          setBasicMenuData({});
          setMenuTxt("");
          responseData.sites.map((item) => {
            if (item.siteid === props.siteData.siteid && "menus" in item) {
              setPropsMenuData(item.menus);
            }
          });
        } else {
          setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setMenuErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setMenuErrMsg(captions.error.SITE_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMenuErrMsg(captions.error.MENU_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
          }
        } else {
          setMenuErrMsg(captions.error.INTERNAL_ERROR);

          setMenuCode("");
          setMenuName("");
          setMenuShortName("");
        }
      });
  }

  function delSubmenu(menuCode, submenuCode) {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .delete(
        url +
          "/" +
          props.projectId +
          "/" +
          props.siteData.siteid +
          "/" +
          menuCode.trim() +
          "/" +
          submenuCode.trim(),

        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          setSubmenuTxt("");
          setSubmenuDelOpen(false);
          var responseData = result.data.info;
          var mData = [];
          responseData.sites.map((item) => {
            if (item.siteid === props.siteData.siteid && "menus" in item) {
              setPropsMenuData(item.menus);

              item.menus.map((i) => {
                if (i.code === menuCode) {
                  mData.push(i);
                }
              });
              setMenuData(mData);
              setSubmenuTxt("");
            }
          });
        } else {
          setMenuErrMsg(captions.error.MENU_DEL_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setMenuErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setMenuErrMsg(captions.error.SITE_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMenuErrMsg(captions.error.MENU_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
          }
        } else {
          setSubmenuDelOpen(false);
          setSubmenuTxt("");
          setMenuErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }
  function delMenu(menuCode) {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .delete(
        url +
          "/" +
          props.projectId +
          "/" +
          props.siteData.siteid +
          "/" +
          menuCode.trim(),

        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          setMenuTxt("");
          setMenuDelOpen(false);
          var responseData = result.data.info;
          responseData.sites.map((item) => {
            if (item.siteid === props.siteData.siteid && "menus" in item) {
              setPropsMenuData(item.menus);
              setMenuTxt("");
            }
          });
        } else {
          setMenuErrMsg(captions.error.MENU_DEL_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setMenuErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setMenuErrMsg(captions.error.SITE_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMenuErrMsg(captions.error.MENU_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMenuErrMsg(captions.error.MENU_ADD_INTERNAL_ERROR);
          }
        } else {
          setMenuDelOpen(false);
          setMenuTxt("");
          setMenuErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  return (
    <UIAccordion style={{ marginTop: "10px" }}>
      <UIAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem", color: "#000" }}
          />
        }
        style={{
          backgroundColor: "rgba(0, 0, 0, .09)",
          color: "#000",
          //border: "1px solid",
        }}
      >
        <div className="row">
          <div className="col text-left">
            <Typography>Menu</Typography>
          </div>
        </div>
      </UIAccordionSummary>
      <UIAccordionDetails style={{ backgroundColor: "#fff" }}>
        <div className="row mt-3">
          <div className="col text-left ml-3">
            <div className="d-flex mr-5">
              <div>
                <UIDropDown
                  value={menuTxt}
                  onChange={(e) => {
                    var mData = [];
                    var mCode = "";
                    propsMenuData.map((item) => {
                      if (
                        "displayName" in item &&
                        item.displayName === e.target.value
                      ) {
                        mData.push(item);
                        mCode = item.code;
                      }
                    });
                    setMenuTxt(e.target.value);
                    setMenuErrMsg("");
                    setMenuData(mData);
                    if (e.target.value !== "") {
                      var codes = [];
                      pageAccess_menu.menuList.forEach((mItem) => {
                        if (mItem.menuCode === mCode) {
                          codes.push(mItem.submenuCode);
                        }
                      });
                      if ("submenus" in mData[0]) {
                        setSubmenuCodeDB(mData[0].submenus);
                      }
                      setSubmenuCodeList(codes);
                      //setSiteMenuList([]);
                    }
                    setSubmenuTxt("");
                    setDisplaySubmenu(e.target.value ? true : false);
                  }}
                  placeholder="Select menu"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={propsMenuData.map((item) => item.displayName)}
                />
              </div>
              <div className="col-sm-8 btn-group cp-btn-group mt-2">
                <div className="ml-1 ">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setMenuEditModalOpen(false);
                      setMenuAddModalOpen(true);
                    }}
                    title={"Add Menu"}
                  />
                  {goToMenuAddModal()}
                </div>
                <div className="ml-1 ">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setMenuAddModalOpen(false);
                      setMenuName(menuData[0].displayName);
                      setMenuShortName(menuData[0].shortDisplayName);
                      setBasicMenuData({
                        ...basicMenuData,
                        code: menuData[0].code,
                      });
                      setMenuEditModalOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([menuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Edit"}
                  />
                  {goToMenuEditModal()}
                </div>
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setMenuDelOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([menuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Delete"}
                  />
                  {menuTxt !== "" ? goToMenuDelDialog(menuData[0].code) : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 border-top">
          <div className="col text-left  mt-4 ml-3">
            <div className="d-flex mr-5">
              <div>
                <UIDropDown
                  value={submenuTxt}
                  disabled={menuTxt === "" ? true : false}
                  onChange={(e) => {
                    setSubmenuTxt(e.target.value);
                    setMenuErrMsg("");
                    var sMenu = [];
                    if (menuData.length !== 0 && "submenus" in menuData[0]) {
                      menuData[0].submenus.map((item) => {
                        if (item.displayName === e.target.value) {
                          return sMenu.push(item);
                        }
                      });
                    }
                    setSubmenuData(sMenu);
                  }}
                  placeholder="Select submenu"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={
                    menuData.length !== 0 && "submenus" in menuData[0]
                      ? menuData[0].submenus.map((item) => item.displayName)
                      : []
                  }
                />
              </div>
              <div className="col-sm-8 btn-group cp-btn-group mt-2">
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setSubmenuEditModalOpen(false);
                      setSubmenuAddModalOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([menuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Add Submenu"}
                  />
                  {menuTxt !== ""
                    ? goToSubmenuAddModal(menuData[0].code)
                    : null}
                </div>
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setSubmenuAddModalOpen(false);
                      setSubmenuName(submenuData[0].displayName);
                      setSubmenuRUrl(submenuData[0].reportURL);
                      setSubmenuLogo(submenuData[0].logo);
                      setBasicSubmenuData({
                        ...basicSubmenuData,
                        submenuCode: submenuData[0].submenuCode,
                      });
                      setSubmenuEditModalOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([submenuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Edit"}
                  />

                  {goToSubmenuEditModal()}
                </div>
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setSubmenuDelOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([submenuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Delete"}
                  />

                  {menuTxt !== "" && submenuTxt !== ""
                    ? goToSubmenuDelDialog(
                        menuData[0].code,
                        submenuData[0].submenuCode
                      )
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </UIAccordionDetails>
    </UIAccordion>
  );
}

export default EditSiteMenu;
