import React, { useEffect, useState } from "react";
import "./EditProject.css";
import { UIDropDown, UIButton, UITextField, UIModal } from "../UIComponents";
import { checkEmptyValues } from "../checkFields";
import { windowsBrowserList, macBrowserList, linBrowserList } from "../../config/siteItemList";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function EditSiteOSDevice(props) {
  const [osName] = useState(props.osName);
  const [formFactorDeviceChecklist, setFormFactorDeviceChecklist] = useState(
    props.osDeviceChecklist
  );
  const [, setFormFactorEdit] = useState(props.editStatus);
  const [deviceModalOpen, setDeviceModalOpen] = useState(false);
  const [tDevice, setTDevice] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setFormFactorEdit(props.editStatus);

    setFormFactorDeviceChecklist([]);
    setFormFactorDeviceChecklist(props.osDeviceChecklist);
  }, [props]);

  function disabledCheck(osName) {
    if (osName === "Windows") {
      return formFactorDeviceChecklist.length === windowsBrowserList.length
        ? true
        : false;
    } else if (osName === "Mac") {
      return formFactorDeviceChecklist.length === macBrowserList.length
        ? true
        : false;
    } else if (osName === "Linux") {
      return formFactorDeviceChecklist.length === linBrowserList.length
        ? true
        : false;
    } else {
      return false;
    }
  }

  function goToDeviceAddModal(checklist) {
    return (
      <UIModal
        open={deviceModalOpen}
        handleClose={deviceModalOpen}
        title={props.formFactorName === "Desktop"? "Add Browser": "Add Device"}
        fields={
          <div className="col">
            <div>
              {props.formFactorName === "Desktop" ? (
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-3">Browser</span>
                  <UIDropDown
                    value={tDevice}
                    onChange={(e) => {
                      setTDevice(e.target.value);
                      setErrMsg("");
                    }}
                    placeholder="Select browser"
                    minWidth={280}
                    textAlign={"left"}
                    maxHeight={350}
                    list={
                      props.osName === "Mac"
                        ? macBrowserList.filter((x) => {
                            var fList = formFactorDeviceChecklist.map(
                              (i) => i.deviceItem
                            );
                            return !fList.includes(x);
                          })
                        : props.osName === "Linux"
                        ? linBrowserList.filter((x) => {
                            var fList = formFactorDeviceChecklist.map(
                              (i) => i.deviceItem
                            );
                            return !fList.includes(x);
                          })
                        : windowsBrowserList.filter((x) => {
                            var fList = formFactorDeviceChecklist.map(
                              (i) => i.deviceItem
                            );
                            return !fList.includes(x);
                          })
                    }
                  />
                </div>
              ) : (
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-1">Device</span>
                  <UITextField
                    value={tDevice}
                    placeholder="Please enter device name"
                    onChange={(e) => {
                      setTDevice(e.target.value);
                      setErrMsg("");
                    }}
                    //helperText={tEnv !== "" ? captions.validation.REQUIRED : ""}
                    //error={tEnv !== ""}
                    width={310}
                  />
                </div>
              )}
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  if (tDevice.trim() !== "") {
                    var newItem = formFactorDeviceChecklist.map((x) => x);
                    newItem.push({
                      deviceItem: tDevice,
                      check: true,
                    });

                    setFormFactorDeviceChecklist(newItem);
                    props.setFormFactorDeviceChecklist(props.osName, newItem);
                    props.setFormFactorApply(true);
                    //setFOS("");
                    setTDevice("");
                    setErrMsg("");
                    setDeviceModalOpen(false);
                  }
                }}
                disabled={checkEmptyValues([tDevice]) ? true : false}
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  setTDevice("");
                  setErrMsg("");
                  setDeviceModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }

  return (
    <div>
      {formFactorDeviceChecklist.map((dItem, dIndex) => (
        <div key={dIndex}>
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
              marginLeft: "10px",
            }}
            control={
              <Checkbox
                color={props.editStatus ? "primary" : "default"}
                checked={formFactorDeviceChecklist[dIndex].check}
                onChange={(e) => {
                  if (e.target.checked) {
                    var c = formFactorDeviceChecklist.map((i, q) => {
                      if (q === dIndex) {
                        i.check = true;
                      }
                      return i;
                    });

                    setFormFactorDeviceChecklist(c);
                    props.setFormFactorDeviceChecklist(props.osName, c);
                  } else {
                    var d = formFactorDeviceChecklist.map((i, q) => {
                      if (q === dIndex) {
                        i.check = false;
                      }
                      return i;
                    });
                    
                    setFormFactorDeviceChecklist(d);
                    props.setFormFactorDeviceChecklist(props.osName, d);
                  }
                  props.setFormFactorApply(true);
                }}
              />
            }
            label={dItem.deviceItem}
            style={props.editStatus ? {} : { pointerEvents: "none" }}
          />
        </div>
      ))}
      <div className="col ml-4 mb-3">
        <div className="col text-left">
          {props.editStatus && formFactorDeviceChecklist ? (
            <div>
              <UIButton
                variant="text"
                title={props.formFactorName === "Desktop"? "Add Browser": "Add Device"}
                disabled={disabledCheck(osName)}
                onClick={() => {
                  setDeviceModalOpen(true);
                }}
              />
              {goToDeviceAddModal(formFactorDeviceChecklist)}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default EditSiteOSDevice;
