import React, { useState } from "react";
import "./EditProject.css";

import axios from "axios";
import captions from "../../config/captions.json";
import {
  UIDropDown,
  UIButton,
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
  UIModal,
  UIDialog,
} from "../UIComponents";
import { checkEmptyValues, checkNonEmptyValues } from "../checkFields";
import pageAccess_menu from "../../config/pageAccess_menu.json";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

function EditUserRights(props) {
  const [menuErrMsg, setMenuErrMsg] = useState("");
  const [projectMenuTxt, setProjectMenuTxt] = useState("");
  const [projectSiteData, setProjectSiteData] = useState([]);
  const [projectAddModalOpen, setProjectAddModalOpen] = useState(false);
  const [projectDeleteModalOpen, setProjectDeleteModalOpen] = useState(false);
  const [siteAddModalOpen, setSiteAddModalOpen] = useState(false);
  const [siteEditModalOpen, setSiteEditModalOpen] = useState(false);
  const [siteDelOpen, setSiteDelOpen] = useState(false);
  const [siteMenuList, setSiteMenuList] = useState([]);
  const [pId, setPId] = useState("");
  const [sName, setSName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [pageItemChecked, setPageItemChecked] = useState([]);
  const [siteMenuTxt, setSiteMenuTxt] = useState("");
  const [siteData, setSiteData] = useState([]);
  const [propsProjectIds, setPropsProjectIds] = useState(
    props.userData.rights.Projects
  );
  const [siteList, setSiteList] = useState([]);
  const [headerData] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  });

  function addProjectRights() {
    if (errMsg === "") {
      var pageAccessList = [];
      pageAccessList = pageItemChecked.map((item) => {
        var pageName = {};
        pageName[item.item] = item.check;
        return pageName;
      });
      var page_access = {};

      page_access["sitename"] = sName;
      for (var elm in pageAccessList) {
        Object.keys(pageAccessList[elm]).forEach((key) => {
          page_access[key] = pageAccessList[elm][key];
        });
      }

      var data = {
        projectId: pId,
        Page_access: [page_access],
      };

      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;
      axios
        .patch(url + "/rights/addproject/" + props.userEmail, data, {
          headers: headerData,
        })
        .then((result) => {
          if (result.data.status === "success") {
            setPId("");
            setSName("");
            setPageItemChecked([]);
            setProjectAddModalOpen(false);
            var responseData = result.data.info.User.rights.Projects;
            setPropsProjectIds(responseData);
            setProjectMenuTxt("");
            setSiteMenuTxt("");
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 409) {
              setErrMsg(captions.error.USER_PROJECT_DUPLICATE);
            } else if (error.response.status === 400) {
              setErrMsg(captions.error.USER_EDIT_VALIDATION);
            } else if (error.response.status === 404) {
              setErrMsg(captions.error.USER_VALUE_ERROR);
            } else if (error.response.status === 401) {
              setErrMsg(captions.error.USER_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setErrMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
            }
          } else {
            setErrMsg(captions.error.INTERNAL_ERROR);
            setPId("");
            setSName("");
            setPageItemChecked([]);
            setProjectMenuTxt("");
            setSiteMenuTxt("");
          }
        });
    } else {
      setErrMsg(captions.error.FIELDS_EMPTY);
    }
  }

  function editSite() {
    if (errMsg === "") {
      var pageAccessList = [];
      pageAccessList = pageItemChecked.map((item) => {
        var pageName = {};
        pageName[item.item] = item.check;
        return pageName;
      });
      var page_access = {};
      for (var elm in pageAccessList) {
        Object.keys(pageAccessList[elm]).forEach((key) => {
          page_access[key] = pageAccessList[elm][key];
        });
      }
      
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;
      axios
        .patch(
          url +
            "/rights/updaterights/" +
            props.userEmail +
            "/" +
            projectMenuTxt +
            "/" +
            siteMenuTxt,
          page_access,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status === "success") {
            setSiteEditModalOpen(false);
            var responseData = result.data.info.User.rights.Projects;
            setPropsProjectIds(responseData);
            var mData = [];
            responseData.forEach((item) => {
              if (item.projectId === projectMenuTxt) {
                mData.push(item);
              }
            });
            setProjectSiteData(mData);
            setPageItemChecked([]);
            setSiteMenuTxt("");
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 400) {
              setErrMsg(captions.error.USER_EDIT_VALIDATION);
            } else if (error.response.status === 404) {
              setErrMsg(captions.error.USER_VALUE_ERROR);
            } else if (error.response.status === 401) {
              setErrMsg(captions.error.USER_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setErrMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
            }
          } else {
            setErrMsg(captions.error.INTERNAL_ERROR);
            setPageItemChecked([]);
            setSiteMenuTxt("");
          }
        });
    } else {
      setErrMsg(captions.error.FIELDS_EMPTY);
    }
  }

  function getSiteList(projectId) {
    //api call for that projectId - this.state.searchTxt
    if (projectId !== "") {
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
      axios
        .get(url + "/" + projectId, {
          headers: headerData,
        })
        .then((result) => {
          var siteIdOptions = [];
          if (result.data.status === "success") {
            var responseData = result.data.info;
            responseData.sites.forEach((item) => {
              siteIdOptions.push(item);
            });
            setSiteList(siteIdOptions);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setErrMsg(captions.error.PROJECT_UNAUTHORIZED);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.PROJECT_VALUE_ERROR);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.PROJECT_INTERNAL_ERROR);
          } else if (error.request) {
            setErrMsg(captions.error.captions.error.PROJECT_INTERNAL_ERROR);
          }
        });
    } else {
      setSiteList([]);
    }
    return siteList;
  }
  function getSitePageList(projectId, siteName) {
    //api call for that projectId - this.state.searchTxt
    if (projectId !== "" && siteName !== "") {
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
      axios
        .get(url + "/" + projectId + "/" + siteName, {
          headers: headerData,
        })
        .then((result) => {
          var dataList = [];
          if (result.data.status === "success") {
            var responseData = result.data.info;
            if ("testingtype" in responseData) {
              responseData.testingtype.forEach((testType) => {
                if (testType.type === "API")
                  dataList.push({
                    item: pageAccess_menu.pageList.api_test[0],
                    check: false,
                  });
                if (testType.type === "UI")
                  dataList.push({
                    item: pageAccess_menu.pageList.ui_test[0],
                    check: false,
                  });
                if (testType.type === "APP")
                  dataList.push({
                    item: pageAccess_menu.pageList.app_test[0],
                    check: false,
                  });
              });
              for (var item in pageAccess_menu.pageList.test_status) {
                dataList.push({
                  item: pageAccess_menu.pageList.test_status[item],
                  check: false,
                });
              }
            }
            if ("menus" in responseData) {
              responseData.menus.forEach((menuItem) => {
                dataList.push({ item: menuItem.code, check: false });
              });
            }
            setPageItemChecked(dataList);
          }
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setErrMsg(captions.error.PROJECT_UNAUTHORIZED);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.PROJECT_VALUE_ERROR);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.PROJECT_INTERNAL_ERROR);
          } else if (error.request) {
            setErrMsg(captions.error.captions.error.PROJECT_INTERNAL_ERROR);
          }
        });
    } else {
      setPageItemChecked([]);
    }
    return pageItemChecked;
  }
  function checkAllPageAccessFalse() {
    var checkKeys = pageItemChecked.map((item) => item.check);
    if (checkKeys.includes(true)) {
      return false;
    } else {
      return true;
    }
  }

  function getSiteName() {
    var siteData = [];
    if (siteList.length > 0 && propsProjectIds.length > 0) {
      var name,
        pageAccess = [];
      propsProjectIds.forEach((i) => {
        if (i.projectId === projectMenuTxt && i.Page_access !== null) {
          pageAccess.push(i.Page_access);
        }
      });
      name = pageAccess.length !== 0 ?pageAccess[0].map((item) => {
        return item.sitename;
      }) : []
      
      siteData = siteList.filter((x) => {
        return !name.includes(x);
      });
    }

    setSiteMenuList(siteData);
  }
  function goToProjectAddModal() {
    return (
      <UIModal
        open={projectAddModalOpen}
        handleClose={projectAddModalOpen}
        title={"Add Project Rights"}
        fields={
          <div className="col">
            <div className="d-flex mt-3">
              <span className="title-prompts mt-3">Project Rights</span>
              <UIDropDown
                value={pId}
                onChange={(e) => {
                  setSName("");
                  setPId(e.target.value);
                  setPageItemChecked([]);
                  getSiteList(e.target.value);
                  setErrMsg("");
                }}
                placeholder="Select project"
                minWidth={280}
                textAlign={"left"}
                maxHeight={350}
                list={props.projectList.filter((x) => {
                  var pList = propsProjectIds.map((i) => i.projectId);
                  return !pList.includes(x);
                })}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-3">Site Rights</span>
              <UIDropDown
                value={sName}
                onChange={(e) => {
                  setSName(e.target.value);
                  setPageItemChecked([]);
                  getSitePageList(pId, e.target.value);
                  setErrMsg("");
                }}
                placeholder="Select site"
                minWidth={280}
                textAlign={"left"}
                maxHeight={350}
                list={siteList}
              />
            </div>
            {pId !== "" && sName !== "" ? (
              <div className="d-flex mt-3">
                <span className="title-prompts mt-2">Page Access</span>
                <FormGroup>
                  {pageItemChecked.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          wordBreak: "break-word",
                        },
                      }}
                      control={
                        <Checkbox
                          color={"primary"}
                          checked={item.check}
                          onChange={(e) => {
                            if (e.target.checked) {
                              var c = pageItemChecked.map((i, q) => {
                                if (q === index) {
                                  i.check = true;
                                }
                                return i;
                              });
                              setPageItemChecked(c);
                            } else {
                              var d = pageItemChecked.map((i, q) => {
                                if (q === index) {
                                  i.check = false;
                                }
                                return i;
                              });
                              setPageItemChecked(d);
                            }
                          }}
                        />
                      }
                      label={item.item}
                    />
                  ))}
                </FormGroup>
              </div>
            ) : null}
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group">
            <div className="ml-4">
              <UIButton
                variant="contained"
                onClick={() => {
                  addProjectRights();
                }}
                disabled={
                  checkEmptyValues([pId, sName]) || checkAllPageAccessFalse()
                    ? true
                    : false
                }
                title={"Add Rights"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  setPId("");
                  setSName("");
                  setPageItemChecked([]);
                  setProjectMenuTxt("");
                  setSiteMenuTxt("");
                  setErrMsg("");
                  setProjectAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }

  function goToSiteAddModal(project) {
    
    return (
      <UIModal
        open={siteAddModalOpen}
        handleClose={siteAddModalOpen}
        title={"Add Site Rights"}
        fields={
          <div className="col">
            <div className="d-flex mt-3">
              <span className="title-prompts mt-3">Site Rights</span>
              <UIDropDown
                value={sName}
                onChange={(e) => {
                  setSName(e.target.value);
                  setPageItemChecked([]);
                  getSitePageList(project, e.target.value);
                  setErrMsg("");
                }}
                placeholder="Select site"
                minWidth={280}
                textAlign={"left"}
                maxHeight={350}
                list={siteMenuList/* .filter((x) => {
                  var sList = submenuCodeDB.map((i) => i.submenuCode);
                  return !sList.includes(x);
                }) */}
              />
            </div>
            {project !== "" && sName !== "" ? (
              <div className="d-flex mt-3">
                <span className="title-prompts mt-2">Page Access</span>
                <FormGroup>
                  {pageItemChecked.map((item, index) => (
                    <FormControlLabel
                      key={index}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          wordBreak: "break-word",
                        },
                      }}
                      control={
                        <Checkbox
                          color={"primary"}
                          checked={item.check}
                          onChange={(e) => {
                            if (e.target.checked) {
                              var c = pageItemChecked.map((i, q) => {
                                if (q === index) {
                                  i.check = true;
                                }
                                return i;
                              });
                              setPageItemChecked(c);
                            } else {
                              var d = pageItemChecked.map((i, q) => {
                                if (q === index) {
                                  i.check = false;
                                }
                                return i;
                              });
                              setPageItemChecked(d);
                            }
                          }}
                        />
                      }
                      label={item.item}
                    />
                  ))}
                </FormGroup>
              </div>
            ) : null}
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  addSite(project);
                }}
                disabled={
                  checkEmptyValues([sName]) || checkAllPageAccessFalse()
                    ? true
                    : false
                }
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  setSName("");
                  //setPageItemChecked([]);
                  setSiteMenuList([]);
                  setErrMsg("");
                  setSiteAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }

  function goToSiteEditModal() {
    return (
      <UIModal
        open={siteEditModalOpen}
        handleClose={siteEditModalOpen}
        title={"Edit Site Rights"}
        fields={
          <div className="col">
            <div className="d-flex mt-3">
              <span className="title-prompts mt-2">Page Access</span>
              <FormGroup>
                {pageItemChecked.map((item, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          wordBreak: "break-word",
                        },
                      }}
                      control={
                        <Checkbox
                          color={"primary"}
                          checked={item.check}
                          onChange={(e) => {
                            if (e.target.checked) {
                              var c = pageItemChecked.map((i, q) => {
                                if (q === index) {
                                  i.check = true;
                                }
                                return i;
                              });

                              setPageItemChecked(c);
                            } else {
                              var d = pageItemChecked.map((i, q) => {
                                if (q === index) {
                                  i.check = false;
                                }
                                return i;
                              });

                              setPageItemChecked(d);
                            }
                          }}
                        />
                      }
                      label={item.item}
                    />
                  );
                })}
              </FormGroup>
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  editSite();
                }}
                disabled={checkAllPageAccessFalse() ? true : false}
                title={"Apply"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  //setPageItemChecked([]);
                  setSiteMenuTxt("")
                  setErrMsg("");
                  setSiteEditModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }
  function goToProjectDelDialog(project) {
    return (
      <UIDialog
        open={projectDeleteModalOpen}
        handleClose={projectDeleteModalOpen}
        title={"Delete Confirmation"}
        content={"Are you sure, want to remove the project rights? "}
        handleNo={() => {
          setProjectDeleteModalOpen(false);
        }}
        handleYes={() => {
          delProject(project);
        }}
      />
    );
  }

  function goToSiteDelDialog(site) {
    return (
      <UIDialog
        open={siteDelOpen}
        handleClose={siteDelOpen}
        title={"Delete Confirmation"}
        content={"Are you sure, want to remove the site rights? "}
        handleNo={() => {
          setSiteDelOpen(false);
        }}
        handleYes={() => {
          delSite(site);
        }}
      />
    );
  }

  function addSite(project) {
    if (errMsg === "" && project !== "") {
      var pageAccessList = [];
      pageAccessList = pageItemChecked.map((item) => {
        var pageName = {};
        pageName[item.item] = item.check;
        return pageName;
      });
      var page_access = {};

      page_access["sitename"] = sName;
      for (var elm in pageAccessList) {
        Object.keys(pageAccessList[elm]).forEach((key) => {
          page_access[key] = pageAccessList[elm][key];
        });
      }
      var data = {
        Page_access : [page_access]
      }

      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;
      axios
        .patch(
          url + "/rights/addsite/" + props.userEmail + "/" + project,
          data,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status === "success") {
            setSName("");
            setPageItemChecked([]);
            setSiteAddModalOpen(false);
            var responseData = result.data.info.User.rights.Projects;
            setPropsProjectIds(responseData);
            var mData = [];
            responseData.forEach((item) => {
              if (item.projectId === project) {
                mData.push(item);
              }
            });
            setProjectSiteData(mData);
            setSiteMenuTxt("");
            setSiteMenuList([])
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 409) {
              setErrMsg(captions.error.USER_PROJECT_DUPLICATE);
            } else if (error.response.status === 400) {
              setErrMsg(captions.error.USER_EDIT_VALIDATION);
            } else if (error.response.status === 404) {
              setErrMsg(captions.error.USER_VALUE_ERROR);
            } else if (error.response.status === 401) {
              setErrMsg(captions.error.USER_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setErrMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
            }
          } else {
            setErrMsg(captions.error.INTERNAL_ERROR);
            setSName("");
            setPageItemChecked([]);
            setSiteMenuTxt("");
            setSiteMenuList([])
          }
        });
    } else {
      setErrMsg(captions.error.FIELDS_EMPTY);
    }
  }

  function delSite(site) {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;
    axios
      .delete(
        url +
          "/rights/deletesite/" +
          props.userEmail +
          "/" +
          projectMenuTxt +
          "/" +
          site,

        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          setSiteDelOpen(false);
          var responseData = result.data.info.User.rights.Projects;
          setPropsProjectIds(responseData);
          setProjectMenuTxt("");
          setSiteMenuList([]);
          setSiteMenuTxt("");
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 404) {
            setMenuErrMsg(captions.error.USER_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMenuErrMsg(captions.error.USER_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMenuErrMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
          }
        } else {
          setSiteDelOpen(false);
          setSiteMenuTxt("");
          setProjectMenuTxt("");
          setSiteMenuList([]);
          setMenuErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function delProject(project) {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;
    axios
      .delete(
        url + "/rights/deleteproject/" + props.userEmail + "/" + project.trim(),

        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          setProjectDeleteModalOpen(false);
          var responseData = result.data.info.User.rights.Projects;
          setPropsProjectIds(responseData);
          setProjectMenuTxt("");
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 404) {
            setMenuErrMsg(captions.error.USER_VALUE_ERROR);
          } else if (error.response.status === 401) {
            setMenuErrMsg(captions.error.USER_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMenuErrMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
          }
        } else {
          setProjectDeleteModalOpen(false);
          setProjectMenuTxt("");
          setMenuErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function modifyPageItemChecked() {
    var newPageChecked = pageItemChecked.map((i, q) => {
      if (i.item in siteData[0]) {
        i.check = siteData[0][i.item];
      }
      return i;
    });
    setPageItemChecked(newPageChecked);
  }

  return (
    <UIAccordion style={{ marginTop: "10px" }}>
      <UIAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem", color: "#000" }}
          />
        }
        style={{
          backgroundColor: "rgba(0, 0, 0, .09)",
          color: "#000",
          //border: "1px solid",
        }}
      >
        <div className="row">
          <div className="col text-left">
            <Typography>User Project Rights</Typography>
          </div>
        </div>
      </UIAccordionSummary>
      <UIAccordionDetails style={{ backgroundColor: "#fff" }}>
        <div className="row mt-3">
          <div className="col text-left ml-3">
            <div className="d-flex mr-5">
              <div>
                <UIDropDown
                  value={projectMenuTxt}
                  onChange={(e) => {
                    var mData = [];
                    propsProjectIds.forEach((item) => {
                      if (item.projectId === e.target.value) {
                        mData.push(item);
                      }
                    });

                    if (e.target.value !== "") {
                      getSiteList(e.target.value);
                      
                      
                    }

                    setProjectMenuTxt(e.target.value);
                    setMenuErrMsg("");
                    setProjectSiteData(mData);

                    setSiteMenuTxt("");
                  }}
                  placeholder="Project Access"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={propsProjectIds.map((item) => item.projectId)}
                />
              </div>
              <div className="col-sm-8 btn-group cp-btn-group mt-2">
                <div className="ml-1 ">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setProjectAddModalOpen(true);
                    }}
                    title={"Add Project Rights"}
                  />
                  {goToProjectAddModal()}
                </div>
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setProjectDeleteModalOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([projectMenuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Remove Project Rights"}
                  />
                  {projectMenuTxt !== ""
                    ? goToProjectDelDialog(projectSiteData[0].projectId)
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 border-top">
          <div className="col text-left  mt-4 ml-3">
            <div className="d-flex mr-5">
              <div>
                <UIDropDown
                  value={siteMenuTxt}
                  disabled={projectMenuTxt === "" ? true : false}
                  onChange={(e) => {
                    setSiteMenuTxt(e.target.value);
                    setMenuErrMsg("");
                    //getSiteName(projectMenuTxt)
                    var sMenu = [];
                    if (
                      projectSiteData.length !== 0 &&
                      projectSiteData[0].Page_access !== null
                    ) {
                      projectSiteData[0].Page_access.forEach((item) => {
                        if (item.sitename === e.target.value) {
                          return sMenu.push(item);
                        }
                      });
                    }
                    getSitePageList(projectMenuTxt, e.target.value);
                    setSiteData(sMenu);
                  }}
                  /* onOpen={(e) => {
                    getSiteName();
                  }} */
                  placeholder="Site access"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={280}
                  list={
                    projectSiteData.length !== 0 &&
                    projectSiteData[0].Page_access !== null
                      ? projectSiteData[0].Page_access.map(
                          (item) => item.sitename
                        )
                      : []
                  }
                />
              </div>
              <div className="col-sm-8 btn-group cp-btn-group mt-2">
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      getSiteName()
                      setSiteAddModalOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([projectMenuTxt])
                      ? checkEmptyValues([menuErrMsg])
                        ? false
                        : true
                      : true
                    }
                    title={"Add Site Rights"}
                  />
                  {projectMenuTxt !== ""
                    ? goToSiteAddModal(projectSiteData[0].projectId)
                    : null}
                </div>
                <div className="ml-1 ">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      modifyPageItemChecked();
                      setSiteEditModalOpen(true);
                      
                    }}
                    disabled={
                      checkNonEmptyValues([siteMenuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Edit Site Rights"}
                  />
                  {siteMenuTxt !== "" ? goToSiteEditModal() : null}
                </div>
                <div className="ml-1">
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setSiteDelOpen(true);
                    }}
                    disabled={
                      checkNonEmptyValues([siteMenuTxt])
                        ? checkEmptyValues([menuErrMsg])
                          ? false
                          : true
                        : true
                    }
                    title={"Remove Site Rights"}
                  />
                  {siteMenuTxt !== ""
                    ? goToSiteDelDialog(siteData[0].sitename)
                    : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </UIAccordionDetails>
    </UIAccordion>
  );
}

export default EditUserRights;
