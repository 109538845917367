import 'babel-polyfill';
import React,{ Component } from 'react';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Sidebar from './componet/Sidebar';
import PageContainer from './componet/PageContainer';
import Header from './componet/Header';
import env from './config/env.json';
import HelpIcon from '@material-ui/icons/Help';
import Tour from 'reactour';
import { IdleTimerProvider, IdleTimerConsumer } from 'react-idle-timer'
import { setUserSession, getUserSession } from './componet/UserSession';

export class Child extends Component {
  render() {
    return (
      <IdleTimerConsumer>
        {idleTimer => (
          <h1>{idleTimer.isIdle()}</h1>
        )}
      </IdleTimerConsumer>
    )
  }
}
class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideHeader: false,
      isTourOpen: false,
      showTour: false,
      current_page: '',
      isSessionActive:false
    };
    this.joyride = React.createRef();
    if (typeof process.env.REACT_APP_ENV == 'undefined') {
      window.configs = env.local;
    } else {
      window.configs = env[process.env.REACT_APP_ENV.trim()];
    }
    //console.log("REACT_APP_ENV", process.env.REACT_APP_ENV)
    //console.log("process.env.NODE_ENV", env[process.env.REACT_APP_ENV])
  }
  onIdle() {
    sessionStorage.clear();
    localStorage.removeItem('rioSession');
    localStorage.setItem('rioLogginStatus', 'N');
    this.props.history.push('/login');
  }
  //console.log("local",window.configs);
  toggleShowMore = () => {
    this.setState((prevState) => ({
      isShowingMore: !prevState.isShowingMore,
    }));
  };

  closeTour = () => {
    this.setState({ isTourOpen: false });
  };

  openTour = () => {
    this.setState({ isTourOpen: true });
  };
  enableTour() {
    this.setState({
      isTourOpen: true,
    });
  }
  rerenderComponent = (val) => {
    console.log(val);
    if(val){
      let session = getUserSession.getItem('email') == null ? false : true;
      this.setState({
        isSessionActive: session
      });
    }
    
  }
 
  componentDidMount() {
   
    this.setState({
      current_page: window.location.pathname
    });
  }

  render() {
    const { isTourOpen } = this.state;
    const accentColor = '#5cb7b7';

    var showHeader = true;
    var enable = false;
    var isSessionActive = this.state.isSessionActive;
    if (!isSessionActive) {
      showHeader = false;
    }

    return (
      <Router>
        <IdleTimerProvider
          timeout={1000 * 1800} //expire after 1800 seconds(30 mins)
          onIdle={this.onIdle}
        >
          <Child />
        </IdleTimerProvider>
        {this.state.current_page !== '/login' &&
          this.state.current_page !== '/SiteSelection' &&
          enable ? (
          <Tour
            onRequestClose={this.closeTour}
            steps={tourConfig}
            isOpen={isTourOpen}
            className="helper"
            rounded={5}
            startAt={0}
            accentColor={accentColor}
          />
        ) : (
          ''
        )}

        <div className="App">
        <PageContainer />
        </div>

        <button
          className={
            enable &&
              this.state.current_page !== '/login' &&
              this.state.current_page !== '/SiteSelection'
              ? 'tour'
              : 'hide'
          }
          onClick={() => this.enableTour()}
        >
          <HelpIcon></HelpIcon>
        </button>
      </Router>
    );
  }
}

const tourConfig = [
  {
    selector: '.navbar-toggler',
    content: 'Click here to access the user Profile',
  },
  {
    selector: '#siteSelect .list-group-item.active',
    content: 'Click here to change the site',
  },
];
export default App;
