import React from 'react';
import { Route } from 'react-router-dom';

import './PageContainer.css';
import TestExecution from './TestExecution';
import ApiExecution from './ApiExecution';
import AppExecution from './AppExecution';
import CurrentExecution from './CurrentExecutionV1';
import Trends from './Trends';
import Results from './Results';
import Queue from './Queue';
import ApiMonitor from './ApiMonitor';
import ApiReport from './ApiReport';
import MyDashboard from './MyDashboard';
import Login from './Login';
import SiteSelection from './SiteSelection';
import CurrentExecutionResults from './CurrentExecutionResults';
import DefectTrends from './DefectTrends';
import Analytics from './Analytics';
import ResultDetails from './ResultDetails';
import ResetPassword from './ResetPassword';
import ReacTour from './ReacTour';
import ChangePassword from './ChangePassword';
import RestrictedPage from './RestrictedPage';
import Header from './Header';
import Sidebar from './Sidebar';
import AdminPage from './adminComponents/AdminPage'
import AdminAddUser from './adminComponents/AdminAddUser'
import AdminEditUser from './adminComponents/AdminEditUser'
import AdminAddProject from './adminComponents/AdminAddProject'
import AdminEditProject from './adminComponents/AdminEditProject'
import TestGenAI from './aiAppComponents/testGen/TestGenAI'
import CHTestGenAI from './aiAppComponents/testGen/CHTestGenAI'

class PageContainer extends React.Component {
  render() {
    return (
      <React.Fragment>

        <Header />


        <div className="main-wrapper d-flex align-items-stretch">
          <Sidebar />
          <div className="content">
            <Route path="/" exact strict component={TestExecution} />
            <Route path="/TestExecution" exact strict component={TestExecution} />
            <Route path="/ApiExecution" exact strict component={ApiExecution} />
            <Route path="/AppExecution" exact strict component={AppExecution} />
            <Route path="/CurrentExecution" exact strict component={CurrentExecution} />
            <Route path="/CurrentExecutionResults" exact strict component={CurrentExecutionResults} />
            <Route path="/Results" exact strict component={Results} />
            <Route path="/Trends" exact strict component={Trends} />
            <Route path="/defecttrends" exact strict component={DefectTrends} />
            <Route path="/Queue" exact strict component={Queue} />
            <Route path="/ApiMonitor" exact strict component={ApiMonitor} />
            <Route path="/ApiReport" exact strict component={ApiReport} />
            <Route path="/MyDashboard" exact strict component={MyDashboard} />
            <Route path="/login" exact strict component={Login} />
            <Route path="/SiteSelection" component={SiteSelection} />
            <Route path="/ReacTour" component={ReacTour} />
            <Route path="/analytics/:code" component={Analytics} />
            <Route path="/ResultDetails/:exeid" component={ResultDetails} />
            <Route path="/resetpassword/:passcode" component={ResetPassword} />
            <Route path="/change-password" component={ChangePassword} />
            <Route path="/admin-page" component={AdminPage} />
            <Route path="/admin-add-user" component={AdminAddUser} />
            <Route path="/admin-edit-user" component={AdminEditUser} />
            <Route path="/admin-edit-project" component={AdminEditProject} />
            <Route path="/admin-add-project" component={AdminAddProject} /> 
            <Route path="/RestrictedPage" component={RestrictedPage} />
            <Route path="/testgen/:code" component={TestGenAI} />
            <Route path="/chtestgen/:code" component={CHTestGenAI} />
          </div>
        </div>

      </React.Fragment>
    );
  }
}

export default PageContainer;
