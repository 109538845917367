import React, { Component } from 'react'
import TabUIGeneric from './TabUIGeneric';
import Grid from '@material-ui/core/Grid';
import Highlight from 'react-highlight'
import Switch from '@material-ui/core/Switch';
class ApiResponse extends Component {
    constructor(props) {
        super(props)
        this.state = {
            panel: "panel",
            req_switched: false,
            res_switched: false,
        }
    }
    handleChangereqSwitch() {
        this.setState((prevstate) => ({
            req_switched: !prevstate.req_switched
        }));
    }
    handleChangeresSwitch() {
        this.setState((prevstate) => ({
            res_switched: !prevstate.res_switched
        }));
    }
    render() {
        let { apiresponse, classes } = this.props;
        debugger;
        const expanded = this.state.panel;
        // const { classes } = this.props;
        return (
            <Grid container spacing={0} >
                <Grid item xs={12} >

                    {
                        apiresponse &&
                        <div>
                            <Grid container spacing={1} className="graytext" style={{"padding":"10px"}}>
                                
                                <Grid item xs={3}>
                                    <span>ResponseTime : <b>{apiresponse.responseTime}</b> ms</span>
                                </Grid>
                                {/* <Grid item xs={2}>
                                    <span>Latency : <b>{apiresponse.latency}</b> ms</span>
                                </Grid> */}
                                <Grid item xs={2}>
                                    <span>Size : <b>{apiresponse.responseSize}</b> {apiresponse.responseSize ? "kb" : ""}</span>
                                </Grid>
                                <Grid item xs={2}>
                                    <span >Status : <b className={apiresponse.responseStatusCode == "200" ? "status-green" : ""}>{apiresponse.responseStatusCode}</b></span>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Grid container spacing={0} >
                                <Grid item xs={12} className='small-text'>
                                    <TabUIGeneric
                                        tabcount={[0, 1, 2, 3]}
                                        tablabel={["Headers (" + Object.keys(apiresponse.headers).length + ")", "Body", "Cookies", "Status Message"]}
                                        tabicon={[]}
                                        tabpanel={[
                                            <div>
                                                {Object.keys(apiresponse.headers).map((item, key) => {
                                                    return <div><b><span>{item + " : "}</span></b><span>{apiresponse.headers[item]}</span></div>
                                                })}
                                            </div>,
                                            <div>
                                                Pretty : <Switch
                                                    checked={this.state.res_switched}
                                                    onChange={(e) => this.handleChangeresSwitch()}
                                                    color="primary"
                                                    name="checkedA"
                                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                                />
                                                <Highlight innerHTML={!this.state.res_switched}>
                                                    {apiresponse.body}
                                                </Highlight>

                                            </div>,
                                            <div >{JSON.stringify(apiresponse.cookies)}</div>,
                                            <div>{JSON.stringify(apiresponse.responseStatusMessage)}</div>

                                        ]}

                                        // className={classes.tabhead}
                                        customStyle={{ "-webkit-mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)", "mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)" }}
                                    ></TabUIGeneric>
                                </Grid>
                            </Grid>

                        </div>
                    }


                </Grid>
            </Grid>
        )
    }
}

export default ApiResponse
