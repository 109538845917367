import React, { Component } from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import './TestExecution.css';
import { v4 as uuidv4 } from 'uuid';
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import {
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers';
import 'date-fns';
import axios from 'axios';
import { Redirect } from 'react-router';
import moment from 'moment-timezone';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
class ApiExecution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      time: new Date(),
      json1: [],
      fullSuiteJson: {},
      json: [],
      osJson: [],
      osJson1: [],
      moduleJSON: {},
      browserJson: [],
      cloudJson: [],
      formFactors: [],
      osAccessJson: [],
      browserList: [],
      orientationList: [],
      isStart: false,
      isStop: false,
      initialCloudJsonState: [],
      initialState: {
        json: [],
        osJson: [],
        browserJson: [],
      },
      allChecked: false,
      switched: false,
      selectedDate: new Date(),
      handleDateChange: new Date(),
      labels: '',
      isheading: true,
      is_text_data: true,
      ApiExecution: [],
      maindata: [],
      cancelButton: false,
      executionStarted: false,
      subscription: {
        status: false,
        name: ""
      },
      subswarning: false,
      noProjectAccess: false,
    };
    this.joyride = React.createRef();
  }

  removeRefernce = (obj) => {
    return JSON.parse(JSON.stringify(obj));
  };

  //Single selection button functionality.
  addClass = (parentIndex, childIndex) => {
    let json = this.state.json;
    json[parentIndex].step.forEach((element) => {
      element.isChecked = false;
    });
    json[parentIndex].step[childIndex].isChecked = true;
    const array = [...json];
    const name = json[parentIndex].step[childIndex].name;
    if (name === 'Module') {
      array.pop();
      array.push(this.state.moduleJSON);
    }
    if (name === 'Full Suite') {
      array.pop();
      array.push(this.state.fullSuiteJson);
    }
    this.setState({
      json: array,
    });
  };

  //Multi selection button functionality.
  addClass1 = (parentIndex, childIndex) => {
    let json = this.state.json;
    json[parentIndex].step[childIndex].isChecked = !json[parentIndex].step[
      childIndex
    ].isChecked;
    const array = [...json];

    const name = json[parentIndex].step[childIndex].name;
    if (name === 'Module') {
      array.pop();
      array.push(this.state.moduleJSON);
    }
    if (name === 'FullSuite') {
      array.pop();
      array.push(this.state.fullSuiteJson);
    }
    this.setState({
      json: array,
    });
  };

  //To show form factor based on the selection of cloud
  showFormFactor = (index) => {
    
    const cloudJsonStep = [...this.state.cloudJson.step];
    const step = { ...cloudJsonStep[index] };
    cloudJsonStep.forEach((element) => {
      element.isChecked = false;
    });
    step.isChecked = true;
    cloudJsonStep[index] = step;
    const cloudJson = {
      ...this.state.cloudJson,
      step: [...cloudJsonStep],
    };
    const formFactors = [
      ...cloudJsonStep[index].name.formfactors,
    ].map((item, index) => (
      {
        isChecked: index == 0 ? true : false, ...item
      })
    );
    this.setState({
      formFactors,
      cloudJson,
      osAccessJson: [],
      browserList: [],
    }, () => this.showFormFactorSubtype(0));

  };

  showFormFactorSubtype = (index) => {
    const formFactors = [...this.state.formFactors];
    const formFactor = formFactors[index];
    formFactors.forEach((element) => {
      element.isChecked = false;
    });
    formFactor.isChecked = true;
    const newFormFactors = [...formFactors];
    const osAccessObj = { ...formFactor.OSAccess };
    const osAccessJson = Object.keys(osAccessObj).map((item, index) => ({
      isChecked: index == 0 ? true : false,
      name: item,
      browserList: osAccessObj[item],
    }));
    this.setSubscription(formFactor.active, formFactor.name);
    if (formFactor.name == "Mobile") {
      this.setState({
        formFactors: newFormFactors,
        osAccessJson,
        browserList: [],
      }, () => this.showBrowser(1));
    }
    else {
      this.setState({
        formFactors: newFormFactors,
        osAccessJson,
        browserList: [],
      }, () => this.showBrowser(0));
    }

  };

  //To show browsers based on the selection of OS
  showBrowser = (index) => {
    let osAccessJson = [...this.state.osAccessJson];
    osAccessJson.forEach((element) => {
      element.isChecked = false;
    });
    osAccessJson[index].isChecked = true;
    const browserList = [...osAccessJson[index].browserList].map((item, index) => ({
      isChecked: index == 0 ? true : false,
      name: item,
    }));
    let orientationList = [];
    if ((this.state.formFactors.find((item) => item.isChecked).name) == ("Mobile")) {
      orientationList = [
        {
          isChecked: true,
          name: "Portrait",
        },
        {
          isChecked: false,
          name: "Landscape",
        }
      ]
    }
    else if ((this.state.formFactors.find((item) => item.isChecked).name) == ("Tablet")) {
      orientationList = [
        {
          isChecked: false,
          name: "Portrait",
        },
        {
          isChecked: true,
          name: "Landscape",
        }
      ]
    }

    this.setState({ osAccessJson, browserList, allChecked: true, orientationList });
  };

  //To add selected browser to browserJson
  selectBrowser = (index) => {
    let browserList = [...this.state.browserList];

    browserList.forEach((element) => {
      element.isChecked = false;
    });
    browserList[index].isChecked = true;
    this.setState(browserList);
  };

  //To add selected orientation to OrientationList
  selectOrientation = (index) => {
    let orientationList = [...this.state.orientationList];

    orientationList.forEach((element) => {
      element.isChecked = false;
    });
    orientationList[index].isChecked = true;
    this.setState(orientationList);
  };

  //For Cancel and start button
  toggleBox = (e) => {
    if (e.target.value === 'start') {
      this.setState({
        isStart: true,
        isStop: false,
      });
    } else {
      this.setState({
        isStop: true,
        isStart: false,
      });
    }
  };

  formate_Json = (data) => {
    const envName = data.testexecution.map((val) => {
      return val.split(':')[0];
    });
    let subsuite = {
      name: 'Choose Test Suite',
      multiselect: false,
      step: [
        {
          name: 'Full Suite',
          isChecked: true,
          unique: 'Restrict',
        },
        {
          name: 'Module',
          isChecked: false,
          unique: 'Restrict',
        },
      ],
    };

    let json = [];

    let envdata = {
      name: 'Choose Test Environment',
      multiselect: false,
      step: [],
    };
    envName.forEach((val, ind, arr) => {
      envdata.step.push({
        name: val,
        isChecked: ind === 0 ? true : false,
        unique: 'env',
      });
    });
    json.push(envdata);
    json.push(subsuite);
    this.setState({
      json: json
    });
  };

  format_fullsuite = (data) => {
    let fsData = {
      name: 'Sub-selection of fullSuite',
      step: [],
    };
    data.fullSuiteJson.forEach((val, ind, arr) => {
      fsData.step.push({
        name: val,
        isChecked: ind === 0 ? true : false,
        unique: 'fs',
      });
    });

    this.setState({
      fullSuiteJson: fsData,
    });
    this.setState({ allChecked: true });
  };
  format_module = (data) => {
    let moduleData = {
      name: 'Sub-selection of Module',
      step: [],
    };
    data.moduleJSON.forEach((val, ind, arr) => {
      moduleData.step.push({
        name: val,
        isChecked: ind === 0 ? true : false,
        unique: 'module',
      });
    });
    //moduleJSON.push(moduleData);
    this.setState({
      moduleJSON: moduleData,
    });
  };

  formatCloudType = (data) => {
    let cloudData = {
      name: 'Choose form factor',
      step: [],
    };
    data &&
      data.cloudJson &&
      data.cloudJson.forEach((val, ind, arr) => {
        if (typeof val.formFactor != "undefined") {
          let formfactorsdata = val.formfactors;
          cloudData.step.push({
            name: val,
            isChecked: ind === 0 ? true : false,
            unique: 'cloud',
            formFactors: formfactorsdata,
          });
        }
      });

    this.setState({
      cloudJson: cloudData,
      initialCloudJsonState: cloudData,
    }, () => {
      data && data.cloudJson && data.cloudJson.length > 0 && this.showFormFactor(0);
    });
  };

  formate_os = (data) => {
    let osData = [];

    const osName = data.osJson1 && Object.keys(data.osJson1);

    osName &&
      osName.forEach((val, ind, arr) => {
        let osObject = {
          name: val,
          isChecked: false,
          url: `../images/${val}.png`,
          children: [],
        };
        data.osJson1[val].forEach((_val, ind, arr) => {
          osObject.children.push({
            name: _val,
            isChecked: false,
            url: `../images/${_val}.png`,
          });
        });
        osData.push(osObject);
      });
    this.setState({
      osJson: osData,
    });
  };

  setData = () => {
    let arr = this.state.json;
    let that = this;
    let fullsuitefound = false;
    arr.forEach(function(key, item){
      if(key.name == that.state.fullSuiteJson.name){
        fullsuitefound = true;
      }
    })
    if(!fullsuitefound)
    arr.push(this.state.fullSuiteJson);
    const arr1 = this.removeRefernce(arr);
    const osJson = this.removeRefernce(this.state.osJson);
    this.setState({
      json: arr,
      initialState: {
        json: arr1,
        osJson: osJson,
        browserJson: [],
        is_text_data: true,
      },
    });
  };

  callmountandupdate() {
    this.componentDidMount();
  }
  componentDidMount() {
    let result = JSON.parse(sessionStorage.getItem('UserDetails'));
    if (!result) {
      this.props.history.push('/login');
    } else {
      setTimeout(() => {
        // //
        if (result.data.User.rights.Projects[0].Page_access != null) {
          var projectIndex = sessionStorage.getItem('selectedProjectIndex');
          var siteIndex = sessionStorage.getItem('selectedSiteIndex');
          let data = {
            "testexecution": "",
            "fullSuiteJson": "",
            "moduleJSON": "",
            // "osJson1": "",
            // "cloudJson": ""
          };
          let i = 0;
          let testingtype = result.data.User.rights.Projects[projectIndex].sites[siteIndex].testingtype;
          testingtype.forEach((val, ind, arr) => {
            i++;
            if (val.type == "API") {
              
              if (typeof val.Environment != "undefined") { data["testexecution"] = val.Environment; this.formate_Json(data); };
              if (typeof val.FullSuite != "undefined") { data["fullSuiteJson"] = val.FullSuite; this.format_fullsuite(data); };
              if (typeof val.Module != "undefined") { data["moduleJSON"] = val.Module; this.format_module(data); };
              // if (typeof val.OSAccess != "undefined") { data["osJson1"] = val.OSAccess; this.formate_os(data); };
              // if (typeof val.integrations != "undefined") { data["cloudJson"] = val.integrations; this.formatCloudType(data); };

            }
            if (i == testingtype.length) {
              this.setData();
            }
          });
        }
        else {
          this.setState({
            noProjectAccess: true
          })
        }
      }, 500);
    }
  }
  //To reset default selection
  reset = () => {
    const json = JSON.parse(JSON.stringify(this.state.initialState.json));
    const osJson = JSON.parse(JSON.stringify(this.state.initialState.osJson));
    const cloudJson = { ...this.state.initialCloudJsonState };
    this.setState({
      json,
      osJson,
      cloudJson,
      browserJson: [],
      // moduleJSON: {},
      formFactors: [],
      osAccessJson: [],
      browserList: [],
      switched: false,
      allChecked: false,
      isheading: true,
      cancelButton: false,
      orientationList: [],
      // switched: false,
      checked: false
    }, () => {this.setState({ allChecked: true})});
    // this.handleChange();
  };

  //To start Test execution
  startExcution = () => {
    // if (!this.state.subscription.status) {
    //   return false;
    // }
    const state = { ...this.state };
    setTimeout(() => {
      this.setState({
        allChecked: false,
        cancelButton: true,
      });
    }, 0);
    let envvalue = this.state.json;
    // let osvalue = this.state.osJson;
    // let browservalue = this.state.browserJson;
    let submitdate = [];
    let arr = [];
    // const selectedCloudType = state.cloudJson.step.find(
    //   (item) => item.isChecked
    // ).name.name;
    // const selectedformFactor = state.formFactors.find((item) => item.isChecked)
    //   .name;
    // const selectedOs = state.osAccessJson.find((item) => item.isChecked).name;
    // const selectedBrowser = state.browserList.find((item) => item.isChecked)
    //   .name;
    let scheduler_date_time = 'None';
    // let Orientation = selectedformFactor == 'Desktop' ? 'None' : state.orientationList.find((item) => item.isChecked).name;
    this.setState({
      labels: `Test execution is ${this.state.switched ? 'scheduled' : 'started'
        }, Please go to ${this.state.switched ? 'Queue' : 'Currently Executing'
        } for further information`,
      isheading: false,
    });
    envvalue.forEach((ele) => {
      ele.step.forEach((element) => {
        if (element.isChecked === true) {
          submitdate.push(element.name);
        }
      });
    });
    // var newele = osvalue.concat(browservalue);

    // newele.forEach((ele) => {
    //   if (ele.isChecked === true) {
    //     submitdate.push(ele.name);
    //   }
    // });
    arr.push({
      env: submitdate[0],
      suite: submitdate[1],
      subsuite: submitdate[2],
      os: submitdate[3],
      browser: submitdate[4],
    });
    var site = sessionStorage.getItem('selectedSite');
    var userName = sessionStorage.getItem('givenName');
    var userEmail = sessionStorage.getItem('email');
    if (this.state.switched === true) {
      var selectedDate = this.state.date;
      var selectedTime = this.state.time;
      var year = selectedDate.getFullYear();
      var months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ];
      var month = months[selectedDate.getMonth()];
      var day = selectedDate.getDate();
      var minutes = selectedTime.getMinutes();
      var hour = selectedTime.getHours();
      var miliseconds = selectedTime.getSeconds();
      scheduler_date_time = `${month}.${day}.${year}-${hour}:${minutes}:${miliseconds}`;
      this.setState({
        allChecked: true,
      });
    }

    // var url =
    //   window.configs.TRIGGER_SERVICE_URL +
    //   `auto-start/${submitdate[0]}/${submitdate[2]}/${site}/${userName}/${submitdate[3]}/${submitdate[4]}/${scheduler_date_time}/${userEmail}`;
    // '/auto-start/:env/:suite/:project/:user/:os/:browser/:schedule_time/:email'

    const url = `${window.configs.TRIGGER_SERVICE_URL + "v3/"}start-execution`;
    let groupStr = submitdate[1].toString();
    let group = groupStr === 'Module' ? 'module' : 'full';
    let device = "None"
    let browser = "None"
    // if ((state.formFactors.find((item) => item.isChecked).name) == ("Desktop")) {
    //   browser = selectedBrowser;
    // }
    // else {
    //   device = selectedBrowser;
    // }
    const data = {   
        env: "DEV",
        suite:  submitdate[2],
        scheduler_date_time:
        scheduler_date_time === 'None' ? '' : scheduler_date_time,
        project: site,
        site: site,
        email: userEmail,
        formfactor: "Desktop",
        integration: "CHCLOUD",
        group: group,
        os_version: "",
        device: "",
        autversion: "",
        autURL: "",
        suites_or_modules: "",
        testType : "api",
        timezone: moment.tz.guess(),
    };

    axios
      .post(url, data)
      .then((result) => {
        if (result.data.status === 'started') {
          setTimeout(
            () => this.setState({ executionStarted: true }),
            3000
          );

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleChange = () => {
    this.setState((prevstate) => ({
      switched: !prevstate.switched,
      checked: !prevstate.checked
    }));
  };

  //To change date
  handleDatechange = (event, date) => {
    this.setState({ date: event });
  };

  //To change time
  handleTimechange = (event, time) => {
    this.setState({ time: event });
  };

  setSubscription = (status, formfactor) => {
    if (typeof formfactor == "undefined") {
      formfactor = "Desktop"
    }
    if (typeof status == "undefined") {
      status = true
    }
    let subscription = {
      status: status,
      name: formfactor
    }
    this.setState({ subscription: subscription });
    if (!status) {
      this.setOpen(true)
    }
    else {
      this.setOpen(false)
    }
  };
  setOpen = (status) => {
    this.setState({ subswarning: status })
  };
  render() {
    if (this.state.executionStarted) return <Redirect to="/CurrentExecution" />;
    let tz = moment().tz(moment.tz.guess()).format('z')
    return (
      <React.Fragment>
        <div className="card  custcard">
          <div className="cord-body">
            {this.state.noProjectAccess ?
              <div>
                <h1>Project access is not avaliable.</h1>
                {/* <p>Please contact your admin for access</p> */}
              </div>
              :
              <div className="testHeading">
                {this.state.isheading ? (
                  <label className="headermsg">Initiate a Test</label>
                ) : (
                  <label className="headermsg">{this.state.labels}</label>
                )}
              </div>
            }

          </div>
        </div>
        {this.state.is_text_data === true && !this.state.noProjectAccess && (
          <div className="card  custcard">
            <div className="cord-body mt-3">
              {this.state.json.map((steps, index) => (
                <div
                  key={index}
                  className="d-flex flex-row align-items-center justify-content-start mb-3 pl-3"
                >
                  <span className="title-prompts">{steps.name}:</span>
                  <div key={index} className="col-sm-8 btn-group cp-btn-group">
                    {!steps.multiselect
                      ? steps.step.map((step, i) => (
                        <div key={i} className="btn-wrapper">
                          <input
                            type="radio"
                            name={step.unique}
                            value=""
                            className={step.isChecked ? 'active' : 'inactive'}
                            onClick={() => {
                              this.addClass(index, i);
                            }}
                          />
                          <button type="button" className="btn btn-secondary">
                            {step.name}
                          </button>
                        </div>
                      ))
                      : steps.step.map((step, i) => (
                        <div key={i} className="btn-wrapper">
                          <input
                            type="checkbox"
                            name={step.unique}
                            value=""
                            className={step.isChecked ? 'active' : 'inactive'}
                            onClick={() => {
                              this.addClass1(index, i);
                            }}
                          />
                          <button type="button" className="btn btn-secondary">
                            {step.name}
                          </button>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
              {/* Choose cloud type */}
              <div className="d-flex flex-row align-items-center justify-content-start mb-3 pl-3 os-list">
                {this.state.cloudJson.step &&
                  this.state.cloudJson.step.length > 0 &&
                  <span className="title-prompts">
                    Choose Cloud Type for Test:
                  </span>
                }
                <div className="col-sm-8 btn-group cp-btn-group">
                  {this.state.cloudJson.step &&
                    this.state.cloudJson.step.length > 0 &&
                    this.state.cloudJson.step.map((cloud, index) => (
                      <div key={index} className="btn-wrapper">
                        <input
                          type="radio"
                          name={cloud.name.name}
                          title={cloud.name.name}
                          value=""
                          className={cloud.isChecked ? 'active' : 'inactive'}
                          onClick={() => {
                            this.showFormFactor(index);
                          }}
                        />
                        <button
                          type="button"
                          className="btn btn-secondary"
                          style={{ padding: '6px 0px' }}
                        >
                          {/* <img
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `../images/no_image.png`;
                            }}
                            src={`../images/${cloud.name.name}.png`}
                            alt={cloud.name.name}
                          /> */}
                          {cloud.name.name}
                        </button>
                      </div>
                    ))}
                </div>
              </div>
              {/* Show FormFactors based on cloud selection */}
              {this.state.formFactors.length > 0 && (
                <div className="d-flex flex-row align-items-center justify-content-start mb-3 pl-3 ">
                  <span className="title-prompts">
                    Choose formfactor for Test:
                  </span>
                  <div className="col-sm-8 btn-group cp-btn-group">
                    {this.state.formFactors.map((form, index) => (
                      <div key={index} className="btn-wrapper">
                        <input
                          type="radio"
                          name={form.name}
                          title={form.name}
                          value=""
                          className={form.isChecked ? 'active' : 'inactive'}
                          onClick={() => {
                            this.showFormFactorSubtype(index);
                            this.setSubscription(form.active, form.name);
                          }}
                        />
                        <button
                          type="button"
                          className={'btn btn-secondary'}
                          title={form.name}
                        >
                          {/* <img
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `../images/no_image.png`;
                            }}
                            src={`../images/${form.name}.png`}
                            alt={form.name}
                          /> */}
                          {form.name}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {/* Show OS based on form factor selection */}
              {this.state.osAccessJson && this.state.osAccessJson.length > 0 && (
                <div className="d-flex flex-row align-items-center justify-content-start mb-3 pl-3 os-list">
                  <span className="title-prompts">
                    Choose Operating System for Test:
                  </span>
                  <div className="col-sm-8 btn-group cp-btn-group">
                    {this.state.osAccessJson.map((os, index) => (
                      <div key={index} className="btn-wrapper">
                        <input
                          type="radio"
                          name={os.name}
                          title={os.name}
                          value=""
                          className={os.isChecked ? 'active' : 'inactive'}
                          onClick={() => {
                            this.showBrowser(index);
                          }}
                        />
                        <button type="button" className="btn btn-secondary">
                          <img
                            src={`../images/${os.name}.png`}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = `../images/no_image.png`;
                            }}
                            alt={os.name}
                          />
                          {/* {os.name} */}
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              
              
              {this.state.allChecked && (
                <div className="d-flex flex-row align-items-center justify-content-start mb-3 pl-3 custSchedule">
                  <span className="title-prompts"></span>
                  <FormControlLabel
                    value="start"
                    id="Switch_toggle"
                    control={<Switch checked={this.state.checked}  onChange={this.handleChange} color="primary" />}
                    label="Schedule this execution "
                    labelPlacement="start"
                   
                    
                  />
                </div>
              )}
              {this.state.switched && (
                <div className="d-flex flex-row align-items-center justify-content-start mb-3 pl-3 custSchedule">
                  <span className="title-prompts"></span>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* <DatePicker value={this.state.} onChange={event => this.setState({selectedDate: event.target.value})}/> */}
                    <DatePicker
                      // autoOk={true}
                      className="Date_Pick"
                      value={this.state.date}
                      onChange={this.handleDatechange}
                      format="MMM dd yyyy"
                      minDate={new Date()}
                    />
                    <TimePicker
                      value={this.state.time}
                      defaultValue={this.defaultValue}
                      onChange={this.handleTimechange}
                    />
                    {tz}
                  </MuiPickersUtilsProvider>
                </div>
              )}
            </div>

            <div className="FooterBAck d-flex flex-column justify-content-start">
              <div className="card">
                <div className="card-body">
                  <Grid container spacing={3}>
                    <Grid item xs>

                    </Grid>
                    <Grid item xs>

                    </Grid>
                    <Grid item xs>
                      <Paper>
                        {this.state.subswarning === true && (
                          <Alert
                            severity="warning"
                            action={
                              <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                  this.setOpen(false);
                                }}
                              >
                                <CloseIcon fontSize="inherit" />
                              </IconButton>
                            }
                          >
                            {this.state.subscription.name + " subscription is not activated for your account"}
                          </Alert>
                        )}
                      </Paper>
                    </Grid>
                  </Grid>
                </div>
                <div className="card-body">

                  <div className="btn-group cp-btn-group1">
                    <div className="btn-wrapper" onClick={this.startExcution}>
                      <input
                        type="radio"
                        value="start"
                        id="start"
                        name="start"
                      />
                      <button
                        // disabled={!this.state.subscription.status}
                        className={`btn btn-info btn-allow ${this.state.isStart ? 'active1' : 'inactive',
                          !this.state.subscription.status ? 'btn-disabled' : ''
                          }`}
                      >
                        <span className="cancelstartmark" disabled>
                          &#10003;
                        </span>
                        {this.state.switched ? 'Schedule' : 'Start'}
                      </button>
                    </div>
                    <div className="btn-wrapper" onClick={this.reset}>
                      <Box
                        borderColor="error.main"
                        border={0}
                        borderRadius="50%"
                      >
                        <input
                          type="radio"
                          id="start"
                          value="stope"
                          name="start"
                        />
                        <button
                          type="button"
                          id="cancel"
                          disabled={this.state.cancelButton}
                          className={`btn btn-info btn-allow  ${this.state.isStop ? 'active1' : 'inactive'
                            }`}
                        >
                          {' '}
                          <span aria-hidden="true" className="cancelstartmark">
                            &times;
                          </span>
                          Cancel
                        </button>
                      </Box>
                    </div>

                  </div>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.is_text_data === false && (
          <div className="card error_queue">
            Waiting to get test execution list...
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ApiExecution;
