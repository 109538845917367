import React, { Component } from 'react';
import { withRouter, Link, History } from 'react-router-dom';
import pageAccessObj from './pageAccessObj';
const pageAccessObjSchema = [...pageAccessObj]
class PageRedirector extends Component {
    
  constructor(props) {
    super(props);
    this.state = {
     
    };
  }
  
  componentDidMount() {
    var project = sessionStorage.getItem('selectedProjectIndex');
    var projectName = sessionStorage.getItem('selectedProject');
    var siteName = sessionStorage.getItem('selectedSite');
    var site = sessionStorage.getItem('selectedSiteIndex');
    var current_page = this.props.location.pathname;
    var userDetails =
      JSON.parse(sessionStorage.getItem('UserDetails')) != null
        ? JSON.parse(sessionStorage.getItem('UserDetails')).data.User.rights
            .Projects
        : {};
    let pageAccess = userDetails[project].Page_access ? userDetails[project].Page_access[0] : null;
    let that = this;
    pageAccessObjSchema.forEach((val) => {

        if(pageAccess && val.path == current_page && !pageAccess[val.key] && current_page != "/Restricted"){
            var selectedpage = JSON.parse(sessionStorage.getItem('selectedPage'))
            
            // if(current_page != "/RestrictedPage")
            this.props.history.push('/RestrictedPage');
            //window.location = '/RestrictedPage';
        }
    })
        
  }
  render() {
      return (
       <div></div>
      );
  }
}

export default withRouter(PageRedirector);
