import React from 'react';
import * as serviceWorker from './serviceWorker';
import './CurrentExecutionResults.css';

class CurrentExecutionResults extends React.Component {
  // Initialize socket variable
  // socket = null;
  constructor(props) {
    super(props);
    this.state = {
      testResultArray: [
        {
          pass: '',
          fail: '',
          error: '',
        },
      ],
      pass: [],
      fail: [],
      error: [],
      room: this.props.room,
      showPass: false,
      showFail: false,
      showError: false,
    };
  }
  componentDidMount() {
  }


  render() {
    const { testResultArray } = this.state;
    return (
      <React.Fragment>
        {testResultArray.map((result, i) => (
          <section className="currently-executing" key={i}>
            <section className="card">
              <section className="cord-body">
                <ul className="header-data">
                  {/* <li className="pass" onMouseEnter={() => { this.showPass() }} onMouseLeave={() => { this.hidePass() }}><span>Pass : </span>{result.pass} */}
                  <li className="pass">
                    <span>Pass : {this.props.pass}</span>
                    {result.pass}
                    {this.state.showPass ? (
                      <section className="pfe-data">
                        {this.state.pass.map((data) => (
                          <span>{data}</span>
                        ))}
                      </section>
                    ) : (
                      ''
                    )}
                  </li>
                  {/* <li className="fail" onMouseEnter={() => { this.showFail() }} onMouseLeave={() => { this.hideFail() }}><span>Fail : </span>{result.fail} */}
                  <li className="fail">
                    <span>fail : {this.props.fail} </span>
                    {result.fail}
                    {this.state.showFail ? (
                      <section className="pfe-data">
                        {this.state.fail.map((data) => (
                          <span>{data}</span>
                        ))}
                      </section>
                    ) : (
                      ''
                    )}
                  </li>
                  {/* <li className="error" onMouseEnter={() => { this.showError() }} onMouseLeave={() => { this.hideError() }}><span>Error : </span>{result.error} */}
                  <li className="error">
                    <span>Error : {this.props.error}</span>
                    {result.error}
                    {this.state.showError ? (
                      <section className="pfe-data">
                        {this.state.error.map((data) => (
                          <span>{data}</span>
                        ))}
                      </section>
                    ) : (
                      ''
                    )}
                  </li>
                </ul>
                {/* <section className={"execution-detail " + this.state.show?"hide":"show"}>
                                <section className="pass">
                                    <p>Pass Tests:</p>
                                    {result.data.pass.map((data) => (
                                        <span>{data}</span>
                                    ))}
                                </section>
                                <section className="fail">
                                <p>Fail Tests:</p>
                                    {result.data.fail.map((data) => (
                                        <span>{data}</span>
                                    ))}
                                </section>
                                <section className="error">
                                <p>Error Tests:</p>
                                    {result.data.error.map((data) => (
                                        <span>{data}</span>
                                    ))}
                                </section>
                            </section> */}
              </section>
            </section>
          </section>
        ))}
      </React.Fragment>
    );
  }
}
export default CurrentExecutionResults;
serviceWorker.unregister();
