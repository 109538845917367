import React, { useState, useEffect } from "react";
import "./EditProject.css";

import axios from "axios";
import captions from "../../config/captions.json";
import {
  UIDropDown,
  UIButton,
  UITextField,
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
  UIModal,
} from "../UIComponents";
import { checkEmptyValues } from "../checkFields";
import {
  formFactorsList,
  desktopOSList,
  mobTabOSList,
  macBrowserList,
  windowsBrowserList,
  linBrowserList
} from "../../config/siteItemList";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";
import EditSiteFormFactor from "./EditSiteFormFactor";

function EditSiteIntegration(props) {
  const [formFactorAddModalOpen, setFormFactorAddModalOpen] = useState(false);
  const [intgData, setIntgData] = useState(props.integrationData);
  const [tFormFactor, setTFormFactor] = useState("");
  const [tOS, setTOS] = useState("");
  const [tDevice, setTDevice] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })

  useEffect(() => {
    setIntgData(props.integrationData);
  }, [props]);

  function resetFormFactorAddModalValues() {
    setTOS("");
    setTDevice("");
    setTFormFactor("");
    setErrMsg("");
  }

  function checkBrowser(osName){
      if(osName === "Mac"){
        return macBrowserList
      }else if(osName === "Windows"){
        return windowsBrowserList
      }
      else if(osName === "Linux"){
        return linBrowserList
      }
  }

  function addFormFactor() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    var factors = {
      name: tFormFactor,
      active: true,
      OSAccess: {},
    };
    //no browsers for desktop app testing
    factors.OSAccess[tOS] = props.testType === "APP" && tFormFactor === "Desktop" ? []:[tDevice];
    var data = {
      type: props.testType,
      integrations: [{ name: props.integrationName, formfactors: [factors] }],
    };
    axios
      .patch(
        url + "/" + props.projId + "/" + props.siteId + "/testingType",
        data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          setErrMsg("");
          responseData.sites.map((item) => {
            if (item.siteid === props.siteId) {
              resetFormFactorAddModalValues();
              props.setPropsTestingTypeData(item.testingtype);
              item.testingtype.map((testItem) => {
                if (testItem.type === props.testType) {
                  props.setTestTypeData(testItem);
                  testItem.integrations.map((i) => {
                    if (i.name === props.integrationName) {
                      setIntgData(i);
                    }
                  });

                  setFormFactorAddModalOpen(false);
                }
              });
            }
          });
        } else {
          setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 401) {
            setErrMsg(captions.error.SITE_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
          } else {
            resetFormFactorAddModalValues();
            setErrMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          resetFormFactorAddModalValues();
          setErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }
  function checkAddFormfactorOnTestType(testType) {
    const valuesToCheck = (testType === "UI" || (testType === "APP" && tFormFactor !== "Desktop"))
        ? [tFormFactor, tDevice, tOS]
        : [tFormFactor, tOS];
        
    return checkEmptyValues(valuesToCheck);
}

  function goToFormFactorAddModal(intg) {
    return (
      <UIModal
        open={formFactorAddModalOpen}
        handleClose={formFactorAddModalOpen}
        title={"Add Form Factor"}
        fields={
          <div className="col">
            <div>
              <div className="d-flex mt-4">
                <span className="title-prompts mt-3">Form Factor</span>
                <UIDropDown
                  value={tFormFactor}
                  onChange={(e) => {
                    setTOS("");
                    setTDevice("");
                    setTFormFactor(e.target.value);
                    setErrMsg("");
                  }}
                  placeholder="Select form factor"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={formFactorsList.filter((x) => {
                    var fList = intg.map((i) => i.name);
                    return !fList.includes(x);
                  })}
                />
              </div>
              {tFormFactor !== "" ? (
                <div>
                  <div className="d-flex mt-4">
                    <span className="title-prompts mt-3">OS</span>
                    <UIDropDown
                      value={tOS}
                      onChange={(e) => {
                        setTDevice("");
                        setTOS(e.target.value);

                        setErrMsg("");
                      }}
                      placeholder="Select os"
                      minWidth={280}
                      textAlign={"left"}
                      maxHeight={350}
                      list={
                        tFormFactor === "Desktop" ? desktopOSList : mobTabOSList
                      }
                    />
                  </div>

                  {tOS !== "" ? (
                    tFormFactor === "Desktop" ? (
                      props.testType === "UI"?
                      <div className="d-flex mt-4">
                        <span className="title-prompts mt-3">Browser</span>
                        <UIDropDown
                          value={tDevice}
                          onChange={(e) => {
                            setTDevice(e.target.value);
                            setErrMsg("");
                          }}
                          placeholder="Select browser"
                          minWidth={280}
                          textAlign={"left"}
                          maxHeight={350}
                          list={
                            //tOS === "Mac" ? macBrowserList : windowsBrowserList
                            checkBrowser(tOS)
                          }
                        />
                      </div>
                      :null
                    ) : (
                      <div className="d-flex mt-4">
                        <span className="title-prompts mt-1">Device</span>
                        <UITextField
                          value={tDevice}
                          placeholder="Please enter device name"
                          onChange={(e) => {
                            setTDevice(e.target.value);
                            setErrMsg("");
                          }}
                          width={310}
                        />
                      </div>
                    )
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  addFormFactor();
                }}
                disabled={
                  checkAddFormfactorOnTestType(props.testType)
                }
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetFormFactorAddModalValues();
                  setFormFactorAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }
  return (
    <UIAccordion style={{ marginTop: "5px" }}>
      <UIAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem", color: "#000" }}
          />
        }
        style={{
          backgroundColor: "#fff",
          color: "#000",
          borderBottom: "1px solid #dfdfdf",
        }}
      >
        <div className="row">
          <div className="col text-left">
            <Typography>{props.integrationName}</Typography>
          </div>
        </div>
      </UIAccordionSummary>
      <UIAccordionDetails>
        {intgData.formfactors && intgData.formfactors.map((formItem, fIndex) => (
          <EditSiteFormFactor
            key={fIndex}
            projId={props.projId}
            siteId={props.siteId}
            testType={props.testType}
            integrationType={props.integrationName}
            formFactor={formItem}
            formFactorName={formItem.name}
            setIntgData={setIntgData}
            setPropsTestingTypeData={props.setPropsTestingTypeData}
            setTestTypeData={props.setTestTypeData}
          />
        ))}
        <div className="row ml-1">
          <div className="col text-right">
            <UIButton
              variant="text"
              title={"Add Form factor"}
              disabled={
                intgData.formfactors.length === formFactorsList.length
                  ? true
                  : false
              }
              onClick={() => {
                setFormFactorAddModalOpen(true);
              }}
            />

            {goToFormFactorAddModal(intgData.formfactors)}
          </div>
        </div>
      </UIAccordionDetails>
    </UIAccordion>
  );
}

export default EditSiteIntegration;
