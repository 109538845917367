import React, { Component } from "react";
import "./EditProject.css";
import axios from "axios";
import captions from "../../config/captions.json";
import {
  checkNameField,
  checkValidNonEmptyValues,
  checkEmptyValues,
  checkNonEmptyValues,
} from "../checkFields";
import {
  UIDropDown,
  UIButton,
  UITextField,
  UIAccordion,
  UIAccordionDetails,
  UIAccordionSummary,
} from "../UIComponents";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import EditSite from "./EditSite";
import AddSite from "./AddSite";

class AdminEditProject extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pBtnClick: false,
      projectIdList: [],
      searchTxt: "",
      errMsg: "",
      basicProjectData: {},
      projData: [],
      projectName: "",
      projectNameValid: true,
      projectEdit: false,
      projectCancel: false,
      projectApply: false,
      projectStatus: true,

      siteIdOptions: [],
      siteData: [],
      siteTxt: "",
      sEditBtnClick: false,
      sAddBtnClick: false,
      siteId: "",
      siteName: "",
      headerData: {
        Authorization: sessionStorage.getItem("authToken")
          ? sessionStorage.getItem("authToken")
          : "",
        "ch-subscription-key": sessionStorage.getItem("authToken")
          ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
              "ch-subscription-key"
            ]
          : "",
      },
    };
  }

  updateBasicProjDetails = (proj, updatedProjectData) => {
    //api call for updating project name

    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;

    axios
      .patch(url + "/" + proj, updatedProjectData, {
        headers: this.state.headerData,
      })
      .then((result) => {
        console.log(result)
        if (result.data.status === "success") {
          var responseData = result.data.info;
          this.setState((prevState) => ({
            projData: {
              // object that we want to update
              ...prevState.projData, // keep all other key-value pairs
              ProjectName: responseData.ProjectName,
              active: responseData.active, // update the value of specific key
            },
          }));
        } else {
          this.setState({ errMsg: captions.error.PROJECT_EDIT_INTERNAL_ERROR });
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            this.setState({ errMsg: captions.error.PROJECT_ADD_VALIDATION });
          } else if (error.response.status === 404) {
            this.setState({ errMsg: captions.error.PROJECT_ADD_VALIDATION });
          } else if (error.response.status === 401) {
            this.setState({ errMsg: captions.error.PROJECT_UNAUTHORIZED });
          } else if (error.response.status === 500) {
            this.setState({
              errMsg: captions.error.PROJECT_EDIT_INTERNAL_ERROR,
            });
          }
        } else {
          this.setState({ errMsg: captions.error.INTERNAL_ERROR });
        }
      });
    this.setState({ basicProjectData: {} });
  };
  getSiteDetails = () => {
    if (this.state.siteTxt) {
      //api call for only sites data of that project
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
      axios
        .get(url + "/" + this.state.searchTxt + "/" + this.state.siteTxt, {
          headers: this.state.headerData,
        })
        .then((result) => {
          if (result.data.status === "success") {
            var responseData = result.data.info;

            this.setState({
              siteData: responseData,
              siteId: responseData.siteid,
              siteName: responseData.sitename,
              sEditBtnClick: true,
              sAddBtnClick: false,
              pBtnClick: true,
            });
          } else {
            this.setState({
              errMsg: captions.error.PROJECT_EDIT_INTERNAL_ERROR,
            });
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 401) {
              this.setState({ errMsg: captions.error.PROJECT_UNAUTHORIZED });
            } else if (error.response.status === 500) {
              this.setState({
                errMsg: captions.error.INTERNAL_ERROR,
              });
            }
          } else {
            this.setState({
              errMsg: captions.error.INTERNAL_ERROR,
            });
          }
        });
    }
  };

  getProjectData = () => {
    //api call for that projectId - this.state.searchTxt
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .get(url + "/" + this.state.searchTxt, {
        headers: this.state.headerData,
      })
      .then((result) => {
        var siteIdOptions = [];
        if (result.data.status === "success") {
          var responseData = result.data.info;
          responseData.sites.map((item) => {
            siteIdOptions.push(item);
          });
          this.setState({
            projData: responseData,
            projectName: responseData.ProjectName,
            projectStatus: responseData.active,
            siteIdOptions: siteIdOptions,
            siteTxt: "",
            pBtnClick: true,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          this.setState({ errMsg: captions.error.PROJECT_UNAUTHORIZED });
        } else if (error.response.status === 404) {
          this.setState({
            errMsg: captions.error.PROJECT_VALUE_ERROR,
          });
        } else if (error.response.status === 500) {
          this.setState({
            errMsg: captions.error.PROJECT_EDIT_INTERNAL_ERROR,
          });
        } else if (error.request) {
          this.setState({
            errMsg: captions.error.INTERNAL_ERROR,
          });
        }
      });
  };

  getProjectIdList = () => {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .get(url, {
        headers: this.state.headerData,
      })
      .then((response) => {
        if (response.data.status === "success") {
          var response_projectIds = response.data.info;
          var projectIdList = [];
          response_projectIds.map((item) => projectIdList.push(item));

          this.setState({
            projectIdList: projectIdList,
          });
        }
      })
      .catch((error) => {
        console.log("error " + error);
        if ("response" in error) {
          if (error.response.status === 401) {
            this.setState({ errMsg: captions.error.PROJECT_UNAUTHORIZED });
          } else if (error.response.status === 500) {
            this.setState({
              errMsg: captions.error.INTERNAL_ERROR,
            });
          }
        } else {
          this.setState({
            errMsg: captions.error.INTERNAL_ERROR,
          });
        }
      });
  };
  componentDidMount() {
    if (
      sessionStorage.getItem("UserDetails") &&
      "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User &&
      JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role ===
        "admin"
    ) {
      this.getProjectIdList();
    }
  }

  accordianComponent = () => {
    return (
      <UIAccordion expanded={true}>
        <UIAccordionSummary
          aria-controls="panel1d-content"
          style={{ cursor: "default", color: "#fff" }}
        >
          <div className="row">
            <div className="col text-left">
              <Typography>Project ID: {this.state.searchTxt}</Typography>
            </div>
          </div>
        </UIAccordionSummary>
        <UIAccordionDetails
          style={{
            backgroundColor: "#fff",
          }}
        >
          <div className="row">
            <div className="col text-left">
              <Typography style={{ margin: "5px", color: "gray" }}>
                Project Name
              </Typography>
            </div>
          </div>
          <div className="row">
            <div className="col text-left ml-1 mt-2">
              <UITextField
                value={this.state.projectName}
                width={310}
                placeholder="Please enter the Project Name"
                onChange={(e) => {
                  this.setState({
                    projectName: e.target.value,

                    projectApply: true,
                    basicProjectData: {
                      ...this.state.basicProjectData,
                      ProjectName: e.target.value,
                    },
                  });
                  checkNameField(e.target.value)
                    ? this.setState({ projectNameValid: true })
                    : this.setState({ projectNameValid: false });
                }}
                helperText={
                  !this.state.projectNameValid && this.state.projectName !== ""
                    ? captions.validation.PROJECT_NAME
                    : ""
                }
                error={
                  !this.state.projectNameValid && this.state.projectName !== ""
                }
                disabled={this.state.projectEdit ? false : true}
                //disableUnderline={this.state.projectEdit ? false : true}
              />
            </div>
            <div className="col text-right">
              {this.state.projectEdit ? (
                <div>
                  <UIButton
                    variant="text"
                    disabled={
                      checkValidNonEmptyValues(
                        [this.state.projectApply, this.state.projectNameValid],
                        [this.state.projectName]
                      )
                        ? false
                        : true
                    }
                    onClick={() => {
                      this.updateBasicProjDetails(
                        this.state.searchTxt,
                        this.state.basicProjectData
                      );

                      this.setState({
                        projectEdit: !this.state.projectEdit,
                        projectApply: !this.state.projectApply,
                      });
                    }}
                    title={"Apply"}
                  />
                  <UIButton
                    variant="text"
                    onClick={() => {
                      this.setState({
                        projectNameValid: true,
                        projectEdit: !this.state.projectEdit,
                        projectName: this.state.projData.ProjectName,
                        projectStatus: this.state.projData.active,
                      });
                    }}
                    title={"Cancel"}
                  />
                </div>
              ) : (
                <UIButton
                  variant="text"
                  onClick={() => {
                    this.setState({
                      projectEdit: !this.state.projectEdit,
                      projectApply: false,
                    });
                  }}
                  title={"Edit"}
                />
              )}
            </div>
          </div>
          {/* Project Status Start */}
          <div className="row mt-3">
            <div className="col text-left mt-2">
              <Typography style={{ margin: "5px", color: "gray" }}>
                Project Status
              </Typography>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col text-left ml-2 ">
              <FormControlLabel
                sx={{
                  "& .MuiFormControlLabel-label.Mui-disabled": {
                    WebkitTextFillColor: "#000000",
                  },
                }}
                control={
                  <Checkbox
                    color={this.state.projectEdit ? "primary" : "default"}
                    checked={this.state.projectStatus}
                    onChange={(e) => {
                      this.setState({
                        projectApply: true,
                        projectStatus: e.target.checked,
                        basicProjectData: {
                          ...this.state.basicProjectData,
                          active: e.target.checked,
                        },
                      });
                    }}
                    disabled={this.state.projectEdit ? false : true}
                  />
                }
                label={"Active"}
                disabled={this.state.projectEdit ? false : true}
              />
            </div>
          </div>
          {/* Project Status End */}
        </UIAccordionDetails>
      </UIAccordion>
    );
  };

  backComponent = (key) => {
    if (key === "edit") {
      return (
        <UIButton
          variant="text"
          title={"< Back"}
          disabled={false}
          onClick={() => {
            this.setState({
              sEditBtnClick: false,
              siteTxt: "",
              errMsg: "",

              projectEdit: false,
              projectCancel: false,
              projectApply: false,
            });
          }}
        />
      );
    } else if (key === "add") {
      return (
        <UIButton
          variant="text"
          title={"< Back"}
          disabled={false}
          onClick={() => {
            this.getProjectData();
            this.setState({
              sEditBtnClick: false,
              sAddBtnClick: false,
              siteTxt: "",
              errMsg: "",

              projectEdit: false,
              projectCancel: false,
              projectApply: false,
            });
          }}
        />
      );
    } else if (key === "edit-project") {
      return (
        <UIButton
          variant="text"
          title={"< Back"}
          disabled={false}
          onClick={() => {
            this.setState({
              pBtnClick: false,
              searchTxt: "",
              errMsg: "",
              projData: [],
              projectName: "",
              projectEdit: false,
              projectCancel: false,
              projectApply: false,
              siteIdOptions: [],
            });
          }}
        />
      );
    }
  };

  checkEditAddSiteComponent = (edit, add) => {
    if (edit && !add) {
      return (
        <div className="m-3 p-1">
          <div className="row">
            <div className="col text-right mr-3">
              {this.backComponent("edit")}
            </div>
          </div>
          <EditSite
            siteId={this.state.siteId}
            siteData={this.state.siteData}
            projId={this.state.projData.projectId}
          />
        </div>
      );
    } else if (!edit && add) {
      return (
        <div className="m-3 p-1">
          <div className="row">
            <div className="col text-right mr-3">
              {this.backComponent("add")}
            </div>
          </div>
          <AddSite projId={this.state.projData.projectId} />
        </div>
      );
    } else {
      return null;
    }
  };

  render() {
    let adminRights = sessionStorage.getItem("UserDetails") && "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role === "admin" ? true : false;
    return (
      <React.Fragment>
        {/* Heading */}
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg">{adminRights ? "Project Edit Page" : "Your access is denied"}</label>
            </div>
          </div>
        </div>

        {/* Page */}

        {
          adminRights
          ?
          <div className="card  custcard">
          {!this.state.pBtnClick ? (
            <div className="cord-body m-5">
              <div className="d-flex align-items-center justify-content-center ml-5">
                <div className="ml-5">
                  <UIDropDown
                    value={this.state.searchTxt}
                    onChange={(e) => {
                      this.setState({
                        searchTxt: e.target.value,
                        errMsg: "",
                      });
                    }}
                    placeholder="Select Project"
                    minWidth={200}
                    textAlign={"left"}
                    maxHeight={350}
                    list={this.state.projectIdList}
                  />
                </div>
                <div className="col-sm-8 btn-group cp-btn-group">
                  <div className="btn-wrapper d-flex">
                    <div className="ml-2">
                      <UIButton
                        variant="contained"
                        onClick={() => {
                          this.getProjectData();
                        }}
                        disabled={
                          checkNonEmptyValues([this.state.searchTxt])
                            ? checkEmptyValues([this.state.errMsg])
                              ? false
                              : true
                            : true
                        }
                        title={"Edit Project"}
                      />
                    </div>
                    <div className="m-2">
                      <span style={{ color: "blue" }}>{this.state.errMsg}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : !this.state.sEditBtnClick && !this.state.sAddBtnClick ? (
            <div className="m-3 p-1">
              <div className="row">
                <div className="col text-right mr-3">
                  {this.backComponent("edit-project")}
                </div>
              </div>
              {this.accordianComponent()}

              {this.state.projData.active ? (
                <div className="row mt-3">
                  <div className="col text-left ml-3 mt-3">
                    <div className="d-flex mr-5">
                      <div>
                        <UIDropDown
                          value={this.state.siteTxt}
                          onChange={(e) => {
                            this.setState({
                              siteTxt: e.target.value,
                              errMsg: "",
                            });
                          }}
                          placeholder="Select Site to Edit"
                          minWidth={200}
                          textAlign={"left"}
                          maxHeight={250}
                          list={this.state.siteIdOptions}
                        />
                      </div>
                      <div className="col-sm-8 btn-group cp-btn-group mt-2">
                        <div className="ml-3">
                          <UIButton
                            variant="contained"
                            onClick={() => {
                              this.getSiteDetails();
                            }}
                            disabled={this.state.siteTxt === "" ? true : false}
                            title={"Edit Site"}
                          />
                        </div>
                        <div className="ml-3">
                          <UIButton
                            variant="contained"
                            onClick={() => {
                              this.setState({ sAddBtnClick: true });
                              return (
                                <div className="m-3 p-1">
                                  <AddSite
                                    projId={this.state.projData.projectId}
                                  />
                                </div>
                              );
                            }}
                            title={"Add Site"}
                          />
                        </div>
                        <div className="ml-2 mt-2">
                          <span style={{ color: "blue" }}>
                            {this.state.errMsg}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            this.checkEditAddSiteComponent(
              this.state.sEditBtnClick,
              this.state.sAddBtnClick
            )
          )}
          </div>
          :
          null
        }
      </React.Fragment>
    );
  }
}

export default AdminEditProject;
