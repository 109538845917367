import React, { useEffect, useState } from "react";
import "./EditProject.css";

import axios from "axios";
import captions from "../../config/captions.json";
import { UIButton, UITextField } from "../UIComponents";

import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

function EditSiteTestSuiteModEnv(props) {
  const [edit, setEdit] = useState(false);
  const [, setCancel] = useState(false);
  const [apply, setApply] = useState(false);
  const [add, setAdd] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const [name, setName] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [itemList, setItemList] = useState(props.listItems);
  const [itemsChecked, setItemsChecked] = useState(props.checkedItems);
  const [errMsg, setErrMsg] = useState("");
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })

  function resetSuiteModEnv(key, originalItems) {
    setErrMsg("");
    originalItems.map((i) => {
      return setItemList([...[], i]);
    });
    originalItems.map((x) => {
      return setItemsChecked([...[], { item: x, check: true }]);
    });
    var newData = {};
    newData[key] = originalItems;
    setSuiteModEnvData(key, newData);
    props.setSuiteModEnvData(key, newData);
  }

  function editSuiteModEnv(key, testType, checkedItems, originalItems) {
    var items = [];
    for (var i in checkedItems) {
      if (checkedItems[i].check === true) {
        items.push(checkedItems[i].item);
      }
    }
    var data = {};
    data = {
      type: testType.trim(),
    };
    data[props.category] = items;

    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;

    axios
      .patch(
        url + "/" + props.projId + "/" + props.siteId + "/testingType",
        data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          responseData.sites.map((item) => {
            if (item.siteid === props.siteId) {
              props.setPropsTestingTypeData(item.testingtype);

              item.testingtype.map((tItem) => {
                props.setTestTypeData(tItem);
                if (tItem.type === testType) {
                  //props.setTestTypeData(tItem);
                  setSuiteModEnvData(props.category, tItem);
                  props.setSuiteModEnvData(props.category, tItem);
                }
              });
            }
          });
        } else {
          setErrMsg(captions.error.SITE_EDIT_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 401) {
            setErrMsg(captions.error.SITE_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.SITE_EDIT_INTERNAL_ERROR);
          } else {
            resetSuiteModEnv(props.category, originalItems);
            setErrMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          resetSuiteModEnv(props.category, originalItems);
          setErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function setSuiteModEnvData(key, data) {
    if (key in data) {
      setItemList([]);
      setErrMsg("");
      setItemsChecked([]);
      setItemList(data[key]);
      setItemsChecked(
        data[key].map((x) => {
          return { item: x, check: true };
        })
      );
    }
  }
  useEffect(() => {
    setEdit(false);
    setCancel(false);
    setApply(false);
    setAddMore(false);
    setName("");
    setNameValid(true);
    setAdd(false);

    if (props.category === "FullSuite") {
      setItemList(props.listItems);
      setItemsChecked(props.checkedItems);
    }
    if (props.category === "Module") {
      setItemList(props.listItems);
      setItemsChecked(props.checkedItems);
    }
    if (props.category === "Environment") {
      setItemList(props.listItems);
      setItemsChecked(props.checkedItems);
    }
  }, [props]);

  return (
    <div
      style={{
        border: "1px solid #aaa",
        marginTop: "5px",
      }}
    >
      <div className="row">
        <div
          className="col d-flex flex-row"
          style={{
            //backgroundColor: "rgba(0, 0, 0, .5)",
            backgroundColor: "rgba(0, 0, 0, .09)",
            margin: "0px 15px 0px 15px",
          }}
        >
          <div className="col text-left">
            <Typography
              style={{
                //color: "darkblue",
                marginTop: "8px",
              }}
            >
              {props.category}
            </Typography>
          </div>

          <div className="col text-right">
            {edit ? (
              <div>
                <UIButton
                  variant="text"
                  title={"Apply"}
                  disabled={
                    apply && itemsChecked.map((i) => i.check).includes(true)
                      ? false
                      : true
                  }
                  onClick={() => {
                    editSuiteModEnv(
                      props.category,
                      props.itemData.type,
                      itemsChecked,
                      itemList
                    );
                    setEdit(!edit);
                    setApply(!apply);
                  }}
                />

                <UIButton
                  variant="text"
                  title={"Cancel"}
                  onClick={() => {
                    setEdit(!edit);
                    setAddMore(false);
                    setName("");
                    resetSuiteModEnv(props.category, itemList);
                  }}
                />
              </div>
            ) : (
              <div>
                <span className="mr-5">{errMsg}</span>
                <UIButton
                  variant="text"
                  title={"Edit"}
                  onClick={() => {
                    setEdit(!edit);
                    setApply(false);
                    setErrMsg("");
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row m-1">
        <div className="col text-left m-2">
          <FormGroup style={addMore ? { pointerEvents: "none" } : {}}>
            {itemsChecked.length !== 0
              ? itemsChecked.map((fItem, fIndex) => {
                  return (
                    <FormControlLabel
                      key={fIndex}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          wordBreak: "break-word",
                        },
                      }}
                      control={
                        <Checkbox
                          color={edit && !addMore ? "primary" : "default"}
                          checked={itemsChecked[fIndex].check}
                          onChange={(e) => {
                            if (e.target.checked) {
                              var c = itemsChecked.map((i, q) => {
                                if (q === fIndex) {
                                  i.check = true;
                                }
                                return i;
                              });

                              setItemsChecked(c);
                            } else {
                              var d = itemsChecked.map((i, q) => {
                                if (q === fIndex) {
                                  i.check = false;
                                }
                                return i;
                              });

                              setItemsChecked(d);
                            }

                            setApply(true);
                          }}
                        />
                      }
                      label={fItem.item}
                      style={edit ? {} : { pointerEvents: "none" }}
                    />
                  );
                })
              : null}
          </FormGroup>
          <div className="row">
            {addMore ? (
              <div className="col text-left">
                <UITextField
                  //disabled={siteEdit ? false : true}
                  value={name}
                  placeholder="Please enter values"
                  onChange={(e) => {
                    setErrMsg("");
                    /* checkNameField(e.target.value)
                                ? setFullSuiteNameValid(true)
                                : setFullSuiteNameValid(false); */
                    setName(e.target.value);
                    /* setBasicSiteData({
                                ...basicSiteData,
                                displayName: e.target.value,
                              }); */
                    setApply(false);
                    setAdd(true);
                  }}
                  helperText={
                    !nameValid && name !== ""
                      ? captions.validation.SITE_DNAME
                      : ""
                  }
                  error={!nameValid && name !== ""}
                  width={290}
                />
              </div>
            ) : null}
            <div className="col text-right">
              {addMore ? (
                <div>
                  <UIButton
                    variant="text"
                    title={"Add"}
                    /* disabled={
                              checkValidNonEmptyValues(
                                [
                                  dNameValid,
                                  uKeyValid,
                                  passKeyValid,
                                  sVerValid,
                                  parthrAllValid,
                                  threadCntValid,
                                  siteApply,
                                ],
                                [sSelVer, sDName, sTimezone, sTimeShort]
                              )
                                ? false
                                : true
                            } */
                    disabled={add ? false : true}
                    onClick={() => {
                      //setApply(!apply ? !apply : apply);
                      if (name.trim() !== "") {
                        var newItem = itemsChecked.map((x) => x);
                        newItem.push({
                          item: name,
                          check: true,
                        });

                        setItemsChecked(newItem);
                        setApply(true);
                      } else {
                        setApply(false);
                      }

                      setAdd(!add);
                      setAddMore(!addMore);
                      setCancel(true);
                      //setApply(!apply ? !apply : apply);
                      setName("");
                    }}
                  />

                  <UIButton
                    variant="text"
                    title={"Cancel"}
                    onClick={() => {
                      setAddMore(!addMore);
                      setCancel(true);
                      setName("");
                      setApply(true);
                      //resetFullSuite();
                    }}
                  />
                </div>
              ) : edit ? (
                <UIButton
                  variant="text"
                  title={"Add More"}
                  onClick={() => {
                    setAdd(false);
                    setAddMore(!addMore);
                    setCancel(false);
                    setApply(false);
                    //resetFullSuite();
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSiteTestSuiteModEnv;
