import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import WavesIcon from '@material-ui/icons/Waves';
import SpeedIcon from '@material-ui/icons/Speed';
import BugReportIcon from '@material-ui/icons/BugReport';
import AssessmentIcon from '@material-ui/icons/Assessment';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Devices from '@material-ui/icons/Devices';

const pageAccessObj = [
    {
      pageName: 'Web Test Execution',
      path: '/',
      isAllowed: false,
      key: 'test_execution',
      icon: [<SpeedIcon></SpeedIcon>],
    },
    {
      pageName: 'APP Test Execution',
      path: '/AppExecution',
      isAllowed: false,
      key: 'app_execution',
      icon: [<Devices></Devices>],
    },
    {
      pageName: 'API Test Execution',
      path: '/ApiExecution',
      isAllowed: false,
      key: 'api_execution',
      icon: [<ListAltIcon></ListAltIcon>],
    },
    {
      pageName: 'Currently Executing',
      path: '/CurrentExecution',
      isAllowed: false,
      key: 'currently_executing',
      icon: [<WavesIcon />],
    },
    {
      pageName: 'Queue',
      path: '/Queue',
      isAllowed: false,
      key: 'queue',
      icon: [<FormatListNumberedIcon></FormatListNumberedIcon>],
    },
    {
      pageName: 'Results',
      path: '/Results',
      isAllowed: false,
      key: 'results',
      icon: [<AssessmentIcon></AssessmentIcon>],
    },
    {
      pageName: 'Automation Analytics',
      path: '/Trends',
      isAllowed: false,
      key: 'trends',
      icon: [<TrendingUpRoundedIcon></TrendingUpRoundedIcon>],
    },
    {
      pageName: 'Defect Analytics',
      path: '/defecttrends',
      isAllowed: false,
      key: 'defecttrends',
      icon: [<BugReportIcon></BugReportIcon>]
    }
  ];
export default pageAccessObj