import React, { Component } from 'react';
import Box from '@material-ui/core/Box';
import './SiteSelection.css';
import { withRouter } from 'react-router-dom';
import TestExecution from './TestExecution';
import Sidebar from './Sidebar';
import SiteLogo from './SiteLogo';
import { Redirect } from 'react-router-dom';
import myInitObject from './MyInitiObject'
// import CurrentExecution from './CurrentExecution';
// import Results from './Results';
class SiteSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectdata: {},
      wrap_class: '',
      siteSelected: false,
      hideSideBar: false
    };
    window.SiteSelectionComponent = this;
  }

  projectSelection = (Index, cb) => {
    let json = this.state.projectdata;
    let static_j = JSON.stringify(this.state.projectdata);
    if (typeof json[0] !== "undefined" && json[Index].Page_access) {
      json.forEach((element) => {
        element.isChecked = false;
      });
      json[Index].isChecked = true;
      const array = [...json];
      myInitObject["selectedProject"] = array[Index].ProjectName;
      myInitObject["selectedProjectIndex"] = Index;
      // console.log("myInitObject-->", myInitObject);
      // sessionStorage.setItem('selectedProject', array[Index].ProjectName);
      // sessionStorage.setItem('selectedProjectIndex', Index);
      // this.props.updateProjectSelection(array[Index].ProjectName);
      array[Index].sites.forEach((element) => {
        element.isChecked = false;
      });
      this.setState(
        {
          json: JSON.parse(static_j),
          projectdata: array,
          siteData: array[Index].sites,
          allChecked: false,
        },
        () => {
          if (cb) {
            cb();
          }
        }
      );
    }
  };
  resetSelection() {
    let selectedProjectIndex = sessionStorage.getItem('selectedProjectIndex');
    let selectedSiteIndex = sessionStorage.getItem('selectedSiteIndex');
    this.projectSelection(selectedProjectIndex, () => {
      this.siteSelection(selectedSiteIndex)
    });
  }
  defaultSelection() {
    let selectedProjectIndex = sessionStorage.getItem('selectedProjectIndex');
    let selectedSiteIndex = sessionStorage.getItem('selectedSiteIndex');
    this.projectSelection(selectedProjectIndex, () => {
      this.siteSelection(selectedSiteIndex);
      this.setSiteSelection();
    });
  }

  siteSelection = (Index) => {
    let json = this.state.siteData;
    json.forEach((element) => {
      element.isChecked = false;
    });
    json[Index].isChecked = true;
    const array = [...json];
    // sessionStorage.setItem('selectedSite', array[Index].sitename);
    // sessionStorage.setItem('selectedSiteIndex', Index);
    // sessionStorage.setItem('selectedSiteLogo', array[Index].logo);
    // sessionStorage.setItem('selectedSiteDisplayName', array[Index].displayName);
    myInitObject["selectedSite"] = array[Index].sitename;
    myInitObject["selectedSiteIndex"] = Index;
    myInitObject["selectedSiteLogo"] = array[Index].logo;
    myInitObject["selectedSiteDisplayName"] = array[Index].displayName;
    // console.log("myInitObject-->", myInitObject);
    // new SiteLogo().callmountandupdate();
    this.setState({
      siteData: array,
      allChecked: true,
      selectedSite: array[Index],
    });
  };
  setSiteSelection = (e, val) => {
    sessionStorage.setItem(
      'selectedSiteData',
      JSON.stringify(this.state.selectedSite)
    );
    // console.log(myInitObject)
    sessionStorage.setItem('selectedProject', myInitObject.selectedProject);
    sessionStorage.setItem('selectedProjectIndex', myInitObject.selectedProjectIndex);
    sessionStorage.setItem('selectedSite', myInitObject.selectedSite);
    sessionStorage.setItem('selectedSiteIndex', myInitObject.selectedSiteIndex);
    sessionStorage.setItem('selectedSiteLogo', myInitObject.selectedSiteLogo);
    sessionStorage.setItem('selectedSiteDisplayName', myInitObject.selectedSiteDisplayName);
    this.props.updateProjectSelection(myInitObject.selectedProject);
    new SiteLogo().callmountandupdate();
    var current_page = this.props.location.pathname;
    if (current_page !== '/SiteSelection') {
      this.setState({
        wrap_class: 'squize',
      });
      this.props.func(true);
    }

    var selectedPage = JSON.parse(sessionStorage.getItem('selectedPage'));
    var selectedProject = sessionStorage.getItem('selectedProject');
    var selectedSite = sessionStorage.getItem('selectedSite');
    var selectedProjectIndex = sessionStorage.getItem('selectedProjectIndex');
    var selectedSiteIndex = sessionStorage.getItem('selectedSiteIndex');
    var UserDetails = JSON.parse(sessionStorage.getItem('UserDetails'));
    sessionStorage.setItem(
      'selectedSiteData',
      JSON.stringify(
        UserDetails.data.User.rights.Projects[selectedProjectIndex].sites[
        selectedSiteIndex
        ]
      )
    );
    if (
      selectedPage != null &&
      typeof selectedPage[selectedProject] != 'undefined' &&
      typeof selectedPage[selectedProject][selectedSite] != 'undefined'
    ) {
      this.props.history.push("/blank");
      setTimeout(() => {
        this.props.history.push(selectedPage[selectedProject][selectedSite]);
        if (current_page === selectedPage[selectedProject][selectedSite]) {
          if (current_page !== '/CurrentExecution') {
            var refresh = sessionStorage.getItem('pagerefresh');
            if (refresh === null || refresh === "") {
              sessionStorage.setItem('pagerefresh', '');
              // localStorage.setItem('rioSession', JSON.stringify(sessionStorage));
              val && window.TestExecutionComponent.callmountandupdate();
              setTimeout(() => {
                window.AnalyticsComponent.rerenderComponent();
              }, 500)

            } else {
              sessionStorage.removeItem('pagerefresh');
            }
          }
        }
        else {
          window.TestExecutionComponent.callmountandupdate();
          setTimeout(() => {
            window.AnalyticsComponent.rerenderComponent();
          }, 500)
          // window.location.reload();
        }
      }, 500)

    } else {
      this.props.history.push('/');
    }
    window.SidebarComponent.updateSidebar();
    this.props.func(true);

  };
  backDropClick = () => {
    this.props.backdropclick(true);
    this.defaultSelection();

  };
  reset = () => {
    this.setState({
      allChecked: false,
      projectdata: this.state.json,
      siteData: [],
    });
    this.projectSelection(0);
  };
  componentDidMount() {
    let details = sessionStorage.getItem('UserDetails');
    if (details && details !== '') {
            details = JSON.parse(details);
      if (typeof details.data.User.rights.Projects[0] !== "undefined" && details.data.User.rights.Projects[0].Page_access !==null) {
        this.setState(
          {
            projectdata: details.data.User.rights.Projects,
            siteData: {},
            allChecked: false,
          },
          () => {
            this.defaultSelection();
          }
        );
      }
      else {
        this.setState({
          hideSideBar: true
        })
      }
    }
  }
  changewrap = (val) => {
    this.setState({
      wrap_class: val,
    });
    //this.state.wrap_class = val;
  };
  render() {
    var current_page = this.props.location.pathname;

    if (!this.state.projectdata.length) return null;
    if (this.state.projectdata.length > 0) {
      if (
        current_page === '/SiteSelection' &&
        this.state.wrap_class === 'squize'
      ) {
        this.changewrap('');
      } else if (
        current_page !== '/SiteSelection' &&
        this.state.wrap_class === ''
      ) {
        this.changewrap('squize');
      }
    }

    return (
      <div>
        <div className={this.state.wrap_class + ' container'}>
          <div className="row">
            {/* <div className="col-sm-1">&nbsp;</div> */}
            <div className="col-sm-12">
              {this.state.wrap_class === '' ? (
                <div className="card custcard">
                  <div className="cord-body">
                    <div className="testHeading">
                      <label className="headermsg">Site Selection</label>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              <div className="card custcard">
                <div className="card-body mt-3">
                  <div className="row">
                    <span className="col-sm-4 black-text left-align">
                      Account:
                    </span>
                    <div
                      className={
                        this.state.wrap_class === 'squize'
                          ? 'col-sm-12 btn-group cp-btn-group'
                          : 'col-sm-8 btn-group cp-btn-group'
                      }
                    >
                      <div
                        className={
                          this.state.wrap_class === 'squize' ? 'border' : ''
                        }
                      ></div>
                      <div className='project-hold'>
                        <React.Fragment>
                          {this.state.projectdata.map((item, i) => (
                            <div key={i} className="btn-wrapper">
                              <input
                                type="radio"
                                className={item.isChecked ? 'active' : 'inactive'}
                                name={item.ProjectName}
                                value={item.ProjectName}
                                onClick={() => {
                                  this.projectSelection(i);
                                }}
                              />
                              <button type="button" className="btn btn-secondary">
                                {item.ProjectName}
                              </button>
                            </div>
                          ))}
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                  {this.state.wrap_class === 'squize' ? '' : <br></br>}

                  {this.state.siteData.length > 0 && (
                    <div>
                      <div className="row">
                        <span className="col-sm-4 black-text left-align">
                          Application:
                        </span>
                        <div
                          className={
                            this.state.wrap_class === 'squize'
                              ? 'col-sm-12 btn-group cp-btn-group'
                              : 'col-sm-8 btn-group cp-btn-group'
                          }
                        >
                          <div
                            className={
                              this.state.wrap_class === 'squize' ? 'border' : ''
                            }
                          ></div>
                          <React.Fragment>
                            {this.state.siteData.map((item, i) => (
                              <div key={i} className="btn-wrapper">
                                <input
                                  type="radio"
                                  className={
                                    item.isChecked ? 'active' : 'inactive'
                                  }
                                  name={item.sitename}
                                  value={item.sitename}
                                  onClick={() => {
                                    this.siteSelection(i);
                                  }}
                                />
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                >
                                  {item.displayName}
                                </button>
                              </div>
                            ))}
                          </React.Fragment>
                          <br></br>
                          <br></br>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="FooterBAck d-flex flex-column justify-content-start">
                    <div className="card">
                      <div className="card-body">
                        <div className="btn-group cp-btn-group1">
                          <div
                            className="btn-wrapper test"
                            onClick={(e) => this.setSiteSelection(e, true)}
                          >
                            <input
                              type="radio"
                              value="select"
                              id="select"
                              name="select"
                            />
                            <button

                              disabled={!this.state.allChecked}
                              className={`btn btn-info btn-allow ${this.state.isStart ? 'active1' : 'inactive'
                                }`}
                            >
                              <span className="cancelstartmark" disabled>
                                &#10003;
                              </span>
                              Select
                            </button>
                          </div>
                          <div className="btn-wrapper" onClick={this.reset}>
                            <Box
                              borderColor="error.main"
                              border={0}
                              borderRadius="50%"
                            >
                              <input
                                type="radio"
                                id="reset"
                                value="reset"
                                name="reset"
                              />
                              <button
                                type="button"
                                id="cancel"
                                className={`btn btn-info  ${this.state.isStop ? 'active1' : 'inactive'
                                  }`}
                              >
                                {' '}
                                <span
                                  aria-hidden="true"
                                  className="cancelstartmark"
                                >
                                  &times;
                                </span>
                                Reset
                              </button>
                            </Box>
                          </div>
                        </div>
                        <br />
                        <br />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="backdrop-site-selection"
          onClick={this.backDropClick}
        ></div>
      </div>
    );
  }
}

export default withRouter(SiteSelection);
