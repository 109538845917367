import React, { useEffect, useState } from "react";
import "./EditProject.css";

import axios from "axios";
import captions from "../../config/captions.json";
import {
  UIDropDown,
  UIButton,
  UITextField,
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
  UIModal,
} from "../UIComponents";
import { checkEmptyValues } from "../checkFields";
import {
  desktopOSList,
  mobTabOSList,
  windowsBrowserList,
  macBrowserList,
  linBrowserList
} from "../../config/siteItemList";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import EditSiteOSDevice from "./EditSiteOSDevice";

function EditSiteFormFactor(props) {
  const [formFactorData, setFormFactorData] = useState(props.formFactor);
  const [formFactorOS, setFormFactorOS] = useState(
    Object.keys(props.formFactor.OSAccess)
  );
  const [formFactorOSChecklist, setFormFactorOSChecklist] = useState(
    Object.keys(props.formFactor.OSAccess).map((x) => {
      return {
        item: x,
        check: true,
        devices: props.formFactor.OSAccess[x]
          .map((i) => i)
          .map((i) => {
            return { deviceItem: i, check: true };
          }),
      };
    })
  );
  const [formFactorEdit, setFormFactorEdit] = useState(false);
  const [formFactorApply, setFormFactorApply] = useState(false);
  const [fName] = useState(props.formFactorName);
  const [fStatus, setFStatus] = useState(
    "active" in props.formFactor ? props.formFactor.active : true
  );
  const [fOS, setFOS] = useState("");
  const [addOSMore, setAddOSMore] = useState(false);
  const [, setAddDeviceMore] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [oSAddModalOpen, setOSAddModalOpen] = useState(false);
  const [tDevice, setTDevice] = useState("");
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })

  useEffect(() => {}, [props]);

  function checkBrowser(osName){
    if(osName === "Mac"){
      return macBrowserList
    }else if(osName === "Windows"){
      return windowsBrowserList
    }
    else if(osName === "Linux"){
      return linBrowserList
    }
}

function checkNonEmptyOS(osList){
  var checkOSKeys = osList.map((item)=> item.check )
     if (checkOSKeys.includes(true)) {
      return true;
    } else {
      return false;
    } 
}

  function populateData(fData) {
    setFormFactorData(fData);
    setFormFactorOS(Object.keys(fData.OSAccess));
    setFormFactorOSChecklist([]);
    setFormFactorOSChecklist(
      Object.keys(fData.OSAccess).map((x) => {
        return {
          item: x,
          check: true,
          devices: fData.OSAccess[x]
            .map((i) => i)
            .map((i) => {
              return { deviceItem: i, check: true };
            }),
        };
      })
    );

    setFStatus("active" in fData ? fData.active : true);
    setFOS("");
    setErrMsg("");
  }

  function editFormFactor(osCheck, oldOS, deviceCheck, oldDevice) {
    var data = {};

    var osData = {};
    osCheck.map((i) => {
      if (i.check === true) {
        var deviceData = [];
        i.devices.map((d) => {
          if (d.check === true) {
            deviceData.push(d.deviceItem);
          }
        });
        osData[i.item] = deviceData;
      }
    });

    var factors = {
      name: props.formFactorName,
      active: fStatus,
      OSAccess: osData,
    };

    data = {
      type: props.testType,
      integrations: [{ name: props.integrationType, formfactors: [factors] }],
    };

    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;

    axios
      .patch(
        url + "/" + props.projId + "/" + props.siteId + "/testingType",
        data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          setErrMsg("");
          responseData.sites.map((item) => {
            if (item.siteid === props.siteId) {
              props.setPropsTestingTypeData(item.testingtype);

              item.testingtype.map((testItem) => {
                if (testItem.type === props.testType) {
                  testItem.integrations.map((i) => {
                    //props.setIntgData(i);
                    if (i.name === props.integrationType) {
                      props.setIntgData(i);
                      i.formfactors.map((f) => {
                        if (f.name === props.formFactorName) {
                          populateData(f);
                        }
                      });
                    }
                  });
                }
              });
            }
          });
        } else {
          setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 401) {
            setErrMsg(captions.error.SITE_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
          } else {
            resetFormFactorData();
            setErrMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          resetFormFactorData();
          setErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function setDChecklist(os, newItem) {
    var newList = formFactorOSChecklist.map((x) => {
      if (x.item === os) {
        if(checkNonEmptyOS(newItem)){
          return {
            item: x.item,
            check: true,
            devices: newItem,
          };
        }else{
          return {
            item: x.item,
            check: false,
            devices: newItem,
          };
        }
        
      } else {
        return {
          item: x.item,
          check: x.check,
          devices: x.devices,
        };
      }
    });
    setFormFactorOSChecklist(newList);
  }

  function resetFormFactorData() {
    setFormFactorData(props.formFactor);
    setFormFactorOS(Object.keys(formFactorData.OSAccess));
    setFormFactorOSChecklist([]);
    setFormFactorOSChecklist(
      formFactorOS.map((x) => {
        return {
          item: x,
          check: true,
          devices: formFactorData.OSAccess[x]
            .map((i) => i)
            .map((i) => {
              return { deviceItem: i, check: true };
            }),
        };
      })
    );

    setFStatus("active" in formFactorData ? formFactorData.active : true);
    setFOS("");
    setErrMsg("");
  }

  function getOSDeviceData(osItem) {
    return (
      osItem.devices.length === 0 ? null :
      <div className="col ml-5">
        <EditSiteOSDevice
          formFactorName={props.formFactorName}
          osDeviceData={props.formFactor.OSAccess[osItem.item]}
          osDeviceChecklist={osItem.devices}
          osName={osItem.item}
          editStatus={formFactorEdit}
          setFormFactorApply={setFormFactorApply}
          setFormFactorDeviceChecklist={setDChecklist}
        />
        <div className="col ml-4 mb-3">
          {/* //make here the add device modal button */}
        </div>
      </div>
    );
  }

  function getOSAccessCheckBoxes() {
    return formFactorOSChecklist.map((osItem, osIndex) => (
      <div key={osIndex}>
        <div className="col ml-4">
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
              marginLeft: "10px",
            }}
            control={
              <Checkbox
                color={formFactorEdit && !addOSMore ? "primary" : "default"}
                checked={formFactorOSChecklist[osIndex].check}
                onChange={(e) => {
                  if (e.target.checked) {
                    var c = formFactorOSChecklist.map((i, q) => {
                      if (q === osIndex) {
                        i.check = true;
                        i.devices.map((d) => (d.check = true));
                      }
                      return i;
                    });

                    setFormFactorOSChecklist(c);
                  } else {
                    var d = formFactorOSChecklist.map((i, q) => {
                      if (q === osIndex) {
                        i.check = false;
                        i.devices.map((d) => (d.check = false));
                      }
                      return i;
                    });

                    setFormFactorOSChecklist(d);
                  }
                  setFormFactorApply(true);
                }}
              />
            }
            label={osItem.item}
            style={formFactorEdit ? {} : { pointerEvents: "none" }}
          />
        </div>
        {getOSDeviceData(osItem)}
      </div>
    ));
  }
  function checkEmptyValBasedOnTestType(testType) {
    const valuesToCheck = (testType === "UI" || (testType === "APP" && props.formFactorName !== "Desktop")) 
        ? [fOS, tDevice] 
        : [fOS];
    return checkEmptyValues(valuesToCheck);
}


  function goToOSAddModal(osData) {
    return (
      <UIModal
        open={oSAddModalOpen}
        handleClose={oSAddModalOpen}
        title={"Add OS Access"}
        fields={
          <div className="col">
            <div>
              <div className="d-flex mt-4">
                <span className="title-prompts mt-3">Operating System</span>
                <UIDropDown
                  value={fOS}
                  onChange={(e) => {
                    setTDevice("");
                    setFOS(e.target.value);
                    setErrMsg("");
                  }}
                  placeholder="Select os"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={
                    props.formFactorName === "Desktop"
                      ? desktopOSList.filter((x) => {
                          var fList = formFactorOSChecklist.map((i) => i.item);
                          return !fList.includes(x);
                        })
                      : mobTabOSList.filter((x) => {
                          var fList = formFactorOSChecklist.map((i) => i.item);
                          return !fList.includes(x);
                        })
                  }
                />
              </div>
              {fOS !== "" ? (
                props.formFactorName === "Desktop" ? (
                  props.testType === "UI"?
                  <div className="d-flex mt-4">
                    <span className="title-prompts mt-3">Browser</span>
                    <UIDropDown
                      value={tDevice}
                      onChange={(e) => {
                        setTDevice(e.target.value);
                        setErrMsg("");
                      }}
                      placeholder="Select browser"
                      minWidth={280}
                      textAlign={"left"}
                      maxHeight={350}
                      list={
                        //fOS === "Mac" ? macBrowserList : windowsBrowserList
                        checkBrowser(fOS)
                      }
                    />
                  </div>:null
                ) : (
                  <div className="d-flex mt-4">
                    <span className="title-prompts mt-1">Device</span>
                    <UITextField
                      value={tDevice}
                      placeholder="Please enter device name"
                      onChange={(e) => {
                        setTDevice(e.target.value);
                        setErrMsg("");
                      }}
                      width={310}
                    />
                  </div>
                )
              ) : null}
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  if (fOS.trim() !== "") {
                    let newItem = formFactorOSChecklist.map((x) => x);
                    let newDevice = [];
                
                    if (props.testType === "UI" && tDevice.trim() !== "") {
                      newDevice = [{ deviceItem: tDevice, check: true }];
                    } else if (props.testType === "APP") {
                      if (props.formFactorName !== "Desktop" && tDevice.trim() !== "") {
                        newDevice = [{ deviceItem: tDevice, check: true }];
                      }
                    }
                
                    newItem.push({
                      item: fOS,
                      check: true,
                      devices: newDevice,
                    });
                
                    setFormFactorOSChecklist(newItem);
                    setFormFactorApply(true);
                    setFOS("");
                    setTDevice("");
                    setErrMsg("");
                    setOSAddModalOpen(false);
                  }
                }}
                disabled={checkEmptyValBasedOnTestType(props.testType)}
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  setFOS("");
                  setTDevice("");
                  setErrMsg("");
                  setOSAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }

  return (
    <UIAccordion>
      <UIAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem", color: "#000" }}
          />
        }
        style={{
          backgroundColor: "#fff",
          color: "#000",
        }}
      >
        <Typography>{fName}</Typography>
      </UIAccordionSummary>
      <UIAccordionDetails>
        <div className="row ml-1">
          <div className="col text-left ml-2">
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              control={
                <Checkbox
                  color={formFactorEdit && !addOSMore ? "primary" : "default"}
                  checked={fStatus}
                  onChange={(e) => {
                    setFormFactorApply(true);
                    setFStatus(e.target.checked);
                  }}
                  disabled={formFactorEdit ? false : true}
                />
              }
              label={"Active"}
              disabled={formFactorEdit && !addOSMore ? false : true}
            />
          </div>
          <div className="col text-right ">
            {formFactorEdit ? (
              <div className="col text-right">
                <span className="mr-5">{errMsg}</span>
                <UIButton
                  variant="text"
                  title={"Apply"}
                  disabled={formFactorApply  && checkNonEmptyOS(formFactorOSChecklist) ? false : true}
                  onClick={() => {
                    editFormFactor(formFactorOSChecklist, formFactorOS);
                    setFormFactorEdit(!formFactorEdit);
                    setFormFactorApply(!formFactorApply);
                  }}
                />

                <UIButton
                  variant="text"
                  title={"Cancel"}
                  onClick={() => {
                    setFormFactorEdit(!formFactorEdit);
                    setAddDeviceMore(false);
                    setAddOSMore(false);

                    resetFormFactorData();
                  }}
                />
              </div>
            ) : (
              <div>
                <span className="mr-5">{errMsg}</span>
                <UIButton
                  variant="text"
                  title={"Edit form factor"}
                  onClick={() => {
                    setFormFactorEdit(!formFactorEdit);
                    setFormFactorApply(false);
                    setErrMsg("");
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {/* should go in separate component */}
        {fStatus && formFactorOSChecklist.length > 0 ? (
          <div className="border-top">
            <FormGroup style={addOSMore ? { pointerEvents: "none" } : {}}>
              {getOSAccessCheckBoxes()}
            </FormGroup>
          </div>
        ) : null}
        <div className="col ml-5 mt-2">
          <div className="col text-left">
            {formFactorEdit && fStatus ? (
              <div>
                <UIButton
                  variant="text"
                  title={"Add OS"}
                  disabled={
                    fName === "Desktop"
                      ? formFactorOSChecklist.length === desktopOSList.length
                        ? true
                        : false
                      : formFactorOSChecklist.length === mobTabOSList.length
                      ? true
                      : false
                  }
                  onClick={() => {
                    setOSAddModalOpen(true);
                  }}
                />
                {goToOSAddModal(formFactorOS)}
              </div>
            ) : null}
          </div>
        </div>

        {/* should go in separate component */}
      </UIAccordionDetails>
    </UIAccordion>
  );
}

export default EditSiteFormFactor;
