import React, { Component } from 'react';
import './CustomeLogin.css';
import GLogin from './GoogleLogin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { setUserSession } from './UserSession';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Redirect } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import PasswordChecklist from "react-password-checklist"
import { isValid } from 'date-fns';
export class CustomeLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userName: '',
      passWord: '',
      cpassWord: '',
      userValidation: '',
      redirect: false,
      forgotpassword: false,
      passcode: localStorage.getItem('Passcode'),
      isValidPasscode: Boolean(localStorage.getItem('isValidPasscode')),
      isPasswordReset: Boolean(localStorage.getItem('isPasswordReset')),
      isLoginProcessing: false,
      isValidEmail: false,
      isValidPassword: false,
      isRecoveryLinkSent: false,
      isPasswordResetSuccess: false,
      auth: false
    };
  }

  handleChangeUsername = (e) => {
    // console.log("email", e.target.value);
    // console.log("email", isEmail(e.target.value));
    if (!isEmail(e.target.value)) {
      this.setState({
        userValidation: "Not valid email",
        isValidEmail: false
      });
    }
    else {
      this.setState({
        userValidation: "",
        isValidEmail: true
      });
    }
    this.setState({ userName: e.target.value });
  };
  handleChangePassword = (e) => {
    this.setState({
      isValidPassword : false
    })
    this.setState({ passWord: e.target.value });
  };
  handleChangeCpassword = (e) => {
    this.setState({
      isValidPassword : false
    })
    this.setState({ cpassWord: e.target.value });
  };
  handleValidPassword = (val) => {
    // console.log("validpassword", val)
    if(val){
      this.setState({
        isValidPassword : true
      })
    }
  }
  login = (e) => {
    const { userName, passWord, passcode, isValidEmail } = this.state;
    this.setState({
      userValidation: '',
      isLoginProcessing: true,
    });
    e.preventDefault();
    var data = {
      Username: userName,
      Password: passWord,
    };
    let is_forgot_password = this.state.forgotpassword;
    let is_reset_password = this.state.isPasswordReset;
    if (!isValidEmail && !is_reset_password) {
      this.setState({
        userValidation: 'Not valid email',
        isLoginProcessing: false,
      });
      return false;
    }
    if (!is_forgot_password && !is_reset_password) {
      if (userName === '' || passWord === '') {
        this.setState({
          userValidation: 'All fields required',
          isLoginProcessing: false,
        });
        return false;
      }
      axios
        .post(window.configs.LOGIN_SERVICE_URL, data)
        .then((result) => {
          if (result.data.status === 'success') {
            var tokenData = result.data.authToken;
            setUserSession.setItem('email', userName);
            // localStorage.setItem('rioLogginStatus', 'Y');
            setUserSession.setItem('imageUrl', `../images/user.svg`);
            setUserSession.setItem('givenName', '');
            sessionStorage.setItem('selectedProjectIndex', 0);
            sessionStorage.setItem('selectedSiteIndex', 0);
            axios
              .get(
                window.configs.USER_SERVICE_URL +
                'user-details/' +
                userName
              )
              .then((result) => {
                sessionStorage.setItem('UserDetails', JSON.stringify(result));
                sessionStorage.setItem('givenName', result.data.User.FirstName);
                setUserSession.setItem('authToken', tokenData);
                this.setState({ auth: true });
              })
              .catch((error) => {
                  this.setState({
                    userValidation:"Your access is denied",
                    isLoginProcessing: false,
                  })
              });
          } else {
            this.setState({
              userValidation: 'Invalid credentials',
              isLoginProcessing: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            userValidation: 'Invalid credentials',
            isLoginProcessing: false,
          });
        });
    }
    else if (is_reset_password) {
      data = {
        "passCode": passcode,
        "passsword": passWord
      }
      axios
        .post(window.configs.CREATE_PASSWORD_URL, data)
        .then((result) => {
          if (result.status == 204) {
            this.setState({
              userValidation: 'Password successfuly updated',
              isLoginProcessing: false,
              isPasswordReset: false,
              userName:"",
              passWord:""
            });
            localStorage.removeItem('Passcode');
            localStorage.removeItem('isValidPasscode');
            localStorage.removeItem('isPasswordReset');
          }
        })
    }
    else {
      data = { email: userName };
      if (userName === '') {
        this.setState({
          userValidation: 'Please enter your email',
          isLoginProcessing: false,
        });
        return false;
      }
      axios
        .post(window.configs.GENERATE_PASSCODE, data)
        .then((result) => {
          if (result.status === 202 || result.status === 204) {
            this.setState({
              userValidation: 'We sent a recovery link to you at ' + userName,
              isLoginProcessing: false,
              isRecoveryLinkSent: true
            });
          } else {
            this.setState({
              userValidation: 'User does not Exist',
              isLoginProcessing: false,
            });
          }
        })
        .catch((err) => {
          this.setState({
            userValidation: 'Reset Error',
          });
        });
    }
  };
  forgotpassword() {
    if (this.state.isPasswordReset) {
      this.setState({ forgotpassword: true, isPasswordReset: false }, () => {
        localStorage.removeItem('Passcode');
        localStorage.removeItem('isValidPasscode');
        localStorage.removeItem('isPasswordReset');
        this.setState({
          forgotpassword: !this.state.forgotpassword,
          userValidation: ""
        })
      });
    }
    else {
      this.setState({
        forgotpassword: !this.state.forgotpassword,
        isRecoveryLinkSent: false,
        userValidation: "",
        userName: "",
        passWord:"",
        cpassWord:""
      })
    }

  }
  resendrecovery(){
    this.setState({
      isRecoveryLinkSent: false,
      userValidation: "",
      userName: "",
      passWord:"",
      cpassWord:""
    })
  }
  handleGoogleUserValidation = (childData)=>{
    this.setState({
      userValidation: childData,
      isLoginProcessing: false
    })
  }
  render() {
    if (this.state.redirect) return <Redirect to="/" />;
    let buttonstatus = true;
    let auth = this.state.auth
    if(this.state.isPasswordReset && this.state.isValidPassword){
      buttonstatus = false;
    }
    else if(this.state.isValidEmail){
      buttonstatus = false;
    }
    if (!auth) {
      return (
        <React.Fragment>
          <div className="login-overlay"></div>
            <div className="col l6 xl6 s12 offset-l3 offset-xl3">
              <div style={{ height: '100px' }}>&nbsp;</div>
              <div className="login-table">
                <table className="">
                  <tbody>
                    <tr>
                      <td>
                        <img
                          className="ch-logo_login"
                          src="https://cpimages.blob.core.windows.net/images/charles-hudson-logo.svg"
                          alt="logoch"
                        />
                      </td>
                      <td>
                        <div className="login-holder white-text">
                          <h5
                            style={{
                              textAlign: 'center',
                              color: 'white',
                              margin: '15px',
                              fontSize: '24px',
                            }}
                          >
                            {this.state.isPasswordReset ? "Create Password" : "Log In With"}

                          </h5>
                          <div style={{ width: '100%' }} className="Glogin">
                            {this.state.isPasswordReset ? "" : <GLogin setValidationMsg={this.handleGoogleUserValidation}/>}

                          </div>
                          <p
                            id="google-user-validation"
                            className="yellow-text"
                          ></p>
                          <p style={{ textAlign: 'center', color: 'white' }}>
                            {this.state.isPasswordReset ? "" : "OR"}
                          </p>
                          <div className="card-content">
                            {!this.state.isRecoveryLinkSent &&
                            <div>
                              <TextField
                                id="standard-basic1"
                                label={this.state.forgotpassword ? "We'll send a recovery link to" : "Username*"}
                                value={this.state.userName}
                                onChange={this.handleChangeUsername}
                                autoComplete="off"
                                name="Username"
                                type="email"
                                className={this.state.isPasswordReset ? "hide-input" : ""}
                              />
                              <TextField
                                id="standard-basicgg"
                                label="Password*"
                                value={this.state.passWord}
                                onChange={this.handleChangePassword}
                                type="password"
                                className={this.state.forgotpassword ? "hide-input" : ""}
                                autoComplete="off"
                                name="Password"
                              />
                              <TextField
                                id="standard-basic-2"
                                label="Confirm Password*"
                                value={this.state.cpassWord}
                                onChange={this.handleChangeCpassword}
                                type="password"
                                className={!this.state.isPasswordReset ? "hide-input" : ""}
                                autoComplete="off"
                                name="cPassword"
                              />
                            </div>
                            }
                            {this.state.isPasswordReset &&
                              <PasswordChecklist
                                rules={["minLength", "specialChar", "number", "capital", "lowercase", "match"]}
                                minLength={8}
                                value={this.state.passWord}
                                valueAgain={this.state.cpassWord}
                                onChange={(isValid) => { this.handleValidPassword(isValid)}}
                                className="passwordcheck"
                                messages={{
                                  minLength: "Password should be at least 8 charecters",
                                  specialChar: "Password sould contain minimum 1 special charecter",
                                  number: "Password sould contain minimum 1 number",
                                  capital: "Password sould contain minimum 1 uppercase letter",
                                  lowercase: "Password sould contain minimum 1 lowercase letter"
                                }}
                              />
                            }
                            
                            <span className='forgot-password' onClick={(e) => this.forgotpassword(e)}>{(this.state.forgotpassword || this.state.isPasswordReset) ? "Back to login" : "Forgot Password?"}</span>
                            {this.state.isRecoveryLinkSent &&
                              <span className='forgot-password' onClick={(e) => this.resendrecovery(e)}> | Resend Recovery Link</span>
                            }
                            <br />
                            <br />
                            <p id="user-validation" className="yellow-text">
                              &nbsp;{this.state.userValidation}
                            </p>
                            <div className="form-field">
                              {!this.state.isRecoveryLinkSent &&
                                <Button
                                  variant="contained"
                                  color="primary"
                                  style={{ width: '100%' }}
                                  id="login"
                                  onClick={this.login}
                                  disabled={buttonstatus}
                                >

                                  {this.state.isLoginProcessing ? <CircularProgress size={30} /> : (this.state.forgotpassword ? "Send recovery link" : this.state.isPasswordReset ? "Reset" : "Login")}
                                </Button>
                              }
                            </div>
                            <br />
                            <br />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </React.Fragment>
      );
    }
    else{
      return <Redirect to="/" rerenderComponent={true} />;
    }
  }
}

export default CustomeLogin;
