import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Paper  from '@material-ui/core/Paper';


class TabUIGeneric extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabdata: {},
      tabcount: [],
      tabvalue: 0,
      tabindexvalue: 0
    }
  }
  handleAccordianClick = (event) => {
    event.stopPropagation();
  };
  handleTabChange = (event, newValue) => {
    this.setState({ tabvalue: newValue })
  };
  handleTabData = (data) => {
    this.setState({ tabdata: data })
  };
  setTabCount = (value) => {
    this.setState({ tabcount: value })
  }
  render() {
    function a11yProps(index) {
      return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
      };
    }
    function TabPanel(props) {
      const { children, value, index, ...other } = props;
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          style={{maxHeight : "500px", "overflowY" : "auto"}}
        
        >
          {value === index && (
            <Box p={3}>
              <div style={{"textAlign":"left"}}>{children}</div>
            </Box>
          )}
        </div>
      );
    }

    TabPanel.propTypes = {
      children: PropTypes.node,
      index: PropTypes.any.isRequired,
      value: PropTypes.any.isRequired,
    };
    const { tabcount, tablabel, tabpanel, customStyle, tabicon } = this.props;
    // console.log("customStyle", customStyle)
    return (

      <React.Fragment>
        <Paper square style={{flexGrow:1,background: "#f4f5f7"}} >
          <Tabs value={this.state.tabvalue} onChange={this.handleTabChange} className="custom-tabs"  aria-label="simple tabs example">
            {tabcount.map((index) => (

              <Tab key={index} icon={tabicon[index]} iconPosition="start" label={tablabel[index]} {...a11yProps(index)} />

            ))}

          </Tabs>
        </Paper>
        {tabcount.map((index) => (

          <TabPanel value={this.state.tabvalue} key={index} index={index} className="white-bg" style={customStyle}   onClick={(e) => this.handleAccordianClick(e)}>
            <div style={{color:"#817e7e", fontWeight:"400", paddingBottom:"50px"}}>{tabpanel[index]}</div>
          </TabPanel>

        ))}
      </React.Fragment>
    )
  }
}
export default TabUIGeneric
