import React, { useState } from "react";
import "./EditProject.css";
import axios from "axios";
import captions from "../../config/captions.json";
import { timezoneList, seleniumVersionList } from "../../config/siteItemList";
import { UIDropDown, UIButton, UITextField, UINumField } from "../UIComponents";
import {
  checkNameField,
  checkValidNonEmptyValues,
  checkAlphaNumField,
  blockInvalidChar,
  checkNumFieldWithoutRange,
} from "../checkFields";

function EditSiteBasicDetails(props) {
  const [siteData, setSiteData] = useState(props.siteData);
  const [siteEdit, setSiteEdit] = useState(false);
  const [siteApply, setSiteApply] = useState(false);

  const [sDName, setSDName] = useState(props.siteData.displayName);
  const [sTimezone, setSTimezone] = useState(
    props.siteData.TimeZone + " " + props.siteData.TimeZoneShort
  );
  const [sTimeShort, setSTimeShort] = useState(props.siteData.TimeZoneShort);
  const [sUkey, setSUkey] = useState(
    props.siteData.userKey ? props.siteData.userKey : ""
  );
  const [sPKey, setSPKey] = useState(
    props.siteData.passKey ? props.siteData.passKey : ""
  );
  const [sThreadCnt, setSThreadCnt] = useState(
    props.siteData.threadCount ? props.siteData.threadCount : ""
  );
  const [sSelVer, setSSelVer] = useState(
    props.siteData.seleniumVersion ? props.siteData.seleniumVersion : ""
  );
  const [sParthrAll, setSParthrAll] = useState(
    props.siteData.partnerThreadsAllocated
      ? props.siteData.partnerThreadsAllocated
      : ""
  );
  const [basicSiteData, setBasicSiteData] = useState({});
  const [msg, setMsg] = useState("");
  const [, setIdValid] = useState(true);
  const [dNameValid, setDNameValid] = useState(true);
  const [sVerValid, setSVerValid] = useState(true);
  const [threadCntValid, setThreadCntValid] = useState(true);
  const [parthrAllValid, setParthrAllValid] = useState(true);
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })

  function editSiteBasic() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .patch(url + "/" + props.projId + "/" + props.siteId, basicSiteData, {
        headers: headerData,
      })
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          setBasicSiteData({});
          setMsg("");
          responseData.sites.map((item) => {
            if (item.siteid === props.siteId) {
              setSiteData(item);
              setSDName(item.displayName);
              setSTimezone(item.TimeZone + " " + item.TimeZoneShort);
              setSTimeShort(item.TimeZoneShort);
              setSUkey(item.userKey ? item.userKey : "");
              setSPKey(item.passKey ? item.passKey : "");
              setSSelVer(item.seleniumVersion ? item.seleniumVersion : "");
              setSThreadCnt(item.threadCount ? item.threadCount : "");
              setSParthrAll(
                item.partnerThreadsAllocated ? item.partnerThreadsAllocated : ""
              );
            }
          });
        } else {
          setMsg(captions.error.SITE_EDIT_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 401) {
            setMsg(captions.error.SITE_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMsg(captions.error.SITE_EDIT_INTERNAL_ERROR);
          }
        } else {
          resetSiteData();
          setMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function resetSiteData() {
    setSDName(siteData.displayName);
    setSTimezone(siteData.TimeZone + " " + siteData.TimeZoneShort);
    setSTimeShort(siteData.TimeZoneShort);
    setSUkey(siteData.userKey ? siteData.userKey : "");
    setSPKey(siteData.passKey ? siteData.passKey : "");
    setSSelVer(siteData.seleniumVersion ? siteData.seleniumVersion : "");
    setSThreadCnt(siteData.threadCount ? siteData.threadCount : "");
    setSParthrAll(
      siteData.partnerThreadsAllocated ? siteData.partnerThreadsAllocated : ""
    );
    setMsg("");
    setIdValid(true);
    setDNameValid(true);
    setSVerValid(true);

    setThreadCntValid(true);
    setParthrAllValid(true);
  }

  return (
    <div>
      <div className="row">
        <div
          className="col text-right"
          style={{
            //backgroundColor: "rgba(0, 0, 0, .5)",
            backgroundColor: "rgba(0, 0, 0, .09)",
            margin: "5px 18px 0px 18px",
          }}
        >
          {siteEdit ? (
            <div>
              <UIButton
                variant="text"
                title={"Apply"}
                disabled={
                  checkValidNonEmptyValues(
                    [
                      dNameValid,
                      sVerValid,
                      parthrAllValid,
                      threadCntValid,
                      siteApply,
                    ],
                    [sSelVer, sDName, sTimezone, sTimeShort]
                  )
                    ? false
                    : true
                }
                onClick={() => {
                  editSiteBasic();
                  setSiteEdit(!siteEdit);
                  setSiteApply(!siteApply);
                }}
              />

              <UIButton
                variant="text"
                title={"Cancel"}
                onClick={() => {
                  setSiteEdit(!siteEdit);
                  resetSiteData();
                }}
              />
            </div>
          ) : (
            <div>
            <span>{msg}</span>
            <UIButton
              variant="text"
              title={"Edit"}
              onClick={() => {
                setSiteEdit(!siteEdit);
                setSiteApply(false);
              }}
            />
            </div>
          )}
        </div>
      </div>
      <div className="row border m-1">
        <div className="row m-1 ml-5">
          <div className="col mb-5 ml-5">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Site Display Name</span>

              <UITextField
                disabled={siteEdit ? false : true}
                value={sDName}
                placeholder="Please enter site display name"
                onChange={(e) => {
                  setMsg("");
                  checkNameField(e.target.value)
                    ? setDNameValid(true)
                    : setDNameValid(false);
                  setSDName(e.target.value);
                  setBasicSiteData({
                    ...basicSiteData,
                    displayName: e.target.value,
                  });
                  setSiteApply(true);
                }}
                helperText={
                  !dNameValid && sDName !== ""
                    ? captions.validation.SITE_DNAME
                    : ""
                }
                error={!dNameValid && sDName !== ""}
                width={290}
              />
            </div>
            <div className="d-flex mt-3">
              <span className="title-prompts mt-3">TimeZone</span>
              <UIDropDown
                disabled={siteEdit ? false : true}
                variant="standard"
                value={sTimezone}
                onChange={(e) => {
                  setMsg("");
                  var timezone = e.target.value.split(" ")[0];
                  var timeShort = e.target.value.split(" ")[1];
                  setSTimezone(e.target.value);
                  setSTimeShort(timeShort);
                  setBasicSiteData({
                    ...basicSiteData,
                    TimeZone: timezone,
                    TimeZoneShort: timeShort,
                  });
                  setSiteApply(true);
                }}
                placeholder="Please select timezone"
                placeholderStyle={{ color: "#B3B3B3", fontStyle: "normal" }}
                minWidth={290}
                textAlign={"left"}
                maxHeight={250}
                list={timezoneList}
                style={{
                  WebkitTextFillColor: "#000000",
                }}
              />
            </div>
            <div className="d-flex mt-3">
              <span className="title-prompts mt-3">Selenium Version</span>

              <UIDropDown
                value={sSelVer}
                disabled={siteEdit ? false : true}
                variant="standard"
                onChange={(e) => {
                  setMsg("");
                  setSSelVer(e.target.value);
                  setBasicSiteData({
                    ...basicSiteData,
                    seleniumVersion: e.target.value,
                  });
                  setSiteApply(true);
                }}
                placeholder="Please select version"
                placeholderStyle={{ color: "#B3B3B3", fontStyle: "normal" }}
                minWidth={290}
                textAlign={"left"}
                maxHeight={250}
                list={seleniumVersionList}
                style={{
                  WebkitTextFillColor: "#000000",
                }}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Thread Count</span>
              <UINumField
                disabled={siteEdit ? false : true}
                value={sThreadCnt}
                min={0}
                width={290}
                onKeyDown={blockInvalidChar}
                onChange={(e) => {
                  setMsg("");
                  checkNumFieldWithoutRange(e.target.value)
                    ? setThreadCntValid(true)
                    : setThreadCntValid(false);
                  setSThreadCnt(e.target.value);
                  setBasicSiteData({
                    ...basicSiteData,
                    threadCount: e.target.value,
                  });
                  setSiteApply(true);
                }}
                placeholder={"Please enter count"}
                helperText={
                  !threadCntValid && sThreadCnt !== ""
                    ? captions.validation.SITE_NUM
                    : ""
                }
                error={!threadCntValid && sThreadCnt !== ""}
              />
            </div>
          </div>
          <div className="col mb-5 ml-5">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">
                Partner Threads Allocated
              </span>
              <UINumField
                disabled={siteEdit ? false : true}
                value={sParthrAll}
                min={0}
                width={300}
                onKeyDown={blockInvalidChar}
                onChange={(e) => {
                  setMsg("");
                  checkNumFieldWithoutRange(e.target.value)
                    ? setParthrAllValid(true)
                    : setParthrAllValid(false);
                  setSParthrAll(e.target.value);
                  setBasicSiteData({
                    ...basicSiteData,
                    partnerThreadsAllocated: e.target.value,
                  });
                  setSiteApply(true);
                }}
                placeholder={"Please enter count"}
                helperText={
                  !setParthrAllValid && setSParthrAll !== ""
                    ? captions.validation.SITE_NUM
                    : ""
                }
                error={!setParthrAllValid && setSParthrAll !== ""}
              />
            </div>
            <div className="d-flex mt-4">
            <span className="title-prompts mt-1">User Key</span>

            <UITextField
              disabled={siteEdit ? false : true}
              value={sUkey}
              placeholder="Please enter user key"
              onChange={(e) => {
                setMsg("");
                setSUkey(e.target.value);
                setBasicSiteData({ ...basicSiteData, userKey: e.target.value });
                setSiteApply(true);
              }}
              width={300}
            />
          </div>
          <div className="d-flex mt-4">
            <span className="title-prompts mt-1">Pass Key</span>

            <UITextField
              disabled={siteEdit ? false : true}
              value={sPKey}
              placeholder="Please enter pass key"
              onChange={(e) => {
                setMsg("");

                setSPKey(e.target.value);
                setBasicSiteData({ ...basicSiteData, passKey: e.target.value });
                setSiteApply(true);
              }}
              width={300}
            />
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditSiteBasicDetails;
