import React, { Component } from 'react';
import HSBar from 'react-horizontal-stacked-bar-chart';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import Pagination from '@material-ui/lab/Pagination';
import axios from 'axios';
import './Results.css';
import Resultchart from './Resultchart';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import download from 'downloadjs';
import { capitalize } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { Buffer } from 'buffer'
import ResultDetails from './ResultDetails';
import AspectRatioIcon from '@material-ui/icons/AspectRatio';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      results: [],
      currentPage: 1,
      perPage: 10,
      is_Api_Result: false,
      lastTenId: [],
      index: null,
      propsState: null,
      user: '',
      children: [],
      downloadProgress: [],
      errorReportProgress: [],
      loading: false,
      apiException: false,
      isChartsApiCompleted: false,
      pageNumbers: 0,
      loadedIndex: [],
      isExpand: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event, value) {
    // console.log(value);
    this.resultRequest(value)
    this.setState({
      currentPage: Number(typeof event.target.innerText != "undefined" ? event.target.innerText : value),
    });
  }
  toBase64(input) {
    return Buffer.from(input, 'utf-8').toString('base64')
  }
  formateJSON = (result, ind) => {
    const chartData = result.data;
    const priorityReport = chartData.priorityReport;



    let priorityDataPass = [];
    let priorityDataError = [];
    let priorityDataFail = [];
    let priorityRowKeys = [];

    priorityReport.forEach((val) => {
      priorityRowKeys.push(val.priorityName);
      priorityDataPass.push(val.priorityPassTotal);
      priorityDataFail.push(val.priorityFailTotal);
      priorityDataError.push(val.priorityErrorTotal);
    });
    const priorityObject = {
      labels: priorityRowKeys,
      data: [
        [...priorityDataPass],
        [...priorityDataError],
        [...priorityDataFail],
      ],
    };

    const moduleReport = chartData.moduleReport;

    let moduleDataPass = [];
    let moduleDataError = [];
    let moduleDataFail = [];
    let moduleRowKeys = [];

    moduleReport.forEach((val) => {
      moduleRowKeys.push(val.moduleName);
      moduleDataPass.push(val.modulePass);
      moduleDataError.push(val.moduleError);
      moduleDataFail.push(val.moduleFail);
    });

    const moduleObject = {
      labels: moduleRowKeys,
      data: [[...moduleDataPass], [...moduleDataError], [...moduleDataFail]],
    };

    const commonsprops = { moduleObject, priorityObject };
    let array = [...this.state.children, <Resultchart key="0" text={commonsprops} />];
    if (this.state.children.length === 1) {
      this.setState({
        children: [],
      }, () => {
        this.setState({
          children: [<Resultchart key="1" text={commonsprops} />],
        });
      });
    } else if (this.state.children.length === 0) {
      this.setState({
        children: [array],
      });
    }
  };

  apicall = (ind, e, nindex) => {
    // console.log('card click', e);
    this.handleExpand(e, nindex);
    // e.stopPropagation();
    // console.log(e.target.getAttribute("data-target"))
    // debugger;
    this.setState({ isExpand: false })
    let { index } = this.state;
    let selectedSiteData = JSON.parse(sessionStorage.getItem('selectedSiteData'));
    let site = sessionStorage.getItem('selectedSite');
    let chartServiceApi = window.configs.CHART_SERVICE_URL + 'suite-report/' + site + '/' + ind;
    if (ind !== index) {
      index = ind;
      let priorityResult = '';
      const chartApiResponse = axios.get(chartServiceApi);
      chartApiResponse.then((result) => {
        this.formateJSON(result, ind);
      });
    } else {
      index = ind;
    }
  };

  updateState() {
    this.componentDidMount();
  }
  componentDidMount() {
    this.resultRequest(1);
  }
  resultRequest(offset) {
    let limit = 10;
    let that = this;
    let loadedindex = this.state.loadedIndex;
    loadedindex.push(offset)
    this.setState({ 'loadedIndex': loadedindex })
    var site = sessionStorage.getItem('selectedSite');
    let one = window.configs.REPORT_SERVICE_URL +
      site +
      '' +
      '?limit=' + limit + '&offset=' + offset;
    const requestone = axios.get(one);
    requestone.then((res) => {
      const progressLoaders = [];
      for (let i = 0; i < limit; i++) {
        progressLoaders.push({ id: i, status: false })
      }
      res.data.data.map(item => item["detailedReport"] = []);
      that.setState({
        is_Api_Result: true,
        results: res.data.data,
        downloadProgress: progressLoaders,
        errorReportProgress: progressLoaders,
        pageNumbers: Math.ceil(res.data.totalCount / 10)
      });
    });
  }
  // Downlaod test report
  downloadReport = async (res, index, e) => {
    // console.log('download report on click', e);
    e.stopPropagation();
    this.updateDownloadReportStatus(true, index);
    const project = sessionStorage.getItem('selectedSite');
    const { executionId } = res;
    const url = `${window.configs.DOWNLOAD_REPORT_SERVICE_URL}download-excel/${project}/${executionId}`;
    const _resp = await axios({
      url,
      method: 'POST',
      responseType: 'blob',
    });
    if (_resp.status === 200) {
      const { filename } = _resp.headers;
      download(_resp.data, filename);
      this.updateDownloadReportStatus(false, index);
    } else {
      this.updateDownloadReportStatus(false, index);
      alert('some error has occured');
    }
    return false;
  };

  // Download test report
  downloadErrorReport = async (res, index, e) => {
    // console.log('download report on click', e);
    e.stopPropagation();
    this.updateErrorReportDownloadStatus(true, index);
    const project = sessionStorage.getItem('selectedSite');
    const { executionId } = res;
    const url = `${window.configs.DOWNLOAD_REPORT_SERVICE_URL}download-excel/${project}/${executionId}?report=error`;
    const _resp = await axios({
      url,
      method: 'POST',
      responseType: 'blob',
    });
    if (_resp.status === 200) {
      const { filename } = _resp.headers;
      download(_resp.data, filename);
      this.updateErrorReportDownloadStatus(false, index);
    } else {
      this.updateErrorReportDownloadStatus(false, index);
      alert('some error has occured');
    }
    return false;
  };

  updateErrorReportDownloadStatus = (status, id) => {
    const errorReportProgress = [...this.state.errorReportProgress];
    const errorReportProgressObj = errorReportProgress.filter(
      (elm) => elm.id === id
    );
    errorReportProgressObj['status'] = status;
    errorReportProgress[id] = errorReportProgressObj;
    this.setState({ errorReportProgress });
  };

  updateDownloadReportStatus = (status, id) => {
    const downloadProgress = [...this.state.downloadProgress];
    const downloadProgressObj = downloadProgress.filter((elm) => elm.id === id);
    downloadProgressObj['status'] = status;
    downloadProgress[id] = downloadProgressObj;
    this.setState({ downloadProgress });
  };
  handleExpand = (e, index) => {
    this.setState(prevState => ({ isExpand: !prevState.isExpand }), () => {
      var stateCopy = Object.assign({}, this.state);
      stateCopy.results[index].detailedReport = this.state.results;
      this.setState(stateCopy);
    });
    // this.state.results[index][detailedResult] =[];

    // this.state.results[index][detailedResult] = this.state.results;
  }
  render() {
    const { results, currentPage, perPage } = this.state;
    const indexOfLast = currentPage * perPage;
    const indexOfFirst = indexOfLast - perPage;
    const dateformat = 'YYYY-MM.DD-HH:mm:ss Z';
    const selectedResult = results
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(results.length / perPage); i++) {
      pageNumbers.push(i);
    }
    return (
      <React.Fragment>
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg1">Recent Result</label>
            </div>
          </div>
        </div>
        {this.state.is_Api_Result === true && (
          <div className="card results" style={{ paddingTop: '10px' }} id="accordion">
            {selectedResult.map((resultList, index) => (
              <div className="card subcard" key={index}>
                <div
                  key={index}
                  style={{ fontSize: '16px', cursor: 'pointer' }}
                  data-toggle="collapse"
                  data-target={'#collapseExample' + index}
                  onClick={(e) => this.apicall(resultList.executionId, e, index)}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="a1 col-sm-10 left-align">
                        <div className='info-holder'>
                          <span className="info">
                            {resultList.execution_date}-
                            {resultList.execution_start_time}
                          </span>
                          <span className="info">{resultList.environment}</span>
                          <span className="info"> {resultList.testsuitname}</span>
                          <span className="info"> {resultList.user_name}</span>
                          <span className="info">
                            {' '}
                            Count: {resultList.totalcount}
                          </span>
                          {!resultList.device || resultList.device.toLowerCase() === "none" ? "" : <span className="info"> {resultList.device}</span>}
                          <span>
                            <a className='expand-link' onClick={(e) => this.handleExpand(e, index)}>
                              {!this.state.isExpand ?
                                <span>Expand <AspectRatioIcon></AspectRatioIcon></span> :
                                <ArrowBackIcon></ArrowBackIcon>}
                            </a>
                          </span>
                        </div>
                        <div className="downloadBtnSpan" id="download-excel-btn">
                          <span onClick={(e) => {
                            this.downloadReport(resultList, index, e);
                          }}>
                            <img
                              alt="download full report"
                              title="Full Report"
                              src={`../images/excel.png`}
                              className="reportImg"
                            />
                            {this.state.downloadProgress[index]['status'] && (
                              <CircularProgress
                                size={24}
                                className="buttonProgressLoader"
                              />
                            )}
                          </span>&nbsp;&nbsp;
                          <span
                            onClick={(e) => {
                              this.downloadErrorReport(resultList, index, e);
                            }}
                          >
                            <img
                              alt="download error report"
                              title="Error Report"
                              src={`../images/excel.png`}
                              className="reportImg"
                            />
                            {this.state.errorReportProgress[index]['status'] && (
                              <CircularProgress
                                size={24}
                                className="buttonErrorProgressLoader"
                              />
                            )}
                          </span>&nbsp;&nbsp;
                          {
                            resultList.htmlReportLink ?
                              <span > <a href={resultList.htmlReportLink} download>
                                < img
                                  alt="download html report"
                                  title="HTML Report"
                                  src={`../images/html_logo.png`}
                                  className="reportImg"
                                />
                              </a></span> : <span></span>
                          }
                          {
                            resultList.applicationLogLink ?
                              <span > <a href={resultList.applicationLogLink} download>
                                < img
                                  alt="download application log "
                                  title="Test Log"
                                  src={`../images/applogs_logo.png`}
                                  className="reportImg"
                                />
                              </a></span> : <span></span>
                          }
                          {
                            resultList.customReportLink ?
                              resultList.customReportLink.map((customReportLinks, index) =>
                                <span > <a href={customReportLinks} download>
                                  <img
                                    alt="download custom report "
                                    title="Custom Report"
                                    src={`../images/customreport.png`}
                                    className="reportImg"
                                  />
                                </a></span>
                              )

                              : <span></span>}
                        </div>
                      </div>

                      <div className="a2 col-sm-2 right-align">
                        {resultList.testType == "api" ? (
                          <div>
                            <b>API</b>
                          </div>
                        ) : resultList.testType == "app" ? (
                          <div className="browserinfo-holder" style={{marginRight:"10px"}}>
                            <img
                              alt="os"
                              src={`../images/${
                                resultList.os_name.charAt(0).toLowerCase() +
                                resultList.os_name.slice(1)
                              }.png`}
                              title={`${capitalize(
                                resultList.os_name.charAt(0).toLowerCase() +
                                  resultList.os_name.slice(1)
                              )}`}
                            />
                            <img
                              alt="formFactor"
                              className={
                                resultList.formFactor +
                                " " +
                                resultList.deviceOrientation
                              }
                              src={`../images/${resultList.formFactor}.png`}
                              title={`${capitalize(
                                resultList.formFactor +
                                  (resultList.deviceOrientation === ""
                                    ? " "
                                    : ", " + resultList.deviceOrientation)
                              )}`}
                            />
                          </div>
                        ) : (
                          <div className="browserinfo-holder">
                            <img
                              alt="os"
                              src={`../images/${
                                resultList.os_name.charAt(0).toLowerCase() +
                                resultList.os_name.slice(1)
                              }.png`}
                              title={`${capitalize(
                                resultList.os_name.charAt(0).toLowerCase() +
                                  resultList.os_name.slice(1)
                              )}`}
                            />
                            <img
                              alt="browser"
                              src={`../images/${
                                resultList.browser.charAt(0).toLowerCase() +
                                resultList.browser.slice(1)
                              }.png`}
                              title={`${capitalize(
                                resultList.browser.charAt(0).toLowerCase() +
                                  resultList.browser.slice(1)
                              )}`}
                            />

                            <img
                              alt="formFactor"
                              className={
                                resultList.formFactor +
                                " " +
                                resultList.deviceOrientation
                              }
                              src={`../images/${resultList.formFactor}.png`}
                              title={`${capitalize(
                                resultList.formFactor +
                                  (resultList.deviceOrientation === ""
                                    ? " "
                                    : ", " + resultList.deviceOrientation)
                              )}`}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="row">
                      <div className="a1 col-sm-10">
                        <HSBar
                          showValueIn
                          height={25}
                          data={[
                            {
                              value: 8 + Number(`${resultList.pass_percent}`),
                              description: `${resultList.pass_percent}%`,
                              color: '#009EEB',
                              name: 'Pass',
                            },
                            {
                              value: 8 + Number(`${resultList.error_percent}`),
                              description: `${resultList.error_percent}%`,
                              color: '#003C5B',
                              name: 'Error',
                            },
                            {
                              value: 8 + Number(`${resultList.fail_percent}`),
                              description: `${resultList.fail_percent}%`,
                              color: '#8B0000',
                              name: 'Fail',
                            },
                          ]}
                        />
                      </div>
                      <div className="a2 col-sm-2 right-align">
                        <span
                          className="execution-time"
                          title="Total execution time"
                        >
                          {resultList.execution_time}
                        </span>
                      </div>
                    </div>

                  </div>
                </div>

                <div
                  className="collapse"
                  id={'collapseExample' + index}
                  data-parent="#accordion"
                >
                  <div className='card-body'>
                    <div className='row'>
                      <div className="a1 col-sm-10" style={{ textAlign: "left" }}>

                      </div>
                    </div>
                    <div className={this.state.isExpand ? 'hide' : ''}>
                      {
                        resultList.detailedReport.length != 0 &&

                        <ResultDetails resultdata={resultList} testType={resultList.testType} graphdata={
                          <div className={this.state.isExpand ? 'hide' : ''}>
                            {this.state.children.map((child) => child)}
                          </div>
                        }></ResultDetails>}

                    </div>

                  </div>
                </div>
              </div>
            ))}
            <div className="pagination_r">
              <Pagination
                count={this.state.pageNumbers}
                onChange={this.handleChange}
                color="primary"
              />
            </div>
          </div>
        )}
        {this.state.is_Api_Result === false && (
          <div className="card error_result">Waiting to get Result...</div>
        )}


      </React.Fragment>
    );
  }
}
export default Results;
