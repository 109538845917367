import React from "react";
import "./TestExecution.css";

import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material/styles";

export function UIIconButton(props) {
  return (
    <IconButton
      aria-label="delete"
      color="primary"
      size="large"
      onClick={props.onClick}
    >
      <DeleteIcon fontSize="inherit" />
    </IconButton>
  );
}

export function UIDialog(props) {
  return (
    <Dialog
      open={props.open}
      onClose={() => props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={"sm"}
      sx={{
        "& .MuiDialog-root": {
          position: "fixed",
          right:"10%",
          bottom:"10%",
          top: "0px",
          left: "0px",
          zIndex:1300
          
        },
      }}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleNo}>No</Button>
        <Button onClick={props.handleYes}>Yes</Button>
      </DialogActions>
    </Dialog>
  );
}

export function UIModal(props) {
  return (
    <Modal
      open={props.open}
      onClose={() => props.handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 750,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 3,
        }}
      >
        
        <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg">{props.title}</label>
            </div>
        </div>

        <div className="row m-1 ml-4">{props.fields}</div>
        <div className="row mt-3 ml-3">
          <div className="col mt-3 ml-3">
            <div className="d-flex justify-content-center  mt-3 ml-5">
              {props.actions}
            </div>
          </div>
        </div>
        <div className="row">{props.errors}</div>
        <div className="row">{props.delete ? props.delete : null}</div>
      </Box>
    </Modal>
  );
}
export function UIDropDown(props) {
  return (
    <Select
      variant={props.variant ? props.variant : "outlined"}
      value={props.value}
      disabled={props.disabled ? props.disabled : false}
      onChange={props.onChange}
      onOpen={props.onOpen ? props.onOpen : ()=>{}}
      onClose={props.onClose ? props.onClose : ()=>{}}
      displayEmpty
      renderValue={(selected) => {
        if (selected === "") {
          return (
            <em
              style={
                props.placeholderStyle
                  ? props.placeholderStyle
                  : {
                      color: "gray",
                    }
              }
            >
              {props.placeholder}
            </em>
          );
        }
        return selected;
      }}
      sx={{
        minWidth: props.minWidth,
        textAlign: props.textAlign,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#71797E",
        },
        "& .MuiInputBase-input.Mui-disabled": props.style ? props.style : {},
      }}
      MenuProps={{
        PaperProps: { sx: { maxHeight: props.maxHeight } },
      }}
    >
      <MenuItem value="">
        <em>{props.placeholder}</em>
      </MenuItem>

      {props.list.map((option) => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </Select>
  );
}

export function UITextField(props) {
  return (
    <TextField
      required
      value={props.value}
      variant="standard"
      placeholder={props.placeholder}
      onChange={props.onChange}
      helperText={props.helperText ? props.helperText : ""}
      error={props.error ? props.error : false}
      sx={{
        width: props.width,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
        },
      }}
      disabled={props.disabled ? props.disabled : false}
      InputProps={props.disableUnderline ? { disableUnderline: true } : {}}
    />
  );
}

export function UIButton(props) {
  return (
    <Button
      sx={{
        "&:focus": {
          outline: "none",
        },
        textTransform: "none",
      }}
      variant={props.variant}
      onClick={props.onClick}
      disabled={props.disabled ? props.disabled : false}
    >
      {props.title}
    </Button>
  );
}

export function UINumField(props) {
  return (
    <TextField
      variant="standard"
      type="number"
      //defaultValue={props.defaultValue ? props.defaultValue : ""}
      InputProps={{
        inputProps: {
          min: props.min,
          max: props.max,
          /* onKeyDown: (event) =>
            props.preventKeyBoard ? event.preventDefault() : () => {}, */
        },
      }}
      sx={{
        width: props.width,
        "& .MuiInputBase-input.Mui-disabled": {
          WebkitTextFillColor: "#000000",
        },
      }}
      value={props.value}
      onKeyDown={props.onKeyDown}
      onChange={props.onChange}
      placeholder={props.placeholder}
      helperText={props.helperText}
      error={props.error}
      disabled={props.disabled ? props.disabled : false}
    />
  );
}

export const UIAccordionSummary = styled((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : //: "rgba(192, 0, 0, .09)",
        // "rgb(20, 50, 61)",
        "#343a40",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    //color: "#fff",
  },
}));

export const UIAccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  //borderTop: "1px solid rgba(0, 0, 0, .125)",
  ".MuiAccordionDetails-root": {
    //maxHeight: "700px",
    //overflowY: "scroll",
  },
}));

export const UIAccordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));
