import React, { useState, useEffect } from "react";
import "./EditProject.css";

import {
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
} from "../UIComponents";
import Typography from "@mui/material/Typography";
import EditUserBasicDetails from "./EditUserBasicDetails";
import EditUserRights from "./EditUserRights";
import axios from "axios";
import captions from "../../config/captions.json";

function EditUser(props) {
  const [projectIdList, setProjectIdList] = useState([]);
  const [, setMsg] = useState("");
  const [headerData] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  });

  useEffect(() => {
    getProjectIdList();
  }, []);

  function getProjectIdList() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .get(url, {
        headers: headerData,
      })
      .then((response) => {
        if (response.data.status === "success") {
          var response_projectIds = response.data.info;
          setProjectIdList(response_projectIds);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 401) {
            setMsg(captions.error.PROJECT_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          setMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  return (
    <React.Fragment>
      <UIAccordion expanded={true}>
        <UIAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{
            cursor: "default",
            backgroundColor: "#343a40",
            color: "#fff",
          }}
        >
          <div className="row">
            <div className="col  text-left d-flex flex-row">
              <div className="d-flex flex-row">
                <Typography>Email: </Typography>&nbsp;&nbsp;
                <Typography>{props.userEmail}</Typography>
              </div>
            </div>
          </div>
        </UIAccordionSummary>
        <UIAccordionDetails style={{ backgroundColor: "#fff" }}>
          <EditUserBasicDetails
            userEmail={props.userEmail}
            userData={props.userData}
          />

          <EditUserRights
            userEmail={props.userEmail}
            userData={props.userData}
            projectList={projectIdList}
          />
        </UIAccordionDetails>
      </UIAccordion>
    </React.Fragment>
  );
}

export default EditUser;
