import React, { Component } from 'react';
class RestrictedPage extends Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    
    redirect() {
        
        console.log(JSON.parse(sessionStorage.getItem('selectedPage')));

    }
    componentDidMount(){}
    render() {
        return (
            
                <div className="card  custcard">
                    <div className="cord-body">
                        <div className="testHeading">
                            <label className="headermsg1">
                                You don't have access to this page. Please <span onClick={this.redirect()}>click here</span>
                            </label>
                        </div>
                    </div>
                </div>
           
        );
    }
}

export default RestrictedPage;
