import React, { useState, useEffect } from "react";
import "../TestExecution.css";
import captions from "../../config/captions.json";
import {
  checkValidNonEmptyValues,
  checkNonEmptyValues,
  checkUserName,
  checkEmail,
} from "../checkFields";
import axios from "axios";
import { UIButton, UITextField, UIDropDown } from "../UIComponents";

import Checkbox from "@mui/material/Checkbox";

function AdminAddUser() {
  const [userFName, setUserFName] = useState("");
  const [userLName, setUserLName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userCompName, setUserCompName] = useState("");
  const [userProjectId, setUserProjectId] = useState("");
  const [projectIdList, setProjectIdList] = useState([]);
  const [userStatus, setUserStatus] = useState(true);

  const [msg, setMsg] = useState("");
  const [fNameValid, setFNameValid] = useState(true);
  const [lNameValid, setLNameValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })
  const [adminRights, ] = useState(sessionStorage.getItem("UserDetails") && "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role === "admin" ? true : false)

  useEffect(() => {
    getProjectIdList();
  }, []);

  function getProjectIdList() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    axios
      .get(url, {
        headers: headerData,
      })
      .then((response) => {
        if (response.data.status === "success") {
          var response_projectIds = response.data.info;
          setProjectIdList(response_projectIds);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 401) {
            setMsg(captions.error.PROJECT_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          setMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function setInitialValues() {
    setUserFName("");
    setUserLName("");
    setUserEmail("");
    setUserCompName("");
    setUserProjectId("");
    setUserStatus("active");
  }

  function reset() {
    setInitialValues();
    setMsg("");
    setFNameValid(true);
    setLNameValid(true);
    setEmailValid(true);
  }

  function addUser() {
    if (
      checkValidNonEmptyValues(
        [fNameValid, lNameValid, emailValid],
        [userFName, userLName, userCompName, userProjectId]
      ) &&
      msg === ""
    ) {
      var data = {
        Status: userStatus ? "active" : "inactive",
        FirstName: userFName.trim(),
        LastName: userLName.trim(),
        CompanyName: userCompName.trim(),
        Email: userEmail.trim(),
        projectId: userProjectId,
      };
      //api call for adding project - projId
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;

      axios
        .post(
          url + "/adduser",

          data,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status === "success") {
            setInitialValues();
            setMsg(captions.message.USER_ADD_SUCCESS);
          } else {
            setMsg(captions.error.USER_ADD_INTERNAL_ERROR);
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 409) {
              setMsg(captions.error.USER_ADD_DUPLICATE);
            } else if (error.response.status === 400) {
              setMsg(captions.error.ADD_VALIDATION);
            } else if (error.response.status === 401) {
              setMsg(captions.error.USER_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setMsg(captions.error.USER_ADD_INTERNAL_ERROR);
            }
          } else {
            setMsg(captions.error.INTERNAL_ERROR);
            setInitialValues();
          }
        });
    } else {
      setMsg(captions.error.FIELDS_EMPTY);
    }
  }
  return (
    <React.Fragment>
      <div className="card  custcard">
        <div className="cord-body">
          <div className="testHeading">
            <label className="headermsg">{adminRights ? "Add User" : "Your access is denied"}</label>
          </div>
        </div>
      </div>

      {
        adminRights
        ?
        <div className="card  custcard">
          <div className="m-3">
            <div className="ml-5">
              <div className="d-flex mt-4 ml-5">
                <span className="title-prompts mt-2">First Name</span>
                <UITextField
                  value={userFName}
                  width={300}
                  placeholder="Please enter first name"
                  onChange={(e) => {
                    setMsg("");
                    checkUserName(e.target.value)
                      ? setFNameValid(true)
                      : setFNameValid(false);
                    setUserFName(e.target.value);
                  }}
                  helperText={
                    !fNameValid && userFName !== ""
                      ? captions.validation.USER_NAME
                      : ""
                  }
                  error={!fNameValid && userFName !== ""}
                />
              </div>
              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Last Name</span>
                <UITextField
                  value={userLName}
                  width={300}
                  placeholder="Please enter last name"
                  onChange={(e) => {
                    setMsg("");
                    checkUserName(e.target.value)
                      ? setLNameValid(true)
                      : setLNameValid(false);
                    setUserLName(e.target.value);
                  }}
                  helperText={
                    !lNameValid && userLName !== ""
                      ? captions.validation.USER_NAME
                      : ""
                  }
                  error={!lNameValid && userLName !== ""}
                />
              </div>
              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Email</span>
                <UITextField
                  value={userEmail}
                  width={300}
                  placeholder="Please enter email"
                  onChange={(e) => {
                    setMsg("");
                    checkEmail(e.target.value)
                      ? setEmailValid(true)
                      : setEmailValid(false);
                    setUserEmail(e.target.value);
                  }}
                  helperText={
                    !emailValid && userEmail !== ""
                      ? captions.validation.EMAIL
                      : ""
                  }
                  error={!emailValid && userEmail !== ""}
                />
              </div>
              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Company Name</span>
                <UITextField
                  value={userCompName}
                  width={300}
                  placeholder="Please enter company"
                  onChange={(e) => {
                    setMsg("");
                    setUserCompName(e.target.value);
                  }}
                />
              </div>
              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Project Rights</span>
                <UIDropDown
                  variant="standard"
                  value={userProjectId}
                  onChange={(e) => {
                    setMsg("");
                    setUserProjectId(e.target.value);
                  }}
                  placeholder="Please select project"
                  placeholderStyle={{ color: "#B3B3B3", fontStyle: "normal" }}
                  minWidth={310}
                  textAlign={"left"}
                  maxHeight={250}
                  list={projectIdList}
                />
              </div>

              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Active User</span>

                <Checkbox
                  id="active"
                  color={"primary"}
                  checked={userStatus}
                  onChange={(e) => {
                    e.target.checked ? setUserStatus(true) : setUserStatus(false);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-sm-8 btn-group cp-btn-group mr-5 mt-5 mb-3 justify-content-end">
                <div className="mr-3">
                  <UIButton
                    variant="contained"
                    onClick={() => {
                      addUser();
                    }}
                    disabled={
                      checkValidNonEmptyValues(
                        [fNameValid, lNameValid],
                        [
                          userFName,
                          userLName,
                          userCompName,
                          userEmail,
                          userProjectId,
                        ]
                      ) && msg===""
                        ? false
                        : true
                    }
                    title={"Add User"}
                  />
                </div>

                <div className="mr-3">
                  <UIButton
                    variant="contained"
                    onClick={() => {
                      reset();
                    }}
                    disabled={
                      checkNonEmptyValues([
                        userFName,
                        userLName,
                        userEmail,
                        userCompName,
                        userProjectId,
                      ])
                        ? false
                        : true
                    }
                    title={"Cancel"}
                  />
                </div>
              </div>
            </div>
            <div className="row mr-5">
              <div className="col d-flex justify-content-end">
                <span className="col-sm-8 btn-group cp-btn-group justify-content-end">
                  {msg}
                </span>
              </div>
            </div>
          </div>
        </div>
        :
        null
      }
    </React.Fragment>
  );
}
export default AdminAddUser;
