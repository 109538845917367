import React, { Component } from 'react';
import { Redirect, NavLink, withRouter } from 'react-router-dom';
import './Sidebar.css';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssessmentIcon from '@material-ui/icons/Assessment';
import LanguageIcon from '@material-ui/icons/Language';
import { getUserSession } from './UserSession';
import SiteSelection from './SiteSelection';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import pageAccessObj from './pageAccessObj';
import PageRedirector from './pageRedirector';
import pageAccess_menu from '../config/pageAccess_menu.json'
const SIDEBAR_SCHEMA = [...pageAccessObj];


class Sidebar extends Component {
  state = { isClicked: false };

  constructor(props) {
    super(props);
    this.state = {
      isClicked: true,
      isShow: false,
      selectedSite: sessionStorage.getItem('selectedSite'),
      selectedProject: sessionStorage.getItem('selectedProject'),
      selectedSiteDisplayName: sessionStorage.getItem('selectedSiteDisplayName'),
      pageAccess: [...SIDEBAR_SCHEMA],
      analytics: [],
      showAnalytics: false,
      pageAccessLength: 0,
      hideSideBar: false
    };
    window.SidebarComponent = this;
    this.child = React.createRef();
    this.siteselection = React.createRef(null);
    this.siteselectionnavbutton = React.createRef(null);
    window.addEventListener("mousedown", (e) => this.checkIfClickedOutside(e));
    this.checkIfClickedOutside = this.checkIfClickedOutside.bind(this);
  }
  checkIfClickedOutside(e) {
    let ref = this.siteselection;
    let ref1 = this.siteselectionnavbutton;
    if ((ref.current && !ref.current.contains(e.target)) && (ref1.current && !ref1.current.contains(e.target))) {
      // debugger;
      this.setState({ isShow: false });
      window.SiteSelectionComponent.resetSelection();
    }

  }
  toggleclassNames = () => {
    this.setState({ isClicked: true });
    document.getElementById('sidebar').classList.toggle('active');

    if (
      document.getElementById('sidebar').classList.contains('active') === true
    ) {
      this.setState({
        isClicked: false,
      });
    }
    if (
      document.getElementById('sidebar').classList.contains('active') === false
    ) {
      this.setState({
        isClicked: true,
      });
    }
  };
  toggleShow = (val) => {
    this.setState({ isShow: !this.state.isShow });
  };
  isSelected = (val) => {
    this.setState({ selectedSite: sessionStorage.getItem('selectedSite') });
    this.setState({ isShow: !val });
  };
  handleClose(val) {
    // console.log('true');
    val.isShow = false;
  }
  closeSiteSelection = () => {
    this.setState({ isShow: false });
  };

  updateProjectSelection = (val) => {
    this.setState({ selectedProject: val }, () => {
      this.updateSidebar();
    });
  };

  componentDidUpdate() {
    var project = sessionStorage.getItem('selectedProject');
    var site = sessionStorage.getItem('selectedSite');
    var current_page = this.props.location.pathname;
    var selectedPage = sessionStorage.getItem('selectedPage')
      ? JSON.parse(sessionStorage.getItem('selectedPage'))
      : {};
    var data = selectedPage;
    
    var userDetails =
      JSON.parse(sessionStorage.getItem('UserDetails')) != null
        ? JSON.parse(sessionStorage.getItem('UserDetails')).data.User.rights
          .Projects
        : {};
    if (typeof userDetails.length !== 'undefined' && userDetails.length !== 0) {
      userDetails.forEach((projects) => {
        var projectName = projects.ProjectName;
        if (typeof data[projectName] == 'undefined') {
          data[projectName] = {};
          if(projects.sites && projects.sites.length !== 0){
            projects.sites.forEach((sites, key) => {
              var siteName = sites.sitename;
              var access = projects.Page_access != null ? projects.Page_access.find(o => o.sitename === siteName) : "undefined";
              // data[projectName][siteName] = '/';
              if (typeof access != "undefined") {
                let analytics_page =
                  sites.menus &&
                  sites.menus.map((menu) => {
                    if (
                      (menu.submenus &&
                        menu.submenus.length > 0 &&
                        menu.code in access &&
                        access[menu.code] == true) ||
                      access[menu.code] == "true"
                    ) {
                      return menu;
                    }
                  });
                if (analytics_page && analytics_page.length > 0) {
                  let analytics_page_access = analytics_page.filter(
                    (analytic_page) => analytic_page !== undefined
                  );
                  analytics_page =
                    sites.menus &&
                    analytics_page_access &&
                    analytics_page_access.length > 0
                      ? analytics_page_access[0].code == "apps"
                      ? "/" + analytics_page_access[0].submenus[0].submenuCode + "/" +
                        this.state.selectedProject +
                        "+" +
                        this.state.selectedSite +
                        "+" +
                        analytics_page_access[0].code
                      :
                      "/analytics/" +
                        this.state.selectedProject +
                        "+" +
                        this.state.selectedSite +
                        "+" +
                        analytics_page_access[0].code +
                        "+" +
                        analytics_page_access[0].submenus[0].submenuCode
                      : null;
                }
                if (access.test_execution)
                  data[projectName][siteName] = '/';
                else {
                  let pageAccess = [...this.state.pageAccess];
                  for (var val in access) {
                    var access_val = val;
                    if (access[access_val] == true && (val == "api_execution" || val == "test_execution")) {
                      data[projectName][siteName] = '/ApiExecution';
                    }
                    if (
                      access[access_val] == true &&
                      (val == "app_execution" || val == "test_execution")
                    ) {
                      data[projectName][siteName] = "/AppExecution";
                    }
                    else {
                      
                      if (access[access_val] == true) {

                        pageAccess = pageAccess.map((elm) => {
                          if (elm.key === val && elm.isAllowed) {
                            if (current_page != "/RestrictedPage")
                              data[projectName][siteName] = elm.path;
                          }
                          return elm;
                        });
                        // console.log(pageAccess)
                      }
                    }
                  }
                  if (Object.values(access).indexOf(true) == -1 && (access.analytics == "true" || access.analytics == true)) {
                    if (current_page != "/RestrictedPage")
                      data[projectName][siteName] = analytics_page;
                  }
                  let pageAccessFalseArr = pageAccess.map((elm) => elm.isAllowed);
                  if (
                    !pageAccessFalseArr.includes(true) &&
                    data[projectName][siteName] == undefined
                  ) {
                    if (analytics_page !== null) {
                      data[projectName][siteName] = analytics_page;
                    } else {
                      data[projectName][siteName] = "/RestrictedPage";
                    }
                  }
                }
              }


            });
          }
        } else {
          if(projects.sites && projects.sites.length !== 0){
            projects.sites.forEach((sites, key) => {
              var siteName = sites.sitename;
              var access = projects.Page_access != null ? projects.Page_access.find(o => o.sitename === siteName) : "undefined";
              let analytics_page =  sites.menus && sites.menus.length > 0 
              && sites.menus[0].submenus && sites.menus[0].submenus.length>0 && (access[sites.menus[0].code] == true ||
                      access[sites.menus[0].code] == "true")
              ?
                sites.menus[0].code == "apps" 
                ? '/' + sites.menus[0].submenus[0].submenuCode+"/"+this.state.selectedProject + '+' + this.state.selectedSite + '+' + sites.menus[0].code 
                : '/analytics/' + this.state.selectedProject + '+' + this.state.selectedSite + '+' + sites.menus[0].code + '+' + sites.menus[0].submenus[0].submenuCode  
              :
               '/Result'
              if (typeof data[projectName][siteName] == 'undefined') {
                // data[projectName][siteName] = '/';
                if (typeof access != "undefined") {
                  if (access.test_execution)
                    data[projectName][siteName] = '/';
                  else {
                    let pageAccess = [...this.state.pageAccess];
                    for (var val in access) {

                      var access_val = val;
                      if (access[access_val] == true) {
                        pageAccess = pageAccess.map((elm) => {
                          if (elm.key === val && elm.isAllowed) {
                            if (current_page != "/RestrictedPage")
                              data[projectName][siteName] = elm.path;
                          }
                          return elm;
                        });

                      }
                    }
                    if (Object.values(access).indexOf(true) == -1 && (access.analytics == "true" || access.analytics == true )) {
                      if (current_page != "/RestrictedPage")
                        data[projectName][siteName] = analytics_page;
                    }
                    let pageAccessFalseArr = pageAccess.map((elm) => elm.isAllowed);
                  if (
                    !pageAccessFalseArr.includes(true) &&
                    data[projectName][siteName] == undefined
                  ) {
                    if (analytics_page !== null) {
                      data[projectName][siteName] = analytics_page;
                    } else {
                      data[projectName][siteName] = "/RestrictedPage";
                    }
                  }
                  }
                }
              }

            });
          }
        }
      });
    }
    if (typeof data[project] != 'undefined') {
      if (typeof data[project][site] != 'undefined' && current_page != "/RestrictedPage") {
        data[project][site] = current_page;
      } else {
        if (current_page != "/RestrictedPage")
          data[project][site] = current_page;
      }
    } else {
      data[project] = {};
      if (current_page != "/RestrictedPage")
        data[project][site] = current_page;
    }
    //console.log(JSON.stringify(selectedPage))

    sessionStorage.setItem('selectedPage', JSON.stringify(selectedPage));
  }

  componentDidMount() {

  }

  updateSidebar = () => {
    let selectedProject = sessionStorage.getItem('selectedProject');
    let selectedSite = sessionStorage.getItem('selectedSite');
    let projects = sessionStorage.getItem('UserDetails')
      ? JSON.parse(sessionStorage.getItem('UserDetails')).data.User.rights
        .Projects
      : null;
    // console.log('projects', projects);
    // console.log('selectedSite', selectedSite);
    let userDetails = projects
      ? projects.find((prj) => prj.ProjectName === selectedProject)
      : null;
    // console.log('user Details', userDetails);
    let site_accessRights = userDetails ? userDetails.Page_access : null;
    // console.log('site_accessRights ', site_accessRights);
    // console.log('selectedSite state ', selectedSite);
    if (selectedSite == null || selectedSite === 'undefined') {
      selectedSite = sessionStorage.getItem('selectedSite');

    }
    // console.log('selectedSite', selectedSite);

    let pageAccessObj = site_accessRights ? site_accessRights.find((site_accessObject) => site_accessObject.sitename === selectedSite) : null;
    let pageAccess = [...this.state.pageAccess];
    for (let access in pageAccess) {
      pageAccess[access].isAllowed = false;
    }
    this.setState({ analytics: [] });
    var analytics_menu = [];
    // 
    this.setState({ pageAccessLength: 0 })
    var menuCodesList = pageAccess_menu.menuList.map((m)=>m.menuCode)
    for (let prop in pageAccessObj) {
      if (menuCodesList.includes(prop) && "menus" in userDetails.sites[sessionStorage.getItem('selectedSiteIndex')]) {
        var menuList = userDetails.sites[sessionStorage.getItem('selectedSiteIndex')].menus
        for (let list in menuList) {
          if (pageAccessObj[menuList[list].code] === true || pageAccessObj[menuList[list].code] === "true") {
            this.setState({ showAnalytics: true });
            analytics_menu[list]=menuList[list]
            if("submenus" in menuList[list]){
              analytics_menu[list]["pages"] = userDetails.sites[sessionStorage.getItem('selectedSiteIndex')].menus[list].submenus
              sessionStorage.setItem('reportDisplayName' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex, this.props.location.displayName);
              sessionStorage.setItem('reportUrl' + sessionStorage.selectedProjectIndex + sessionStorage.selectedSiteIndex, this.props.location.reportUrl);
            }
            this.setState({ pageAccessLength: menuList.length })
          }
        }
        this.setState({ analytics: [...analytics_menu] })

      }
      pageAccess = pageAccess.map((elm) => {
        if (elm.key === prop) {
          const updatedElm = { ...elm, isAllowed: pageAccessObj[prop] };
          if (pageAccessObj[prop] == true || pageAccessObj[prop] == "true") {
            this.setState({ pageAccessLength: ++this.state.pageAccessLength })
          }
          return updatedElm;
        }
        return elm;
      });
    }
    this.setState({ selectedSiteDisplayName: sessionStorage.getItem('selectedSiteDisplayName') })
    this.setState({ pageAccess });
    // localStorage.setItem('rioSession', JSON.stringify(sessionStorage));
  };

  render() {
    const test_val = this.state.isClicked ? 'AUTOMATION TESTING' : 'AUTO';
    const site_selection = this.state.isClicked ? 'CURRENT SITE' : 'SITE';
    let session = getUserSession.getItem('email');
    // 
    let current_page = this.props.location.pathname;

    let hideSideBar = '';
    let projects = sessionStorage.getItem('UserDetails')
      ? JSON.parse(sessionStorage.getItem('UserDetails')).data.User.rights
        .Projects
      : null;
    if (current_page === '/SiteSelection' || (projects && typeof projects[0] === "undefined") || this.state.pageAccessLength == 0) {
      hideSideBar = 'hide';
    }
    if (session === '' || session === null) {
      if (current_page.split("/")[1] === 'resetpassword') {
        return <Redirect to={current_page} />;
      }
      else {
        return <Redirect to="/login" />;
      }
    } else if(projects && typeof projects[0] !== "undefined" && projects[0].Page_access !== null){
      const togglefaclass = this.state.isClicked
        ? 'fa fa-angle-double-down'
        : 'fa fa-angle-double-up';
      return (

        <div className={hideSideBar + ' sidebar'} id="sidebar">
          <PageRedirector></PageRedirector>
          <button
            className="btn btn-dark togglebtn"
            onClick={this.toggleclassNames}
          >
            <i id="toggleIcon" className={togglefaclass}></i>
            <span></span>
          </button>
          <div
            className="collapsible-header dropdown-toggle togglelist"
            data-toggle="collapse"
            data-target="#siteSelect"
            href="siteSelect"
          >
            {' '}
            <span id="siteSelection_hdr">{site_selection}</span>
          </div>
          <div className="collapse show" id="siteSelect">
            <ul className="list-group">
              <li
                className="list-group-item active"
                onClick={() => {
                  this.toggleShow(this.state.isShow);
                }}
                ref={this.siteselectionnavbutton}
              >
                <LanguageIcon></LanguageIcon>
                <span>{this.state.selectedSiteDisplayName}</span>
                <Tooltip
                  title="Click here to change the site"
                  aria-label="add"
                  arrow
                  style={{ marginLeft: '10px', fontSize: '13px' }}
                  placement="right"
                >
                  <HelpIcon fontSize="small" />
                </Tooltip>
              </li>
              <div className={this.state.isShow ? 'show' : 'hide'} ref={this.siteselection}>
                <SiteSelection
                  func={this.isSelected}
                  backdropclick={this.closeSiteSelection}
                  updateProjectSelection={this.updateProjectSelection}
                ></SiteSelection>
              </div>
            </ul>
          </div>
          <div>
            {this.state.pageAccessLength != 0 && this.state.pageAccess.map((page)=>page.isAllowed).includes(true) ? <div
              className="collapsible-header dropdown-toggle togglelist"
              data-toggle="collapse"
              data-target="#myNavbar"
              href="myNavbar"
            >
              {' '}
              <span id="Test_Engine">{test_val}</span>
            </div> : ""}

            <div className="collapse show" id="myNavbar">
              <ul className="list-group">
                {this.state.pageAccess &&
                  this.state.pageAccess.map((page) =>
                    page.isAllowed ? (
                      <NavLink
                        className="nav-link"
                        exact
                        activeClassName="active"
                        to={`${page.path}`}
                      >
                        <li className="list-group-item">
                          {page.icon.map((icon) => icon)}
                          <span>{page.pageName}</span>
                        </li>
                      </NavLink>
                    ) : null
                  )}
              </ul>
            </div>
          </div>
          {/* <div className="collapsible-header dropdown-toggle togglelist" data-toggle="collapse" data-target="#myNavbar2" href="myNavbar2"> <span>{'ANALYTICS'}</span></div>  */}
          <div className="collapse" id="myNavbar2">
            <ul className="list-group">
              <NavLink
                className="nav-link"
                exact
                activeClassName="active"
                to="/AANALYTICS"
              >
                <li className="list-group-item">
                  <AdjustIcon></AdjustIcon>
                  <span>Automation Analytics</span>
                </li>
              </NavLink>

              <NavLink
                className="nav-link"
                exact
                activeClassName="active"
                to="/MANALYTICS"
              >
                <li className="list-group-item">
                  <AssessmentIcon></AssessmentIcon>
                  <span>Manual Analytics</span>
                </li>
              </NavLink>
            </ul>
          </div>
          {
            this.state.showAnalytics ?
              this.state.analytics &&
              this.state.analytics.map((page) =>
                // <div>{page.displayName}</div>
                <div>
                  <div
                    className="collapsible-header dropdown-toggle togglelist"
                    data-toggle="collapse"
                    data-target={"#" + page.code}
                    href={page.code}
                  >
                    <span id={page.code} className="analytics-head">{this.state.isClicked ? page.displayName : page.shortDisplayName}</span>
                  </div>
                  <div className={(current_page.search('analytics') != "-1" && current_page.split('/')[2].split("+")[0] == page.code) ? "collapse show" : "collapse"} id={page.code} >
                    <ul className="list-group">
                      {page.pages && page.pages.map((list) =>
                        <NavLink
                          className="nav-link"
                          exact
                          activeClassName="active"
                          to={{
                            pathname: page.code !=="apps" ?
                            "/analytics/" + this.state.selectedProject + '+' + this.state.selectedSite + '+'  + page.code + '+' + list.submenuCode
                            :
                            "/"+list.submenuCode + "/"+this.state.selectedProject + '+' + this.state.selectedSite + '+' + page.code ,
                            reportUrl: list.reportURL,
                            displayName: list.displayName
                          }}
                        >
                          <li className="list-group-item">
                            <img src={list.logo} className="logo"></img>
                            <span>{list.displayName}</span>
                          </li>
                        </NavLink>
                      )}
                    </ul>
                  </div>
                </div>
              )
              : ""
          }

          {/* <div
            className="collapsible-header dropdown-toggle togglelist"
            data-toggle="collapse"
            data-target="#myNavbar"
            href="myNavbar"
          >
            {' '}
            <span id="Test_Engine">ANALYTICS</span>
          </div> */}
        </div>

      );
    }
    else return null
  }
}

export default withRouter(Sidebar);
