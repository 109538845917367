// import React from 'react';

var UserSession = (function() {
    // function getItem(item){
    //     return sessionStorage.getItem(item);
    // }
  })();
  var getUserSession = (function() {
    function getItem(item){
        return sessionStorage.getItem(item);
    }
    return {
        getItem: getItem
    }
  })();
  var setUserSession = (function() {
    function setItem(item, value){
        return sessionStorage.setItem(item, value);
    }
    return {
        setItem: setItem
    }
  })();
  var removeUserSession = (function() {
    function removeItem(item, value){
        return sessionStorage.removeItem(item);
    }
    return {
        removeItem: removeItem
    }
  })();
export default UserSession;
export {getUserSession, setUserSession, removeUserSession}