import React, { Component } from 'react'
import { Buffer } from 'buffer'
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Accordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';
import TabUIGeneric from './TabUIGeneric';
import Moment from 'react-moment';
import Button from '@material-ui/core/Button';
import './Results.css';
import Box from '@material-ui/core/Box';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import ViewListIcon from '@material-ui/icons/ViewList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReactPlayer from 'react-player'
import DevicesIcon from '@material-ui/icons/Devices';
import SubjectIcon from '@material-ui/icons/Subject';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CloudDownload from '@material-ui/icons/CloudDownload';
import ApiRequest from "./ApiRequest";
import ApiResponse from "./ApiResponse";
import ApiAssertion from "./ApiAssertion";
import SettingsIcon from '@material-ui/icons/Settings';
const useStyles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: "4px 8px",
        textAlign: 'left',
        color: "#000",
        fontSize: '0.9em !important',
        textTransform: 'capitalize',
        background: "#d6dde3",
        border: "none",
        boxShadow: "none",
        display: "inline-block",
        marginBottom: "3px",
        isAccordianopen: false,
    },
    paperspan: {
        fontWeight: '500',
    },
    alignLeft: {
        textAlign: 'left'
    },
    tabhead: {
        textAlign: 'left',
        backgroundColor: '#ffffff'
    },
    listheading: {
        padding: '5px 16px',
        textAlign: 'left',
        fontWeight: '500'
    },
    PASS: {
        "background-color": "rgb(0, 158, 235)",
        color: "#fff",
        padding: "0px 10px",
        display: 'inline-block',
        borderRadius: "4px"
    },
    FAIL: {
        "background-color": "rgb(139, 0, 0)",
        color: "#fff",
        padding: "0px 10px",
        display: 'inline-block',
        borderRadius: "4px"
    },
    ERROR: {
        "background-color": "rgb(0, 60, 91)",
        color: "#fff",
        padding: "0px 10px",
        display: 'inline-block',
        borderRadius: "4px"
    },
    Accordion: {
        "border": "1px solid #fff",
        '&:hover': {
            "border": "1px solid rgb(0, 158, 235)",
        },
    },
    graytext: {
        color: "#817e7e"
    }
});
const AccordionSummary = withStyles({
    content: {
        display: "inline-block",
        textAlign: 'left',
        //   flexGrow: 0,

    },

})(MuiAccordionSummary);
class ResultDetails extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: "",
            detail: [],
            panel: "panel",
            currentPage: 1,
            perPage: 10,
            pageNumbers: 0,
            tabvalue: 0,
            tabindexvalue: 0,
            filtervalue: "ALL",
            passcount: 0,
            failcount: 0,
            errorcount: 0,
            totalcount: 0
        }
        this.accordianRef = React.createRef(null);
        this.handleChange = this.handleChange.bind(this)
        window.addEventListener("mousedown", (e) => this.checkIfClickedOutside(e));
        this.checkIfClickedOutside = this.checkIfClickedOutside.bind(this);
    }
    fromBase64(encoded) {
        return Buffer.from(encoded, 'base64').toString('utf8')
    }
    checkIfClickedOutside(e) {
        let ref = this.accordianRef;
        let isAccordianOpen = this.state.panel;
        if (isAccordianOpen && ref.current && !ref.current.contains(e.target)) {
            this.setState({ panel: false })
        }
    }
    componentDidMount() {
        this.setState({ data: (typeof this.props.resultdata != "undefined") ? this.props.resultdata : [], testType: this.props.testType }, () => {
            this.setState({
                passcount: this.state.data.pass_count,
                failcount: this.state.data.fail_count,
                errorcount: this.state.data.error_count,
                totalcount: this.state.data.totalcount,
            })
            this.ResultDetails(1, "ALL");

        });

    }
    ResultDetails(offset, status) {
        let limit = 10;
        var site = sessionStorage.getItem('selectedSite');
        let one = window.configs.TEST_DETAIL_URL +
            site + '/' +
            this.state.data.executionId +
            '?limit=' + limit + '&offset=' + offset + '&status=' + status;
        const requestone = axios.get(one);
        requestone.then((res) => {
            this.setState({
                detail: res.data.data,
                pageNumbers: Math.ceil(res.data.totalCount / 10),
                currentPage: offset
            });
        });
    }
    handleAccordianChange = (panel) => (event, newExpanded) => {
        this.setState({ panel: (newExpanded ? panel : false) });
    };

    handleChange(event, value) {
        // console.log(value);
        let filterstatus = this.state.filtervalue;
        this.ResultDetails(value, filterstatus)
        this.setState({
            currentPage: Number(typeof event.target.innerText != "undefined" ? event.target.innerText : value),
        });
    }
    handleFilter(value) {
        let currentPage = 1;
        this.setState({ filtervalue: value, currentPage: currentPage });
        this.ResultDetails(currentPage, value);
    }
    render() {
        const { classes } = this.props;
        const resultList = this.state.data;
        const filtervalue = this.state.filtervalue;
        const resultDetails = this.state.detail;
        const expanded = this.state.panel;
        const graphdata = this.props.graphdata;
        function FormRow() {
            return (
                <React.Fragment>
                    <Grid item xs={4}>
                        <Paper className={classes.paper}>item</Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paper}>item</Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper className={classes.paper}>item</Paper>
                    </Grid>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {/* <div className="card  custcard">
                    <div className="cord-body">
                        <div className="testHeading">
                            <label className="headermsg1">Result Details</label>
                        </div>
                    </div>
                </div> */}
                <div className="  ">
                    <div className="cord-body">
                        <div className={classes.root}>
                            <Box p={2}>
                                <Grid container spacing={1}>
                                    <Grid container item xs={12} spacing={1}>
                                        <Grid item xs={12}>
                                            {graphdata}
                                        </Grid>
                                    </Grid>
                                    <Grid container item xs={12} spacing={1} className={classes.alignLeft}>
                                        <Grid item xs={3} >
                                            <Grid item xs={12}>
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Execution date: </span>{resultList.execution_date}</Paper>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Execution start time: </span>{resultList.execution_start_time}</Paper>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Execution end time: </span>{resultList.executionEndTime}</Paper>
                                            </Grid>
                                            <Grid item xs={12} >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Total execution time: </span>{resultList.execution_time}</Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>User: </span>{resultList.user_name}</Paper>
                                            </Grid>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Environment: </span>{resultList.environment}</Paper>
                                            </Grid>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Suite: </span>{resultList.testsuitname}</Paper>
                                            </Grid>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Count: </span>{resultList.totalcount}</Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3} >
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Operating system: </span>{resultList.os_name}</Paper>
                                            </Grid>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Browser: </span>{resultList.browser}</Paper>
                                            </Grid>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Form factor: </span>{resultList.formFactor}</Paper>
                                            </Grid>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Device: </span>{(resultList.device == "None") ? "Desktop" : resultList.device}</Paper>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Orientation: </span>{resultList.deviceOrientation == "" ? "Landscape" : resultList.deviceOrientation}</Paper>
                                            </Grid>

                                            <Grid item >
                                                <Paper className={classes.paper}><span className={classes.paperspan}>Status: </span>{resultList.status}</Paper>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </div>
                    </div>
                </div>
                <div className="   resultsDetails">
                    <div className="cord-body">
                        <div className={classes.root}>
                            <br></br>
                            <Grid container spacing={1}>
                                <Grid item xs={12}>
                                    <Grid container spacing={1} className={classes.listheading}>
                                        <Grid item>
                                            <Button variant="outlined" className={this.state.filtervalue == "ALL" ? "filter-buttons all active" : "filter-buttons all"} onClick={(e) => this.handleFilter("ALL")}><ViewListIcon></ViewListIcon> All {this.state.totalcount}</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" className={this.state.filtervalue == "PASS" ? "filter-buttons pass active" : "filter-buttons pass"} onClick={(e) => this.handleFilter("PASS")}><CheckCircleIcon></CheckCircleIcon>  Passed  {this.state.passcount}</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" className={this.state.filtervalue == "FAIL" ? "filter-buttons fail active" : "filter-buttons fail"} onClick={(e) => this.handleFilter("FAIL")}><WarningIcon></WarningIcon> Failed  {this.state.failcount}</Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="outlined" className={this.state.filtervalue == "ERROR" ? "filter-buttons error active" : "filter-buttons error"} onClick={(e) => this.handleFilter("ERROR")}><ErrorIcon></ErrorIcon> Error  {this.state.errorcount}</Button>
                                        </Grid>
                                    </Grid>
                                    <br></br>
                                    <Grid container spacing={1} className={classes.listheading}>
                                        <Grid item xs={12} style={{"paddingRight":"40px"}}>
                                            <Grid container spacing={1} className={classes.graytext}>
                                                <Grid item xs={5} xl={5}>
                                                    <span>Test Name</span>
                                                </Grid>
                                                <Grid item xs={2} xl={1}>
                                                    <span>Status</span>
                                                </Grid>
                                                <Grid item xs={2} xl={1}>
                                                    <span>Duration</span>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} className={classes.listheading}>
                                        <Grid item xs={12} ref={this.accordianRef}>
                                            {resultDetails.map((result, index) => (

                                                <Accordion key={index} square expanded={expanded === 'panel' + index} onChange={this.handleAccordianChange('panel' + index)} className={classes.Accordion}>
                                                    <AccordionSummary expandIcon={<ExpandMoreIcon />} elevation={2} aria-controls={"panel" + index + "d-content"} id={"panel" + index + "d-header"} className={classes.AccordionSummary}>

                                                        <Grid container spacing={1} className={classes.graytext}>
                                                            <Grid item xs={5} xl={5}>
                                                                <span style={{ color: "#007bff", wordBreak: 'break-all' }}>{result.testName}</span>
                                                            </Grid>
                                                            <Grid item xs={2} xl={1}>
                                                                <span className={classes[result.testStatus]}>{result.testStatus}</span>
                                                            </Grid>
                                                            <Grid item xs={2} xl={1}>
                                                                <span>{result.duration}</span>
                                                            </Grid>

                                                        </Grid>
                                                    </AccordionSummary>
                                                    <AccordionDetails className='custom-accordian-details' >
                                                        <Grid container spacing={0} >
                                                            <Grid item xs={10}>
                                                                {this.state.testType == "api" ?
                                                                    <TabUIGeneric
                                                                        tabcount={[0, 1, 2, 3, 4]}
                                                                        tablabel={["Steps", "Stacktrace", "Request : " + result.apiRequest.requestMethod, "Response : " + result.apiRequest.requestMethod, "Assertion (" + result.apiResponse.apiAssertions.passed + "/" + result.apiResponse.apiAssertions.total + ")"]}
                                                                        tabicon={[<DevicesIcon></DevicesIcon>, <SubjectIcon></SubjectIcon>, <CloudUploadIcon></CloudUploadIcon>, <CloudDownload></CloudDownload>, <SettingsIcon></SettingsIcon>]}
                                                                        tabpanel={[
                                                                            <div >
                                                                                <Grid container spacing={1} className="steps-header">
                                                                                    <Grid item xs={2}>
                                                                                        <span className='timeofactivity'>Time</span>
                                                                                        <span>Status</span>
                                                                                    </Grid>
                                                                                    <Grid item xs={9}>
                                                                                        <span>Description</span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <div>
                                                                                    {result.steps.map((item, key) =>

                                                                                        <Grid container key={key} spacing={1} className="white-bg">
                                                                                            <Grid item xs={2}>
                                                                                                {<Moment className='timeofactivity' format='HH:mm:ss'>{item.stepStartDateTime}</Moment>}
                                                                                                <span className={'stepstatus ' + item.stepStatus}> {item.stepStatus}</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={9}>
                                                                                                {item.stepName}
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    )}
                                                                                </div>
                                                                            </div>,
                                                                            result.exceptionInfo.stackTrace,
                                                                            <ApiRequest apirequest={result.apiRequest} apiresponse={result.apiResponse}></ApiRequest>,
                                                                            <ApiResponse apirequest={result.apiRequest} apiresponse={result.apiResponse}></ApiResponse>,
                                                                            <ApiAssertion apirequest={result.apiRequest} apiresponse={result.apiResponse}></ApiAssertion>

                                                                        ]}

                                                                        className={classes.tabhead}
                                                                        customStyle={{ "-webkit-mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)", "mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)" }}
                                                                    ></TabUIGeneric>
                                                                    :
                                                                    <TabUIGeneric
                                                                        tabcount={[0, 1]}
                                                                        tablabel={["Steps", "Stacktrace"]}
                                                                        tabicon={[<DevicesIcon></DevicesIcon>, <SubjectIcon></SubjectIcon>]}
                                                                        tabpanel={[
                                                                            <div >
                                                                                <Grid container spacing={1} className="steps-header">
                                                                                    <Grid item xs={2}>
                                                                                        <span className='timeofactivity'>Time</span>
                                                                                        <span>Status</span>
                                                                                    </Grid>
                                                                                    <Grid item xs={9}>
                                                                                        <span>Description</span>
                                                                                    </Grid>
                                                                                </Grid>
                                                                                <div>
                                                                                    {result.steps.map((item, key) =>

                                                                                        <Grid container key={key} spacing={1} className="white-bg">
                                                                                            <Grid item xs={2}>
                                                                                                {<Moment className='timeofactivity' format='HH:mm:ss'>{item.stepStartDateTime}</Moment>}
                                                                                                <span className={'stepstatus ' + item.stepStatus}> {item.stepStatus}</span>
                                                                                            </Grid>
                                                                                            <Grid item xs={9}>
                                                                                                {item.stepName}
                                                                                            </Grid>
                                                                                        </Grid>

                                                                                    )}
                                                                                </div>
                                                                            </div>,
                                                                            result.exceptionInfo.stackTrace

                                                                        ]}

                                                                        className={classes.tabhead}
                                                                        customStyle={{ "-webkit-mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)", "mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)" }}
                                                                    ></TabUIGeneric>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                {(result.sessionInfo.videoUrl == null || result.sessionInfo.videoUrl == "") ?
                                                                    ""
                                                                    :
                                                                    <ReactPlayer controls="true" width="auto" style={{ maxWidth: "100%", textAlign: "right" }} height="400px" url={result.sessionInfo.videoUrl} />
                                                                }

                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>

                                            ))}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="pagination_r">
                    <Pagination
                        count={this.state.pageNumbers}
                        page={this.state.currentPage}
                        onChange={this.handleChange}
                        color="primary"
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default withStyles(useStyles)(ResultDetails)