import React, { useState } from "react";
import "../TestExecution.css";
import captions from "../../config/captions.json";
import {
  checkNameField,
  checkIdField,
  checkValidNonEmptyValues,
  checkNonEmptyValues,
} from "../checkFields";
import axios from "axios";
import { UIButton, UITextField } from "../UIComponents";

import Checkbox from "@mui/material/Checkbox";

function AdminAddProject() {
  const [projName, setProjName] = useState("");
  const [projID, setProjID] = useState("");
  const [projActive, setProjActive] = useState(true);
  const [msg, setMsg] = useState("");
  const [nameValid, setNameValid] = useState(true);
  const [idValid, setIdValid] = useState(true);
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })
  const [adminRights, ] = useState(sessionStorage.getItem("UserDetails") && "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role === "admin" ? true : false)

  function setInitialValues() {
    setProjName("");
    setProjID("");
    setProjActive(true);
  }

  function reset() {
    setInitialValues();
    setMsg("");
    setNameValid(true);
    setIdValid(true);
  }

  function addProject() {
    if (
      checkValidNonEmptyValues([nameValid, idValid], [projName, projID]) &&
      msg == ""
    ) {
      var data = {
        ProjectName: projName.trim(),
        projectId: projID.trim(),
        active: projActive,
      };
      //api call for adding project - projId
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;

      axios
        .post(
          url + "/createProject" ,

          data,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status == "success") {
            setInitialValues();
            setMsg(captions.message.PROJECT_ADD_SUCCESS);
          } else {
            setMsg(captions.error.PROJECT_ADD_INTERNAL_ERROR);
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status == 409) {
              setMsg(captions.error.PROJECT_ADD_DUPLICATE);
            } else if (error.response.status == 400) {
              setMsg(captions.error.ADD_VALIDATION);
            } else if (error.response.status == 401) {
              setMsg(captions.error.PROJECT_UNAUTHORIZED);
            } else if (error.response.status == 500) {
              setMsg(captions.error.PROJECT_ADD_INTERNAL_ERROR);
            }
          } else {
            setMsg(captions.error.INTERNAL_ERROR);
            setInitialValues();
          }
        });
    } else {
      setMsg(captions.error.FIELDS_EMPTY);
    }
  }
  return (
    <React.Fragment>
      <div className="card  custcard">
        <div className="cord-body">
          <div className="testHeading">
            <label className="headermsg">{adminRights ? "Add Project" : "Your access is denied"}</label>
          </div>
        </div>
      </div>

      {
        adminRights ? 
        <div className="card  custcard">
          <div className="m-3">
            <div className="ml-5">
              <div className="d-flex mt-4 ml-5">
                <span className="title-prompts mt-2">Project Name</span>
                <UITextField
                  value={projName}
                  width={300}
                  placeholder="Please enter project name"
                  onChange={(e) => {
                    setMsg("");
                    checkNameField(e.target.value)
                      ? setNameValid(true)
                      : setNameValid(false);
                    setProjName(e.target.value);
                  }}
                  helperText={
                    !nameValid && projName !== ""
                      ? captions.validation.PROJECT_NAME
                      : ""
                  }
                  error={!nameValid && projName !== ""}
                />
              </div>
              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Project ID</span>
                <UITextField
                  value={projID}
                  width={300}
                  placeholder="Please enter project id"
                  onChange={(e) => {
                    setMsg("");
                    checkIdField(e.target.value)
                      ? setIdValid(true)
                      : setIdValid(false);
                    setProjID(e.target.value);
                  }}
                  helperText={
                    !idValid && projID !== ""
                      ? captions.validation.PROJECT_ID
                      : ""
                  }
                  error={!idValid && projID !== ""}
                />
              </div>
              <div className="d-flex mt-3 ml-5">
                <span className="title-prompts mt-2">Project Active</span>

                <Checkbox
                  id="active"
                  color={"primary"}
                  checked={projActive}
                  onChange={(e) => {
                    e.target.checked ? setProjActive(true) : setProjActive(false);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <div className="col-sm-8 btn-group cp-btn-group mr-5 mt-5 mb-3 justify-content-end">
                <div className="mr-3">
                  <UIButton
                    variant="contained"
                    onClick={() => {
                      addProject();
                    }}
                    disabled={
                      checkValidNonEmptyValues(
                        [nameValid, idValid],
                        [projName, projID]
                      )
                        ? false
                        : true
                    }
                    title={"Add Project"}
                  />
                </div>

                <div className="mr-3">
                  <UIButton
                    variant="contained"
                    onClick={() => {
                      reset();
                    }}
                    disabled={
                      checkNonEmptyValues([projName, projID]) ? false : true
                    }
                    title={"Cancel"}
                  />
                </div>
              </div>
            </div>
            <div className="row mr-5">
              <div className="col d-flex justify-content-end">
                <span className="col-sm-8 btn-group cp-btn-group justify-content-end">
                  {msg}
                </span>
              </div>
            </div>
          </div>
        </div>
        :
        null
      }
    </React.Fragment>
  );
}
export default AdminAddProject;
