import React, { Component } from 'react'
import TabUIGeneric from './TabUIGeneric';
import Grid from '@material-ui/core/Grid';
import Highlight from 'react-highlight'
import Switch from '@material-ui/core/Switch';

class ApiRequest extends Component {
    constructor(props) {
        super(props)
        this.state = {
            panel: "panel",
            req_switched: false,
            res_switched: false,
        }
    }
    handleChangereqSwitch() {
        this.setState((prevstate) => ({
            req_switched: !prevstate.req_switched
        }));
    }
    handleChangeresSwitch() {
        this.setState((prevstate) => ({
            res_switched: !prevstate.res_switched
        }));
    }
    render() {
        let { apirequest, classes } = this.props;
        return (
            <Grid container spacing={0} >
                <Grid item xs={12} >
                    {
                        apirequest &&
                        <Grid container spacing={0} >
                            <Grid item xs={12}>
                                <Grid container spacing={1} className={"graytext"} style={{ "padding": "10px" }}>
                                    <Grid item xs={12}>
                                        <span className="requestURL">URL : {apirequest.requestURL}</span>
                                    </Grid>
                                </Grid>
                                <br></br>
                                <TabUIGeneric
                                    tabcount={[0, 1, 2]}
                                    tablabel={["Body", "Headers (" + Object.keys(apirequest.headers).length + ")", "Cookies"]}
                                    tabicon={[]}
                                    tabpanel={[
                                        <div>
                                            Pretty :
                                            <Switch
                                                checked={this.state.req_switched}
                                                onChange={(e) => this.handleChangereqSwitch()}
                                                color="primary"
                                                name="checkedA"
                                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                            <Highlight innerHTML={!this.state.req_switched}>
                                                {apirequest.body}
                                            </Highlight>
                                        </div>,
                                        <div>
                                            {Object.keys(apirequest.headers).map((item, key) => {
                                                return <div><span>{item + " : "}</span><span>{apirequest.headers[item]}</span></div>
                                            })}
                                        </div>,
                                        <div>{JSON.stringify(apirequest.cookies)}</div>
                                    ]}
                                    customStyle={{ "-webkit-mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)", "mask-image": "linear-gradient(black, black 20%, black 80%, transparent 100%)" }}
                                ></TabUIGeneric>
                            </Grid>
                        </Grid>

                    }
                </Grid>
            </Grid>
        )
    }
}

export default ApiRequest
