import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import {Link}  from "react-router-dom";


class AdminPage extends Component {

  renderButtonComponent = (link, title)=>(
      <Button
          variant="contained"
          color="primary"
          style={{ backgroundColor: "#fff", margin:"10px" }}
      >
          <Link to={link} style={{ color: "2491cf" }}>{title}</Link>
      </Button>
  )
  render() {
    let adminRights = sessionStorage.getItem("UserDetails") && "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role === "admin" ? true : false;
    return (
      <React.Fragment>
        <Grid
          container
          justifyContent="center"
          alignContent="center"
          item
          style={{ height: "85%"}}
        >
          <Grid item>
            <div className="card-body" style={{ background: "#2491cf" }}>
              <br />
              <h4 style={{ color: "#fff" }}>{adminRights ? "ADMIN PAGE" : "Your access is denied"}</h4>
              <br />
              {adminRights ? 
                <div className="card-content">
                <div className="form-field flex-row btn-wrapper">

                  {this.renderButtonComponent("/admin-add-user","Add User")}
                  {this.renderButtonComponent("/admin-edit-user","Edit User")}

                </div>
                <br />
                <br />
                <div className="form-field flex-row btn-wrapper">

                  {this.renderButtonComponent("/admin-add-project","Add Project")}
                  {this.renderButtonComponent("/admin-edit-project","Edit Project")}
                  
                </div>
                <br /> <br />
                <Link to="/" className="forgot-password">Go to Home</Link>
                <br />
                </div>
                : null
              } 
            </div>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default AdminPage;
