import React, { Component } from 'react'
// import GoogleLogin, { GoogleLogout } from '../dist/google-login'
// import GLogin from './GoogleLogin';
import CustomeLogin from './CustomeLogin'

class Login extends Component {
    render() {
        return (
            <React.Fragment>
                <center>
                <CustomeLogin/>
                </center>
          </React.Fragment>
        )
    }
}

export default Login