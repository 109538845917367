export const timezoneList = [
  "America/New_York EDT",
  "America/Chicago EDT",
  "America/Phoenix EDT",
  "America/North_Dakota/Center EDT",
  "America/Toronto EDT",
  "America/Montreal EDT",
  "America/Denver MDT",
];

export const seleniumVersionList = ["3", "4"];

export const threadCntList = ["3", "4"];
export const partnerCntList = ["3", "4"];

export const testTypeList = ["UI", "API", "APP"];

export const cloudTypeList = [
  "CHCLOUD",
  "SAUCELABS",
  "ZALENIUM",
  "LAMBDATEST",
  "BROWSERSTACK",
];

export const formFactorsList = ["Desktop", "Mobile", "Tablet"];

export const desktopOSList = ["Windows", "Mac", "Linux"];

export const mobTabOSList = ["Android", "Ios"];

export const windowsBrowserList = ["Chrome", "Firefox", "Edge"];

export const macBrowserList = ["Safari", "Chrome", "Edge"];
export const linBrowserList = ["Chrome", "Firefox", "Edge"];
