import React, { Component } from "react";

import "./EditProject.css";

import {
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
} from "../UIComponents";

import Typography from "@mui/material/Typography";

import EditSiteTestDetails from "./EditSiteTestDetails";
import EditSiteMenu from "./EditSiteMenu";
import EditSiteBasicDetails from "./EditSiteBasicDetails";

class EditSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //expanded: "false",
      projectId: this.props.projId,
      siteId: this.props.siteId,
      siteData: this.props.siteData,
      projectEdit: false,
      projectCancel: false,
      projectApply: false,

      siteIntgEdit: [],
      siteIntgCancel: [],
      siteIntgApply: [],
      siteIntgChecked: [],
      siteIFactChecked: [],
      siteIOSChecked: [],
      siteIDevChecked: [],
      siteIntg: [],
      siteIntgAddM: [],
      siteIntgAdd: [],
      siteIntgAddCancel: [],
      siteIntgItem: [],

      siteFullSiteEdit: [],
      siteFullSiteCancel: [],
      siteFullSiteApply: [],
      siteFullSuiteChecked: [],
      siteFullSuite: [],
      siteFullSuiteAddM: [],
      siteFullSuiteAdd: [],
      siteFullSuiteAddCancel: [],
      siteFullSuiteItem: [],

      siteModuleEdit: [],
      siteModuleCancel: [],
      siteModuleApply: [],
      siteModuleChecked: [],
      siteModule: [],
      siteModuleAddM: [],
      siteModuleAdd: [],
      siteModuleAddCancel: [],
      siteModuleItem: [],

      siteEnvEdit: [],
      siteEnvCancel: [],
      siteEnvApply: [],
      siteEnvChecked: [],
      siteEnv: [],
      siteEnvAddM: [],
      siteEnvAdd: [],
      siteEnvAddCancel: [],
      siteEnvItem: [],
    };
  }

  handleChange = (panel) => (event, expandedState) => {};

  render() {
    return (
      <React.Fragment>
        <UIAccordion expanded={true}>
          <UIAccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            style={{
              cursor: "default",
              backgroundColor: "#343a40",
              color: "#fff",
            }}
          >
            <div className="row">
              <div className="col  text-left d-flex flex-row">
                <div className="d-flex flex-row">
                  <Typography>Project ID: </Typography>&nbsp;
                  <Typography>{this.state.projectId}</Typography>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <div className="d-flex flex-row">
                  <Typography>Site ID: </Typography>&nbsp;
                  <Typography>{this.state.siteId}</Typography>
                </div>
              </div>
            </div>
          </UIAccordionSummary>
          <UIAccordionDetails style={{ backgroundColor: "#fff" }}>
            <EditSiteBasicDetails
              projId={this.state.projectId}
              siteId={this.state.siteId}
              siteData={this.state.siteData}
            />

            <EditSiteTestDetails
              projId={this.state.projectId}
              siteId={this.state.siteId}
              siteData={this.state.siteData}
            />

            <EditSiteMenu
              projectId={this.state.projectId}
              siteData={this.state.siteData}
            />
          </UIAccordionDetails>
        </UIAccordion>
      </React.Fragment>
    );
  }
}

export default EditSite;
