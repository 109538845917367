import {
  validProjectName,
  validProjectId,
  validAlphaNumKeys,
  validWholeNumR010,
  validWholeNum,
  validShortName,
  validLogoUrl,
  validUserName,
  validEmail,
  validAlphaNumName
} from "./regex";

import isUrlHttp from "is-url-http";

export const checkNameField = (val) => {
  if (validProjectName.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const checkAlphaNumNameField = (val) => {
  if (validAlphaNumName.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const checkLogoUrl = (val) => {
  if (validLogoUrl.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const checkHttpUrl = (val) => {
  if (isUrlHttp(val)) {
    return true;
  } else {
    return false;
  }
};

export const checkShortNameField = (val) => {
  if (validShortName.test(val)) {
    return true;
  } else {
    return false;
  }
};

export const blockInvalidChar = (e) =>
  ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();

export const checkNumField = (val) => {
  if (val.length === 0) {
    return true;
  } else {
    if (validWholeNumR010.test(val)) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkNumFieldWithoutRange = (val) => {
  if (val.length === 0) {
    return true;
  } else {
    if (validWholeNum.test(val)) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkAlphaNumField = (val) => {
  if (val.length === 0) {
    return true;
  } else {
    if (validAlphaNumKeys.test(val)) {
      return true;
    } else {
      return false;
    }
  }
};

export const checkIdField = (val) => {
  if (validProjectId.test(val)) {
    return true;
  } else {
    return false;
  }
};
export const checkValidNonEmptyValues = (validKeys, fieldValues) => {
  if (!validKeys.includes(false) && !fieldValues.includes("")) {
    return true;
  } else {
    return false;
  }
};

export const checkNonEmptyValues = (fieldValues) => {
  return fieldValues.some((el) => el !== "");
};

export const checkEmptyValues = (fieldValues) => {
  if (fieldValues.includes("")) {
    return true;
  } else {
    return false;
  }
};

export const checkUserName = (val)=>{
  if (validUserName.test(val)) {
    return true;
  } else {
    return false;
  }
}

export const checkEmail = (val)=>{
  if (validEmail.test(val)) {
    return true;
  } else {
    return false;
  }
}
