export const validProjectName = new RegExp("^[a-zA-Z]+[a-zA-Z- ]{2,}[a-zA-Z]$");
export const validProjectId = new RegExp("^[a-zA-Z]+[a-zA-Z-]{2,}[a-zA-Z]$");
export const validAlphaNumKeys = new RegExp(
  "^[a-zA-Z]+[a-zA-Z0-9]{4,}[a-zA-Z0-9]$"
);
export const validAlphaNumName = new RegExp("^[a-zA-Z]+[a-zA-Z0-9- ]{2,}[a-zA-Z0-9]$");
//positive integer for range 0-20
export const validWholeNumR020 = new RegExp("^([0-9]|1[0-9]|20)$");

//positive integer for range 0-10
export const validWholeNumR010 = new RegExp("^([0-9]|1[0]|10)$");

export const validWholeNum = new RegExp("^[0-9]*");

export const validShortName = new RegExp("^[a-zA-Z]{2,4}$");

export const validLogoUrl = new RegExp("^https?://.+/.+$");

export const validUserName = new RegExp("^[a-zA-Z]+$")

export const validEmail = new RegExp("^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$")
