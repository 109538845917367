import React, { Component } from 'react'

class ApiReport extends Component {
    render() {
        return (
            <div>
                <h1>API REPORT</h1>
            </div>
        )
    }
}

export default ApiReport
