import React, { Component } from 'react';
import {Chart} from 'chart.js';
import './Results.css';

class Resultchart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastTenId1: [],
      results1: [],
    };
  }

  chartRef = React.createRef();
  chartRef2 = React.createRef();

  componentDidMount() {
    // console.log('Props in resultChart', JSON.stringify(this.props));
    const myChartRef = this.chartRef.current.getContext('2d');
    Chart.defaults.global.datasets.bar.barPercentage = 0.25;
    Chart.defaults.global.datasets.bar.maxBarThickness = 10;
    var moduleBack_color = ['#009EEB', '#003C5B', '#8B0000'];
    new Chart(myChartRef, {
      type: 'bar',
      data: {
        labels: this.props.text.moduleObject.labels,
        datasets: [
          {
            label: 'PASS',
            data: this.props.text.moduleObject.data[0],
            backgroundColor: moduleBack_color[0],
            borderColor: moduleBack_color[0],
          },
          {
            data: this.props.text.moduleObject.data[1],
            label: 'ERROR',
            backgroundColor: moduleBack_color[1],
            borderColor: moduleBack_color[1],
          },
          {
            data: this.props.text.moduleObject.data[2],
            label: 'FAIL',
            backgroundColor: moduleBack_color[2],
            borderColor: moduleBack_color[2],
          },
        ],
      },
      options: {
        // responsive: true, // Instruct chart js to respond nicely.
        scales: {
          xAxes: [
            {
              fontSize: 16,
              stacked: true,
              ticks: {
                maxTicksLimit: 20,
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              fontSize: 16,
              ticks: {
                stepSize: 25,
              },
            },
          ],
        },
        tooltips: {
          // multiKeyBackground: 'red',
          labelColor: 'red',
          titleFontSize: 16,
          titleFontColor: '#003C5B',
          bodyFontColor: 'black',
          backgroundColor: 'white',
          color: 'black',
        },
        legend: {
          display: false,
        },
        title: {
          text: 'Module Report',
          display: true,
          fontColor: '#337AB7',
          fontSize: 16,
          fontStyle: 'normal',
        },
      },
    });

    var back_color = ['#009EEB', '#003C5B', '#8B0000']; // if 3 fields PAss, Error, Fail
    const myChartRef2 = this.chartRef2.current.getContext('2d');
    new Chart(myChartRef2, {
      type: 'bar',
      data: {
        labels: this.props.text.priorityObject.labels,
        datasets: [
          {
            label: 'PASS',
            data: this.props.text.priorityObject.data[0],
            backgroundColor: back_color[0],
            borderColor: back_color[0],
          },
          {
            label: 'ERROR',
            data: this.props.text.priorityObject.data[1],
            backgroundColor: back_color[1],
            borderColor: back_color[1],
          },
          {
            label: 'FAIL',
            data: this.props.text.priorityObject.data[2],
            backgroundColor: back_color[2],
            borderColor: back_color[2],
          },
        ],
      },
      options: {
        title: {
          text: 'Priority Report',
          display: true,
          fontColor: '#337AB7',
          fontSize: 16,
          fontStyle: 'normal',
        },
        legend: {
          display: false,
        },
        scales: {
          xAxes: [
            {
              fontSize: 16,
              stacked: true,
              ticks: {
                maxTicksLimit: 20,
                autoSkip: false,
                maxRotation: 45,
                minRotation: 45,
              },
            },
          ],
          yAxes: [
            {
              fontSize: 16,
              stacked: true,
              ticks: {
                stepSize: 10,
              },
            },
          ],
        },
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <canvas id="myChart" ref={this.chartRef}>
              {' '}
            </canvas>
          </div>
          <div className="col">
            <canvas id="myChart2" ref={this.chartRef2}>
              {' '}
            </canvas>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default Resultchart;
