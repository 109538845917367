import React, { useState } from "react";
import "../TestExecution.css";
import captions from "../../config/captions.json";
import { timezoneList, seleniumVersionList } from "../../config/siteItemList";

import {
  checkNameField,
  checkIdField,
  checkValidNonEmptyValues,
  checkNonEmptyValues,
  checkAlphaNumField,
  blockInvalidChar,
  checkNumFieldWithoutRange,
} from "../checkFields";
import axios from "axios";
import {
  UIDropDown,
  UIButton,
  UITextField,
  UINumField,
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
} from "../UIComponents";

import Typography from "@mui/material/Typography";

function AddSite(props) {
  const [sId, setSId] = useState("");
  const [sDName, setSDName] = useState("");
  const [sTimezone, setSTimezone] = useState("");
  const [sTimeShort, setSTimeShort] = useState("");
  const [sUkey, setSUkey] = useState("");
  const [sPKey, setSPKey] = useState("");
  const [sThreadCnt, setSThreadCnt] = useState("");
  const [sSelVer, setSSelVer] = useState("");
  const [sParthrAll, setSParthrAll] = useState("");

  const [msg, setMsg] = useState("");
  const [idValid, setIdValid] = useState(true);
  const [dNameValid, setDNameValid] = useState(true);
  const [sVerValid, setSVerValid] = useState(true);
  const [threadCntValid, setThreadCntValid] = useState(true);
  const [parthrAllValid, setParthrAllValid] = useState(true);
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })

  function setInitialValues() {
    setSId("");
    setSDName("");
    setSTimezone("");
    setSTimeShort("");
    setSUkey("");
    setSPKey("");
    setSThreadCnt("");
    setSSelVer("");
    setSParthrAll("");
  }

  function reset() {
    setInitialValues();
    setMsg("");
    setIdValid(true);
    setDNameValid(true);
    setSVerValid(true);

    setThreadCntValid(true);
    setParthrAllValid(true);
  }

  function addSite() {
    if (
      checkValidNonEmptyValues(
        [
          idValid,
          dNameValid,
          parthrAllValid,
          sVerValid,
        ],
        [sSelVer, sId, sDName, sTimezone, sTimeShort]
      ) &&
      msg == ""
    ) {
      var data = {
        siteid: sId.trim(),
        displayName: sDName.trim(),
        TimeZone: sTimezone.split(" ")[0].trim(),
        TimeZoneShort: sTimeShort.trim(),
        seleniumVersion: sSelVer.trim(),
      };
      if (sUkey.length !== 0) {
        data["userKey"] = sUkey.trim();
      }
      if (sPKey.length != 0) {
        data["passKey"] = sPKey.trim();
      }
      if (sThreadCnt.length !== 0) {
        data["threadCount"] = sThreadCnt.trim();
      }
      if (sParthrAll.length !== 0) {
        data["partnerThreadsAllocated"] = sParthrAll.trim();
      }

      //api call for adding project - projId
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;

      axios
        .patch(
          url + "/" + props.projId + "/addSite" ,

          data,
          {
            headers: headerData,
          }
        )
        .then((result) => {
          if (result.data.status == "success") {
            setInitialValues();
            setMsg(captions.message.SITE_ADD_SUCCESS);
          } else {
            setMsg(captions.error.SITE_ADD_INTERNAL_ERROR);
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status == 409) {
              setMsg(captions.error.SITE_ADD_DUPLICATE);
            } else if (error.response.status == 400) {
              setMsg(captions.error.ADD_VALIDATION);
            } else if (error.response.status == 404) {
              setMsg(captions.error.SITE_VALUE_ERROR);
            } else if (error.response.status == 401) {
              setMsg(captions.error.SITE_UNAUTHORIZED);
            } else if (error.response.status == 500) {
              setMsg(captions.error.SITE_ADD_INTERNAL_ERROR);
            }
          } else {
            setMsg(captions.error.INTERNAL_ERROR);
            setInitialValues();
          }
        });
    } else {
      setMsg(captions.error.FIELDS_EMPTY);
    }
  }
  return (
    <React.Fragment>
      <UIAccordion expanded={true}>
        <UIAccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{
            cursor: "default",
            backgroundColor: "#343a40",
            color: "#fff",
          }}
        >
          <div class="row">
            <div class="col  text-left d-flex flex-row">
              <div class="d-flex flex-row">
                <Typography>Project ID: </Typography>&nbsp;
                <Typography>{props.projId}</Typography>
              </div>
            </div>
          </div>
        </UIAccordionSummary>
        <UIAccordionDetails style={{ backgroundColor: "#fff" }}>
          <div class="row m-1">
            <div class="col">
              <div class="d-flex mt-4">
                <span className="title-prompts mt-1">Site ID</span>
                <UITextField
                  value={sId}
                  placeholder="Please enter site id"
                  onChange={(e) => {
                    setMsg("");
                    checkIdField(e.target.value)
                      ? setIdValid(true)
                      : setIdValid(false);
                    setSId(e.target.value);
                  }}
                  helperText={
                    !idValid && sId !== "" ? captions.validation.SITE_ID : ""
                  }
                  error={!idValid && sId !== ""}
                  width={310}
                />
              </div>

              <div class="d-flex mt-4">
                <span className="title-prompts mt-1">Site Display Name</span>

                <UITextField
                  value={sDName}
                  placeholder="Please enter site display name"
                  onChange={(e) => {
                    setMsg("");
                    checkNameField(e.target.value)
                      ? setDNameValid(true)
                      : setDNameValid(false);
                    setSDName(e.target.value);
                  }}
                  helperText={
                    !dNameValid && sDName !== ""
                      ? captions.validation.SITE_DNAME
                      : ""
                  }
                  error={!dNameValid && sDName !== ""}
                  width={310}
                />
              </div>
              <div class="d-flex mt-3">
                <span className="title-prompts mt-3">TimeZone</span>
                <UIDropDown
                  variant="standard"
                  value={sTimezone}
                  onChange={(e) => {
                    setMsg("");
                    setSTimezone(e.target.value);
                    setSTimeShort(e.target.value.split(" ")[1]);
                  }}
                  placeholder="Please select timezone"
                  placeholderStyle={{ color: "#B3B3B3", fontStyle: "normal" }}
                  minWidth={310}
                  textAlign={"left"}
                  maxHeight={250}
                  list={timezoneList}
                />
              </div>
              <div class="d-flex mt-3">
                <span className="title-prompts mt-3">Selenium Version</span>
                {/* //do for only 3 and 4 */}
                <UIDropDown
                  value={sSelVer}
                  variant="standard"
                  onChange={(e) => {
                    setMsg("");
                    setSSelVer(e.target.value);
                  }}
                  placeholder="Please select version"
                  placeholderStyle={{ color: "#B3B3B3", fontStyle: "normal" }}
                  minWidth={310}
                  textAlign={"left"}
                  maxHeight={250}
                  list={seleniumVersionList}
                />
              </div>
            </div>
            <div class="col">
              <div class="d-flex mt-4">
                <span className="title-prompts mt-1">Thread Count</span>
                <UINumField
                  value={sThreadCnt}
                  min={0}
                  width={310}
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => {
                    setMsg("");
                    checkNumFieldWithoutRange(e.target.value)
                      ? setThreadCntValid(true)
                      : setThreadCntValid(false);
                    setSThreadCnt(e.target.value);
                  }}
                  placeholder={"Please enter count"}
                  helperText={
                    !threadCntValid && sThreadCnt !== ""
                      ? captions.validation.SITE_NUM
                      : ""
                  }
                  error={!threadCntValid && sThreadCnt !== ""}
                />
              </div>

              <div class="d-flex mt-4">
                <span className="title-prompts mt-1">
                  Partner Threads Allocated
                </span>
                <UINumField
                  value={sParthrAll}
                  min={0}
                  width={310}
                  onKeyDown={blockInvalidChar}
                  onChange={(e) => {
                    setMsg("");
                    checkNumFieldWithoutRange(e.target.value)
                      ? setParthrAllValid(true)
                      : setParthrAllValid(false);
                    setSParthrAll(e.target.value);
                  }}
                  placeholder={"Please enter count"}
                  helperText={
                    !setParthrAllValid && setSParthrAll !== ""
                      ? captions.validation.SITE_NUM
                      : ""
                  }
                  error={!setParthrAllValid && setSParthrAll !== ""}
                />
              </div>

              <div class="d-flex mt-4">
                <span className="title-prompts mt-1">User Key</span>

                <UITextField
                  value={sUkey}
                  placeholder="Please enter user key"
                  onChange={(e) => {
                    setMsg("");
                    setSUkey(e.target.value);
                  }}
                  width={310}
                />
              </div>
              <div class="d-flex mt-4">
                <span className="title-prompts mt-1">Pass Key</span>

                <UITextField
                  value={sPKey}
                  placeholder="Please enter pass key"
                  onChange={(e) => {
                    setMsg("");

                    setSPKey(e.target.value);
                  }}
                  width={310}
                />
              </div>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col mt-3 ">
              <div className="d-flex justify-content-end mt-4">
                <div className="col-sm-8 btn-group cp-btn-group justify-content-end mr-5 mt-5 mb-3">
                  <div class="mr-4">
                    <UIButton
                      variant="contained"
                      onClick={() => {
                        addSite();
                      }}
                      disabled={
                        checkValidNonEmptyValues(
                          [
                            idValid,
                            dNameValid,
                            sVerValid,
                            parthrAllValid,
                            threadCntValid,
                          ],
                          [sSelVer, sId, sDName, sTimezone, sTimeShort]
                        )
                          ? false
                          : true
                      }
                      title={"Add Site"}
                    />
                  </div>
                  <div class="mr-4">
                    <UIButton
                      variant="contained"
                      onClick={() => {
                        reset();
                      }}
                      disabled={
                        checkNonEmptyValues([
                          sParthrAll,
                          sSelVer,
                          sId,
                          sDName,
                          sTimezone,
                          sTimeShort,
                          sUkey,
                          sPKey,
                          sThreadCnt,
                        ])
                          ? false
                          : true
                      }
                      title={"Cancel"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mr-5">
            <div class="col d-flex justify-content-end mr-2">
              <span className="col-sm-8 btn-group cp-btn-group justify-content-end">
                {msg}
              </span>
            </div>
          </div>
        </UIAccordionDetails>
      </UIAccordion>
    </React.Fragment>
  );
}

export default AddSite;
