import React, { useState } from "react";
import "../TestExecution.css";
import captions from "../../config/captions.json";
import {
  checkValidNonEmptyValues,
  checkEmptyValues,
  checkEmail,
} from "../checkFields";
import axios from "axios";
import { UIButton, UITextField } from "../UIComponents";

import EditUser from "./EditUser";

function AdminEditUser() {
  const [userEmail, setUserEmail] = useState("");
  const [userData, setUserData] = useState({});
  const [msg, setMsg] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const [editUserBtn, setEditUserBtn] = useState(false);
  const [headerData] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  });
  const [adminRights, ] = useState(sessionStorage.getItem("UserDetails") && "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role === "admin" ? true : false)

  function getUserData() {
    if (checkValidNonEmptyValues([emailValid], [userEmail]) && msg === "") {
      //api call for adding project - projId
      const url =
        window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_USER_URL;

      axios
        .get(url + "/" + userEmail.trim(), {
          headers: headerData,
        })
        .then((result) => {
          if (result.data.status === "success") {
            var responseData = result.data.info;
            setUserEmail("");
            setUserData(responseData.User);
            setEditUserBtn(true);
          }
        })
        .catch((error) => {
          if ("response" in error) {
            if (error.response.status === 404) {
              setMsg(captions.error.USER_VALUE_ERROR);
            } else if (error.response.status === 401) {
              setMsg(captions.error.USER_UNAUTHORIZED);
            } else if (error.response.status === 500) {
              setMsg(captions.error.USER_EDIT_INTERNAL_ERROR);
            }
          } else {
            setMsg(captions.error.INTERNAL_ERROR);
          }
        });
    } else {
      setMsg(captions.error.FIELDS_EMPTY);
    }
  }

  return (
    <React.Fragment>
      <div className="card  custcard">
        <div className="cord-body">
          <div className="testHeading">
            <label className="headermsg">{adminRights? "Edit User" : "Your access is denied"}</label>
          </div>
        </div>
      </div>

      {
        adminRights
        ?
        <div className="card  custcard">
          <div className="cord-body m-4">
            {!editUserBtn ? (
              <div className="d-flex align-items-center justify-content-center mt-4 ml-5">
                <div className="d-flex ml-5 mt-3">
                  <span className="title-prompts mt-2 ml-5">Email Id</span>
                  <UITextField
                    value={userEmail}
                    width={350}
                    placeholder="Please enter email id"
                    onChange={(e) => {
                      setMsg("");
                      checkEmail(e.target.value)
                        ? setEmailValid(true)
                        : setEmailValid(false);
                      setUserEmail(e.target.value);
                    }}
                    helperText={
                      !emailValid && userEmail !== ""
                        ? captions.validation.EMAIL
                        : ""
                    }
                    error={!emailValid && userEmail !== ""}
                  />
                </div>
                <div className="col-sm-8 btn-group cp-btn-group">
                  <div className="btn-wrapper d-flex mt-2">
                    <div className="ml-4">
                      <UIButton
                        variant="contained"
                        onClick={() => {
                          getUserData();
                        }}
                        disabled={
                          checkValidNonEmptyValues([emailValid], [userEmail])
                            ? checkEmptyValues([msg])
                              ? false
                              : true
                            : true
                        }
                        title={"Edit User"}
                      />
                    </div>
                    <div className="m-2">
                      <span style={{ color: "blue" }}>{msg}</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="row">
                  <div className="col text-right">
                    <UIButton
                      variant="text"
                      title={"< Back"}
                      disabled={false}
                      onClick={() => {
                        setEditUserBtn(false);
                        setUserEmail("");
                        setMsg("");
                      }}
                    />
                  </div>
                </div>
                <EditUser userData={userData} userEmail={userData.Email} />
              </div>
            )}
          </div>
        </div>
        :
        null
      }
    </React.Fragment>
  );
}
export default AdminEditUser;
