import React, { Component } from 'react';
import { withRouter, Link, Redirect } from 'react-router-dom';
import './Header.css';
import { getUserSession, removeUserSession } from './UserSession';
import ch_logo from '../images/ch-logo-light.png';
import SiteLogo from './SiteLogo';
import LockIcon from '@material-ui/icons/Lock';
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chemail: '',
      chimageUrl: '',
      chfirstname: '',
    };
  }

  toggleclassNames = () => {
    document.getElementById('slidout-content').classList.toggle('show');
    document.getElementById('backdrop-for-slidout').classList.toggle('show');
  };
  Logout = () => {
    // console.log('Logout');
    sessionStorage.clear();
    // localStorage.removeItem('rioSession');
    // localStorage.setItem('rioLogginStatus', 'N');
    this.props.history.push('/login');
  };
  update = () => {
    this.setState = {
      chemail: getUserSession.getItem('email'),
      chimageUrl: getUserSession.getItem('imageUrl'),
      chfirstname: getUserSession.getItem('givenName'),
    };
  };
  componentDidMount() {
    this.update();
  }
  render() {
    let current_page = this.props.location.pathname;
    let hideHome = 'hide';
    let hideDashboard = '';
    let hideChangePassword = '';
    let hideAdminPage = "";
    let selectedProjectIndex = sessionStorage.getItem("selectedProjectIndex")?sessionStorage.getItem("selectedProjectIndex"):"0";
    let NopageAccess = sessionStorage.getItem("UserDetails") && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.rights.Projects.length !== 0 ?JSON.parse(sessionStorage.getItem("UserDetails")).data.User.rights.Projects[selectedProjectIndex].Page_access == null:false;
    //let pageAccessList = (sessionStorage.getItem("UserDetails")&&sessionStorage.getItem("selectedProjectIndex"))?JSON.parse(sessionStorage.getItem("UserDetails")).data.User.rights.Projects[selectedProjectIndex].Page_access:[];
    let checkpage = current_page.replace("/","").toLowerCase();
    let adminRights = sessionStorage.getItem("UserDetails") && "role" in JSON.parse(sessionStorage.getItem("UserDetails")).data.User && JSON.parse(sessionStorage.getItem("UserDetails")).data.User.role === "admin" ? true : false;
    // 
    // NopageAccess = pageAccessList?!pageAccessList[0][checkpage]:true;
    
    if (current_page === '/MyDashboard') {
      hideDashboard = 'hide';
      hideHome = '';
    }
    if (current_page === '/change-password') {
      hideChangePassword = 'hide';
    }
    else{
      hideChangePassword = '';
    }
    if (current_page === "/admin-page") {
      hideAdminPage = "hide";
    } else {
      hideAdminPage = "";
    }
    if (current_page === '/SiteSelection') {
      hideDashboard = 'hide';
      hideHome = 'hide';
    }
    // if (localStorage.getItem('rioSession') != null) {
    //   let rioSession = JSON.parse(localStorage.getItem('rioSession'));
    //   console.log(rioSession)
    //   for (let key in rioSession) {
    //     console.log(key + "-->" + rioSession[key]);
    //     sessionStorage.setItem(key, rioSession[key]);
    //   }
    //   // rioSession.forEach(element => {
    //   //   console.log(element);   
    //   // });
    // }
    // let isloggedin = localStorage.getItem('rioLogginStatus');
    let session = getUserSession.getItem('email');
    // if (isloggedin == null || isloggedin == "N") {
    //   sessionStorage.clear();
    // }
    let chemail = getUserSession.getItem('email');
    let chimageUrl = getUserSession.getItem('imageUrl');
    let chfirstname = getUserSession.getItem('givenName');
    this.update();
    // console.log("session-->", session);
    let clsnname = session === '' || session === null ? 'hide' : '';
    if (session === '' || session === null) {
      if (current_page.split("/")[1] === 'resetpassword') {
        return <Redirect to={current_page} />;
      }
      else {
        return <Redirect to="/login" />;
      }
    } else if(NopageAccess && current_page !== "/"){
      return <Redirect to="/" />;
    }
    else
      return (
        <div className="pos-f-t">
          <div className="my-collapse" id="slidout-content">
            <div className="user-view">
              <div className="background">
                <img
                  src="https://materializecss.com/images/office.jpg"
                  className="img-responsive"
                  alt="header"
                />
              </div>
              <div className="user-details">
                <a href="#chlogo">
                  <img
                    alt="chts"
                    className="circle"
                    data-user-pic=""
                    src={chimageUrl}
                  />
                </a>
                <br />
                <br />
                <a href="#name">
                  <span className="white-text name" data-user-name="">
                    {chfirstname}
                  </span>
                </a>
                <br />
                <a href="#email">
                  <span className="white-text email" data-user-email="">
                    {chemail}
                  </span>
                </a>
              </div>
            </div>

            <ul className="menu">
              <li>
                <a
                  href="#"
                  onClick={this.Logout.bind(null)}
                  title="Logout"
                >
                  <i className="fa fa-sign-out"></i> Logout
                </a>
              </li>
              <li className={hideHome}>
                <Link className="nav-link" to="/">
                <i className="fa fa-home" aria-hidden="true"></i> Home
                </Link>
              </li>
              {/* for admin module */}
              {adminRights === true ? (
                <li className={hideAdminPage}>
                  <Link
                    className="nav-link"
                    to="/admin-page"
                    onClick={() => this.toggleclassNames()}
                  >
                    <i className="fa fa-lock" aria-hidden="true"></i> Admin Account
                  </Link>
                </li>
              ) : null}
              {/* <li className={hideDashboard}>
                <Link className="nav-link" to="/MyDashboard">
                  <i class="fa fa-tachometer" aria-hidden="true"></i> My Dashboard
                </Link>
              </li> */}
              <li className={hideChangePassword}>
                <Link className="nav-link" to="/change-password" onClick={()=>this.toggleclassNames()}>
                  <i className="fa fa-lock" aria-hidden="true"></i> Change Password
                </Link>
              </li>
            </ul>
          </div>
          <div
            className="backdrop"
            id="backdrop-for-slidout"
            onClick={this.toggleclassNames}
          ></div>
          <nav className="navbar navbar bg-dark">
            <div>
              <img alt="chLogo" src={ch_logo} className="ch-logo"></img>
            </div>
            <div className={clsnname}>
              <SiteLogo></SiteLogo>
            </div>
            <div className={clsnname}>
              <button
                className="navbar-toggler"
                style={{ marginLeft: 'auto' }}
                type="button"
                onClick={this.toggleclassNames}
                data-toggle="collapse"
                data-target="#navbarToggleExternalContent"
                aria-controls="navbarToggleExternalContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                Hi {chfirstname}, <i className="fa fa-user"></i>
              </button>
            </div>
          </nav>
        </div>
      );
  }
}

export default withRouter(Header);
