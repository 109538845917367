
import React, { Component } from 'react'
import moment from 'moment';
import 'moment-timezone';
class CustomMoment extends Component {
    constructor(props) {
        super(props);
        this.setState({
            time: ""
        });
        var intervel = this.props.intervel?this.props.intervel:"1000"
        setInterval(() => {
            this.componentDidMount();
        }, intervel)
    }
    
    componentDidMount(){
        var then = moment(this.props.time);
        let now =  moment.utc();
        var ms = moment(now).diff(moment.utc(then));
        var d = moment.duration(ms);
        var hr = ('00' + (Math.floor(d.asHours()))).substr(-2);
        var s = hr + moment.utc(ms).format(":mm:ss");
        this.setState({
            time: s
        });
        // console.log(s)
    }
    render() {
        const result = this.state==null?"":this.state.time
        return <span>{result}</span>;
    }
}

export default CustomMoment