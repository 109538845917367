import React, { Component } from 'react'
import './DefectTrends.css'

class DefectTrends extends Component {
  constructor(props) {
    super(props)

    this.state = {
      img: ""
    }

  }
  componentDidMount() {
    setTimeout(() => {
      let trendsDetails = JSON.parse(sessionStorage.selectedSiteData)['defectTrendsUrl']
      if (!trendsDetails) {
        this.props.history.push('/login');
      }
      else {
        this.setState({
          img: trendsDetails
        })
      }
    }, 1000)
  }
  render() {
    return (
      <div>
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg1" id="TestResult">Defect Trends</label>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <iframe src={this.state.img} title="trends" className="trends"></iframe>
          </div>
        </div>
      </div>
    )
  }
}

export default DefectTrends
