import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: sessionStorage.getItem("email"),
            oldPassword: '',
            newPassword: '',
            message: '',
            isPasswordChangeInProgress: false,
        };
    }
    handleOldPassword = (e) => {
        this.setState({ "oldPassword": e.target.value, message: "" })
    }
    handleNewPassword = (e) => {
        this.setState({ "newPassword": e.target.value, message: "" })
    }
    save = (e) => {
        this.setState({isPasswordChangeInProgress:true})
        if (this.state.oldPassword === "" || this.state.newPassword === "") {
            this.setState({ 
                message: "All fields are required",  
                isPasswordChangeInProgress: false, 
            })
            return false;
        }
        var data = {
            email: this.state.email,
            oldPassword: this.state.oldPassword,
            newPassword: this.state.newPassword
        }
        axios
            .put(window.configs.CHANGE_PASSWORD, data)
            .then((result) => {
                if (result.status === 202 || result.status === 204) {
                    this.setState({
                        message: 'Password updated successfully',
                        oldPassword:'',
                        newPassword:'',
                        isPasswordChangeInProgress: false,
                    });
                } else {
                    this.setState({
                        message: 'Please enter the correct password',
                        isPasswordChangeInProgress: false,
                    });
                }
            })
            .catch((err) => {
                this.setState({
                    message: 'Something went wrong',
                    isPasswordChangeInProgress: false,
                });
            });
    }
    render() {
        return (
            <React.Fragment>
                <Grid container justify='center' alignContent='center' xs={12} spacing={3} style={{ height: "70%" }}>
                    <Grid item xs={4}>
                        <div className="card-body" style={{ background: "#2491cf" }}>
                            <br />
                            <h5 style={{ color: "#fff" }}>Change Password</h5>
                            <br />
                            <div className="card-content">
                                <TextField
                                    id="standard-basic1"
                                    label={"Current Password*"}
                                    value={this.state.oldPassword}
                                    autoComplete="off"
                                    name="Username"
                                    onChange={this.handleOldPassword}
                                    type="password"
                                />
                                <br /><br />
                                <TextField
                                    id="standard-basic1"
                                    label={"New Password*"}
                                    value={this.state.newPassword}
                                    autoComplete="off"
                                    name="Username"
                                    onChange={this.handleNewPassword}
                                    type="password"
                                />
                                <br /> <br />
                                <a className='forgot-password' href="/">Go to Home</a>
                                <p className="yellow-text">{this.state.message}</p>
                                <div className="form-field">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '100%', backgroundColor: "#fff" }}
                                        id="save"
                                        onClick={this.save}
                                    >
                                        {this.state.isPasswordChangeInProgress?<CircularProgress size={30} />:"Save Changes"}

                                    </Button>
                                </div>
                                <br />
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

export default ChangePassword