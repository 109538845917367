import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import Avatar from "@mui/material/Avatar";
import { blue, blueGrey, grey } from "@mui/material/colors";
import ReactMarkdown from "react-markdown";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./testGenAI.css";
import captions from "../../../config/captions.json";
import {
  Stack,
  Box,
  FormControl,
  OutlinedInput,
  CircularProgress,
  IconButton,
  Button,
} from "@mui/material";
import { RateReviewTwoTone } from "@material-ui/icons";

const messageType = {
  answer: "assistant",
  question: "user",
};

const TestGenAI = (props) => {
  const inputRef = useRef();
  const chatWrapperRef = useRef();
  const [userName, setUserName] = useState("");
  const [onRequest, setOnRequest] = useState(false);
  const [question, setQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [displayName, setDisplayName] = useState("");
  const [submenuCode, setSubmenuCode] = useState("");
  const [projectId, setProjectId] = useState("");
  const [siteId, setSiteId] = useState("");
  const [menuCode] = useState(
    props.location.pathname.split("/")[2].split("+")[2]
  );
  const [appCode] = useState(props.location.pathname.split("/")[1]);
  const [newChat, setNewChat] = useState(true);
  const [newChatId, setNewChatId] = useState(uuidv4());

  const tc_icon = require("../../../images/ecommerce.png");

  const getCaptionsAnswer = async (qData) => {
    if (onRequest) return;

    const newMsgs = [
      ...messages,
      {
        role: messageType.question,
        content: qData,
      },
    ];
    setNewChat(false);
    setMessages(newMsgs);

    setQuestion("");
    setOnRequest(true);

    const api_url = window.configs.BASE_API_URL + window.configs.TEST_GEN_URL;
    const payload = {
      projectId: projectId,
      siteId: siteId,
      prompt: qData,
    };
    const queryParams = {
      id: newChatId,
    };

    const urlWithParams = `${api_url}?${new URLSearchParams(
      queryParams
    ).toString()}`;

    try {
      const response = await fetch(urlWithParams, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
      /*  {"prompt" : "xyz" , "index" : "gen-index", messages: []}*/
      if (response.status === 200) {
        const decoder = new TextDecoder();
        const reader = response.body.getReader();
        var chunks = "";
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            setOnRequest(false);
            break;
          }

          chunks += decoder.decode(value);
          setMessages([
            ...newMsgs,
            {
              role: messageType.answer,
              content: chunks.replace(/^None/, "").trim(),
            },
          ]);
        }
      } else {
        toast.info("Something went wrong", {
          duration: 1000,
          position: "bottom-right",
          theme: "colored",
        });
        setOnRequest(false);
      }
    } catch (err) {
      toast.info("Something went wrong", {
        duration: 1000,
        position: "bottom-right",
        theme: "colored",
      });

      setOnRequest(false);
      setNewChat(true);
      return { err };
    }
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && question !== "") getCaptionsAnswer(question);
  };

  const nameFormat = () => {
    var firstLetter = sessionStorage.getItem("givenName").split(" ")[0][0];
    return firstLetter;
  };

  useEffect(() => {
    if (inputRef.current && !onRequest) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    setUserName(
      sessionStorage.getItem("givenName") === null ||
        sessionStorage.getItem("givenName") === "undefined"
        ? ""
        : sessionStorage.getItem("givenName")
    );
    var project = sessionStorage.getItem("selectedProjectIndex");
    var site = sessionStorage.getItem("selectedSiteIndex");
    var projectDetails =
      JSON.parse(sessionStorage.getItem("UserDetails")) != null
        ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User.rights
            .Projects
        : {};

    var menus = projectDetails[project].sites[site].menus;
    if (projectDetails.length > 0 && menus !== undefined) {
      setProjectId(projectDetails[project].projectId);
      setSiteId(projectDetails[project].sites[site].siteid);
      menus.forEach((item) => {
        if (item.code === menuCode) {
          let submenuList = item.submenus;
          submenuList.forEach((element) => {
            if (element.submenuCode === appCode) {
              setDisplayName(element.displayName);
              setSubmenuCode(element.submenuCode);
            }
          });
        }
      });
    }
    sessionStorage.setItem(
      "pageDisplayName" +
        sessionStorage.selectedProjectIndex +
        sessionStorage.selectedSiteIndex,
      displayName
    );
    sessionStorage.setItem(
      "pagePath" +
        sessionStorage.selectedProjectIndex +
        sessionStorage.selectedSiteIndex,
      "/" + submenuCode
    );
    if (chatWrapperRef.current) {
      chatWrapperRef.current.scrollTop = chatWrapperRef.current.scrollHeight;
    }
  }, [props, userName, messages, onRequest]);

  const formatMarkdownComponents = () => {
    return {
      code: ({ node, inline, className, children, ...props }) => {
        if (inline) {
          return (
            <code
              style={{
                backgroundColor: "#f0f0f0",
                padding: "2px",
                scrollbarWidth: "none",
              }}
              {...props}
            >
              {children}
            </code>
          );
        } else {
          return (
            <pre
              style={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                scrollbarWidth: "none",
              }}
            >
              <code {...props}>{children}</code>
            </pre>
          );
        }
      },
      h1: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h2: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h3: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h4: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
      h5: ({ node, inline, className, children, ...props }) => {
        return <h6 {...props}>{children}</h6>;
      },
    };
  };

  const reset = () => {
    setMessages([]);
    setOnRequest(false);
    setQuestion("");
    setNewChat(true);
    setNewChatId(uuidv4());
  };

  return (
    <div className="analytics">
      <div className="card  custcard">
        <div className="cord-body">
          <div
            className="testHeading"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <label
              id="TestResult"
              className="testResultLabel"
              aria-label={displayName}
            >
              <center>{displayName} </center>
            </label>
            {onRequest || newChat ? null : (
              <Button
                id="newChat"
                variant="outlined"
                startIcon={<RateReviewTwoTone />}
                size="small"
                style={{ textTransform: "none", outline: "none" }}
                onClick={() => {
                  reset();
                }}
              >
                New Chat
              </Button>
            )}
          </div>
        </div>
      </div>
      <div ref={chatWrapperRef} className="card cardAdditionalStyling" style={{ overflowY: "hidden" }}>
        <ToastContainer />
        <div className="card subcard box-container">
          <Box className="messageBox">
            <Box
              className="boxStyling"
            >
              {messages.length === 0 && newChat ? (
                <div>
                  <center>
                    <Avatar
                      className="avatarGen"
                      sx={{ width: 50, height: 50 }}
                    >
                      <img alt="" src={tc_icon} width="50" />
                    </Avatar>
                  </center>

                  <p className="h6Generic">
                    {captions.labels.TEST_GEN.genericText}
                  </p>
                </div>
              ) : (
                messages.map((item, index) => (
                  <Box key={index}>
                    <Stack spacing={2} direction={"row"}>
                      {item.role === messageType.answer ? (
                        <Avatar
                          sx={{
                            width: 35,
                            height: 35,
                          }}
                          style={{ marginTop: "15px" }}
                        >
                          <p className="pBot">
                            <img alt="" src={tc_icon} width="36" />
                          </p>
                        </Avatar>
                      ) : (
                        <Avatar
                          sx={{
                            bgcolor: blue[700],
                            width: 35,
                            height: 35,
                          }}
                          style={{ marginTop: "15px" }}
                        >
                          {userName !== "" ? (
                            <p className="pBot">{nameFormat()}</p>
                          ) : null}
                        </Avatar>
                      )}
                      <Box
                        sx={{
                          padding: 1,
                          /* bgcolor: item.type === messageType.answer && "#2f2f2f", */
                          borderRadius: 3,
                        }}
                      >
                        {item.role === messageType.answer ? (
                          <div className="text-section">
                            <h6 style={{ marginTop: "10px" }}>
                              {captions.labels.TEST_GEN.botName}
                            </h6>

                            <ReactMarkdown
                              components={formatMarkdownComponents()}
                              children={item.content}
                            ></ReactMarkdown>
                          </div>
                        ) : (
                          <div className="text-section">
                            <h6 style={{ marginTop: "10px" }}>You</h6>

                            <ReactMarkdown>{item.content}</ReactMarkdown>
                          </div>
                        )}
                      </Box>
                    </Stack>
                  </Box>
                ))
              )}
            </Box>
          </Box>
        </div>

        <Stack
          alignItems="center"
          justifyContent="center"
          className="subcard inputWrapper"
        >
          <Box width="100%" zIndex={4} textAlign={"center"}>
            <FormControl fullWidth variant="outlined">
              <OutlinedInput
                inputRef={inputRef}
                className="customOutlinedInput"
                multiline="true"
                maxRows={6}
                minRows={1}
                sx={{
                  color:blueGrey[500],
                  backgroundColor: grey[50],
                  "& textarea": {
                    "&::-webkit-scrollbar": { cursor: "default", width: "0.9rem" },
                    "&::-webkit-scrollbar-thumb": { cursor: "default" },
                  },
                }}
                endAdornment={
                  onRequest ? (
                    <CircularProgress size="1.5rem" />
                  ) : (
                    <IconButton disabled={question == ""}>
                      <SendOutlinedIcon
                        onClick={() => getCaptionsAnswer(question)}
                      />
                    </IconButton>
                  )
                }
                autoFocus
                disabled={onRequest}
                onKeyUp={onEnterPress}
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                placeholder="Ask something..."
              />
            </FormControl>
          </Box>
        </Stack>
      </div>
    </div>
  );
};

export default TestGenAI;
