import React, { useState, useEffect } from "react";

import "./EditProject.css";

import axios from "axios";
import captions from "../../config/captions.json";
import {
  UIDropDown,
  UIButton,
  UITextField,
  UIAccordion,
  UIAccordionSummary,
  UIAccordionDetails,
  UIModal,
} from "../UIComponents";
import { checkEmptyValues } from "../checkFields";
import {
  testTypeList,
  cloudTypeList,
  formFactorsList,
  desktopOSList,
  mobTabOSList,
  macBrowserList,
  windowsBrowserList,
  linBrowserList
} from "../../config/siteItemList";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Typography from "@mui/material/Typography";
import EditSiteTestSuiteModEnv from "./EditSiteTestSuiteModEnv";
import EditSiteIntegration from "./EditSiteIntegration";

function EditSiteTestDetails(props) {
  const [propsTestingTypeData, setPropsTestingTypeData] = useState(
    "testingtype" in props.siteData ? props.siteData.testingtype : []
  );
  const [testTypeTxt, setTestTypeTxt] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [testTypeData, setTestTypeData] = useState({});

  const [fullSuite, setFullSuite] = useState([]);
  const [fullSuiteChecked, setFullSuiteChecked] = useState([]);

  const [module, setModule] = useState([]);
  const [moduleChecked, setModuleChecked] = useState([]);

  const [env, setEnv] = useState([]);
  const [envChecked, setEnvChecked] = useState([]);

  const [testTypeAddModalOpen, setTestTypeAddModalOpen] = useState(false);
  const [tType, setTType] = useState("");
  const [tEnv, setTEnv] = useState("");
  const [tSuite, setTSuite] = useState("");
  const [tModule, setTModule] = useState("");
  const [tIntegration, setTIntegration] = useState("");
  const [tFormFactor, setTFormFactor] = useState("");
  const [tOS, setTOS] = useState("");
  const [tDevice, setTDevice] = useState("");
  const [headerData, ] = useState({
    Authorization: sessionStorage.getItem("authToken")
      ? sessionStorage.getItem("authToken")
      : "",
    "ch-subscription-key": sessionStorage.getItem("authToken")
      ? JSON.parse(sessionStorage.getItem("UserDetails")).data.User[
          "ch-subscription-key"
        ]
      : "",
  })

  const [integrationTypeAddModalOpen, setIntegrationTypeAddModalOpen] =
    useState(false);

  useEffect(() => {
    setPropsTestingTypeData(
      "testingtype" in props.siteData ? props.siteData.testingtype : []
    );
  }, [props]);

  function setSuiteModEnvData(key, data) {
    if (key === "FullSuite" && key in data) {
      setFullSuite([]);
      setFullSuiteChecked([]);
      setFullSuite(data.FullSuite);
      setFullSuiteChecked(
        data.FullSuite.map((x) => {
          return { item: x, check: true };
        })
      );
    }
    if (key === "Module" && key in data) {
      setModule([]);
      setModuleChecked([]);
      setModule(data.Module);
      setModuleChecked(
        data.Module.map((x) => {
          return { item: x, check: true };
        })
      );
    }
    if (key === "Environment" && key in data) {
      setEnv([]);
      setEnvChecked([]);
      setEnv(data.Environment);
      setEnvChecked(
        data.Environment.map((x) => {
          return { item: x, check: true };
        })
      );
    }
  }

  function resetAddTestModalValues() {
    setTType("");
    setTIntegration("");
    setTEnv("");
    setTSuite("");
    setTModule("");
    setTFormFactor("");
    setErrMsg("");
  }

  function resetAddIntegrationModalValues() {
    setTIntegration("");
    setTOS("");
    setTDevice("");
    setTFormFactor("");
    setErrMsg("");
  }

  function checkBrowser(osName){
    if(osName === "Mac"){
      return macBrowserList
    }else if(osName === "Windows"){
      return windowsBrowserList
    }
    else if(osName === "Linux"){
      return linBrowserList
    }
}

  function addTestingType() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;

    var data = {
      type: tType,
      Environment: [tEnv],
      FullSuite: [tSuite],
      Module: [tModule],
    };
    var osData = {};
    //no browsers for desktop app testing
    osData[tOS] = tType === "APP" && tFormFactor === "Desktop" ? []:[tDevice];
    if (tIntegration !== "" && tFormFactor !== "") {
      data["integrations"] = [
        {
          name: tIntegration,
          formfactors: [{ name: tFormFactor, active: true, OSAccess: osData }],
        },
      ];
    }

    axios
      .patch(
        url + "/" + props.projId + "/" + props.siteId + "/testingType",
        data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          setErrMsg("");
          responseData.sites.map((item) => {
            if (item.siteid === props.siteId) {
              resetAddTestModalValues();
              setPropsTestingTypeData(item.testingtype);
              setTestTypeTxt("");

              setTestTypeAddModalOpen(false);
            }
          });
        } else {
          setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 401) {
            setErrMsg(captions.error.SITE_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
          } else {
            resetAddTestModalValues();
            setErrMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          resetAddTestModalValues();
          setErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function addIntegration() {
    const url =
      window.configs.BASE_API_URL + window.configs.ADMIN_SERVICE_PROJECT_URL;
    var factors = {
      name: tFormFactor,
      active: true,
      OSAccess: {},
    };
    //no browsers for desktop app testing
    factors.OSAccess[tOS] = testTypeTxt === "APP" && tFormFactor === "Desktop" ? []: [tDevice];
    var data = {
      type: testTypeTxt,
      integrations: [{ name: tIntegration, formfactors: [factors] }],
    };
    axios
      .patch(
        url + "/" + props.projId + "/" + props.siteId + "/testingType",
        data,
        {
          headers: headerData,
        }
      )
      .then((result) => {
        if (result.data.status === "success") {
          var responseData = result.data.info;

          setErrMsg("");
          responseData.sites.map((item) => {
            if (item.siteid === props.siteId) {
              resetAddIntegrationModalValues();
              setPropsTestingTypeData(item.testingtype);
              //setTestTypeTxt("");
              item.testingtype.map((testItem) => {
                if (testItem.type === testTypeTxt) {
                  setTestTypeData(testItem);

                  setIntegrationTypeAddModalOpen(false);
                }
              });
            }
          });
        } else {
          setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
        }
      })
      .catch((error) => {
        if ("response" in error) {
          if (error.response.status === 400) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 404) {
            setErrMsg(captions.error.SITE_ADD_VALIDATION);
          } else if (error.response.status === 401) {
            setErrMsg(captions.error.SITE_UNAUTHORIZED);
          } else if (error.response.status === 500) {
            setErrMsg(captions.error.TESTING_ADD_INTERNAL_ERROR);
          } else {
            resetAddIntegrationModalValues();
            setErrMsg(captions.error.INTERNAL_ERROR);
          }
        } else {
          resetAddIntegrationModalValues();
          setErrMsg(captions.error.INTERNAL_ERROR);
        }
      });
  }

  function checkIntegrationBasedOnTestType(testType) {
    const valuesToCheck = (testType === "UI" || (testType === "APP" && tFormFactor !== "Desktop"))
        ? [tIntegration, tFormFactor, tDevice, tOS]
        : [tIntegration, tFormFactor, tOS];
        
    return checkEmptyValues(valuesToCheck);
}

function checkAddTestTypeBasedOnTestType() {
  //common for all testing type - API, APP, UI
  const baseValues = [tType, tEnv, tSuite, tModule];
  
  if (tType === "UI") {
      baseValues.push(tIntegration, tFormFactor, tOS, tDevice);
  } else if (tType === "APP") {
      baseValues.push(tIntegration, tFormFactor, tOS);
      if (tFormFactor !== "Desktop") {
          baseValues.push(tDevice);
      }
  }
  
  return checkEmptyValues(baseValues);
}

  function goToTestTypeAddModal(testList) {
    return (
      <UIModal
        open={testTypeAddModalOpen}
        handleClose={testTypeAddModalOpen}
        title={"Add Testing Type"}
        fields={
          <div className="col">
            <div className="d-flex mt-4">
              <span className="title-prompts mt-3">Testing Type</span>
              <UIDropDown
                value={tType}
                onChange={(e) => {
                  setTType(e.target.value);
                  setErrMsg("");
                }}
                placeholder="Select type"
                minWidth={280}
                textAlign={"left"}
                maxHeight={350}
                list={testList}
              />
            </div>
            {tType !== "API" ? (
              <div>
                <div className="d-flex mt-4">
                  <span className="title-prompts mt-3">Integration</span>
                  <UIDropDown
                    value={tIntegration}
                    onChange={(e) => {
                      setTFormFactor("");
                      setTDevice("");
                      setTOS("");
                      setTIntegration(e.target.value);
                      setErrMsg("");
                    }}
                    placeholder="Select integration"
                    minWidth={280}
                    textAlign={"left"}
                    maxHeight={350}
                    list={cloudTypeList}
                  />
                </div>
                {tIntegration !== "" ? (
                  <div>
                    <div className="d-flex mt-4">
                      <span className="title-prompts mt-3">Form Factors</span>
                      <UIDropDown
                        value={tFormFactor}
                        onChange={(e) => {
                          setTDevice("");
                          setTOS("");
                          setTFormFactor(e.target.value);
                          setErrMsg("");
                        }}
                        placeholder="Select form factors"
                        minWidth={280}
                        textAlign={"left"}
                        maxHeight={350}
                        list={formFactorsList}
                      />
                    </div>
                    {tFormFactor !== "" ? (
                      <div>
                        <div className="d-flex mt-4">
                          <span className="title-prompts mt-3">OS</span>
                          <UIDropDown
                            value={tOS}
                            onChange={(e) => {
                              setTDevice("");
                              setTOS(e.target.value);

                              setErrMsg("");
                            }}
                            placeholder="Select os"
                            minWidth={280}
                            textAlign={"left"}
                            maxHeight={350}
                            list={
                              tFormFactor === "Desktop"
                                ? desktopOSList
                                : mobTabOSList
                            }
                          />
                        </div>

                        {tOS !== "" ? (
                          tFormFactor === "Desktop"  ? (
                            tType === "UI" ?
                            <div className="d-flex mt-4">
                              <span className="title-prompts mt-3">
                                Browser
                              </span>
                              <UIDropDown
                                value={tDevice}
                                onChange={(e) => {
                                  setTDevice(e.target.value);
                                  setErrMsg("");
                                }}
                                placeholder="Select browser"
                                minWidth={280}
                                textAlign={"left"}
                                maxHeight={350}
                                list={
                                  checkBrowser(tOS)
                                }
                              />
                            </div>
                            :null
                          ) : (
                            <div className="d-flex mt-4">
                              <span className="title-prompts mt-1">Device</span>
                              <UITextField
                                value={tDevice}
                                placeholder="Please enter device name"
                                onChange={(e) => {
                                  setTDevice(e.target.value);
                                  setErrMsg("");
                                }}
                                //helperText={tEnv !== "" ? captions.validation.REQUIRED : ""}
                                //error={tEnv !== ""}
                                width={310}
                              />
                            </div>
                          )
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Environment</span>
              <UITextField
                value={tEnv}
                placeholder="Please enter environment"
                onChange={(e) => {
                  setTEnv(e.target.value);
                  setErrMsg("");
                }}
                //helperText={tEnv !== "" ? captions.validation.REQUIRED : ""}
                //error={tEnv !== ""}
                width={310}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Full Suite</span>
              <UITextField
                value={tSuite}
                placeholder="Please enter full suite"
                onChange={(e) => {
                  setTSuite(e.target.value);
                  setErrMsg("");
                }}
                //helperText={tSuite !== "" ? captions.validation.REQUIRED : ""}
                //error={tSuite !== ""}
                width={310}
              />
            </div>
            <div className="d-flex mt-4">
              <span className="title-prompts mt-1">Module</span>
              <UITextField
                value={tModule}
                placeholder="Please enter module"
                onChange={(e) => {
                  setTModule(e.target.value);
                  setErrMsg("");
                }}
                //helperText={tModule !== "" ? captions.validation.REQUIRED : ""}
                //error={tModule !== ""}
                width={310}
              />
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  addTestingType();
                }}
                disabled={
                  checkAddTestTypeBasedOnTestType() ? true : false
                }
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetAddTestModalValues();
                  setTestTypeTxt("");
                  setTestTypeAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }

  function goToIntegrationTypeAddModal(testType) {
    return (
      <UIModal
        open={integrationTypeAddModalOpen}
        handleClose={integrationTypeAddModalOpen}
        title={"Add Integration Type"}
        fields={
          <div className="col">
            <div>
              <div className="d-flex mt-4">
                <span className="title-prompts mt-3">Integration</span>
                <UIDropDown
                  value={tIntegration}
                  onChange={(e) => {
                    setTFormFactor("");
                    setTOS("");
                    setTDevice("");
                    setTIntegration(e.target.value);
                    setErrMsg("");
                  }}
                  placeholder="Select integration"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={cloudTypeList.filter((x) => {
                    var name = testTypeData.integrations.map((i) => i.name);
                    return !name.includes(x);
                  })}
                />
              </div>
              {tIntegration !== "" ? (
                <div>
                  <div className="d-flex mt-4">
                    <span className="title-prompts mt-3">Form Factors</span>
                    <UIDropDown
                      value={tFormFactor}
                      onChange={(e) => {
                        setTOS("");
                        setTDevice("");
                        setTFormFactor(e.target.value);
                        setErrMsg("");
                      }}
                      placeholder="Select form factors"
                      minWidth={280}
                      textAlign={"left"}
                      maxHeight={350}
                      list={formFactorsList}
                    />
                  </div>

                  {tFormFactor !== "" ? (
                    <div>
                      <div className="d-flex mt-4">
                        <span className="title-prompts mt-3">OS</span>
                        <UIDropDown
                          value={tOS}
                          onChange={(e) => {
                            setTDevice("");
                            setTOS(e.target.value);

                            setErrMsg("");
                          }}
                          placeholder="Select os"
                          minWidth={280}
                          textAlign={"left"}
                          maxHeight={350}
                          list={
                            tFormFactor === "Desktop"
                              ? desktopOSList
                              : mobTabOSList
                          }
                        />
                      </div>

                      {tOS !== "" ? (
                        tFormFactor === "Desktop"  ? (
                          testType === "UI" ?
                          <div className="d-flex mt-4">
                            <span className="title-prompts mt-3">Browser</span>
                            <UIDropDown
                              value={tDevice}
                              onChange={(e) => {
                                setTDevice(e.target.value);
                                setErrMsg("");
                              }}
                              placeholder="Select browser"
                              minWidth={280}
                              textAlign={"left"}
                              maxHeight={350}
                              list={
                                checkBrowser(tOS)
                              }
                            />
                          </div>
                          :null
                        ) : (
                          <div className="d-flex mt-4">
                            <span className="title-prompts mt-1">Device</span>
                            <UITextField
                              value={tDevice}
                              placeholder="Please enter device name"
                              onChange={(e) => {
                                setTDevice(e.target.value);
                                setErrMsg("");
                              }}
                              //helperText={tEnv !== "" ? captions.validation.REQUIRED : ""}
                              //error={tEnv !== ""}
                              width={310}
                            />
                          </div>
                        )
                      ) : null}
                    </div>
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        }
        actions={
          <div className="col-sm-8 btn-group cp-btn-group mt-3">
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  addIntegration();
                }}
                disabled={
                  checkIntegrationBasedOnTestType(testType) ? true : false
                }
                title={"Add"}
              />
            </div>
            <div className="ml-5">
              <UIButton
                variant="contained"
                onClick={() => {
                  resetAddIntegrationModalValues();
                  //setTestTypeTxt("");
                  setIntegrationTypeAddModalOpen(false);
                }}
                title={"Close"}
              />
            </div>
          </div>
        }
        errors={
          <div className="col text-center mt-2">
            <span className="col-sm-8 btn-group cp-btn-group">{errMsg}</span>
          </div>
        }
      />
    );
  }

  return (
    <UIAccordion style={{ marginTop: "10px" }}>
      <UIAccordionSummary
        expandIcon={
          <ArrowForwardIosSharpIcon
            sx={{ fontSize: "0.9rem", color: "#000" }}
          />
        }
        style={{
          backgroundColor: "rgba(0, 0, 0, .09)",
          color: "#000",
        }}
      >
        <div className="row">
          <div className="col text-left">
            <Typography>Testing Type</Typography>
          </div>
        </div>
      </UIAccordionSummary>
      <UIAccordionDetails style={{ backgroundColor: "#fff" }}>
        <div className="row mt-3">
          <div className="col text-left ml-3">
            <div className="d-flex mr-5">
              <div>
                <UIDropDown
                  value={testTypeTxt}
                  onChange={(e) => {
                    setTestTypeTxt(e.target.value);
                    setErrMsg("");
                    setTestTypeData({});
                    propsTestingTypeData.map((item) => {
                      if (item.type === e.target.value) {
                        setTestTypeData(item);

                        setSuiteModEnvData("FullSuite", item);

                        setSuiteModEnvData("Module", item);
                        setSuiteModEnvData("Environment", item);
                      }
                    });
                  }}
                  placeholder="Select Testing Type"
                  minWidth={280}
                  textAlign={"left"}
                  maxHeight={350}
                  list={propsTestingTypeData.map((item) => item.type)}
                />
              </div>
              <div className="col-sm-8 btn-group cp-btn-group mt-2">
                <div>
                  <UIButton
                    variant="text"
                    onClick={() => {
                      setTestTypeAddModalOpen(true);
                    }}
                    disabled={
                      propsTestingTypeData.length === testTypeList.length
                        ? true
                        : false
                    }
                    title={"Add"}
                  />

                  {goToTestTypeAddModal(
                    testTypeList.filter((x) => {
                      var type = propsTestingTypeData.map((item) => item.type);
                      return !type.includes(x);
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col text-left  mt-4 ml-3">
            {/*Integration Begins */}
            {testTypeTxt !== "" &&
            testTypeTxt !== "API" &&
            "integrations" in testTypeData ? (
              <div
                style={{
                  border: "1px solid #aaa",
                  marginTop: "5px",
                }}
              >
                <div className="row">
                  <div
                    className="col d-flex flex-row"
                    style={{
                      //backgroundColor: "rgba(0, 0, 0, .5)",
                      backgroundColor: "rgba(0, 0, 0, .09)",
                      margin: "0px 15px 0px 15px",
                    }}
                  >
                    <div className="col text-left">
                      <Typography
                        style={{
                          //color: "darkblue",
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        Integration
                      </Typography>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left m-3">
                    {testTypeData.integrations && testTypeData.integrations.map((item, index) => 
                      {
                        if (testTypeTxt === "UI") {
                         return (
                            <EditSiteIntegration
                              key = {`UI-${index}`}
                              projId={props.projId}
                              siteId={props.siteId}
                              integrationName={item.name}
                              integrationData={item}
                              testType={testTypeTxt}
                              setPropsTestingTypeData={setPropsTestingTypeData}
                              setTestTypeData={setTestTypeData}
                            />
                          )
                        }
                        if (testTypeTxt === "APP") {
                          return (
                             <EditSiteIntegration
                               key = {`APP-${index}`}
                               projId={props.projId}
                               siteId={props.siteId}
                               integrationName={item.name}
                               integrationData={item}
                               testType={testTypeTxt}
                               setPropsTestingTypeData={setPropsTestingTypeData}
                               setTestTypeData={setTestTypeData}
                             />
                           )
                         }
                      }
                      
                    )}
                  </div>
                </div>
                <div className="col text-left ml-4 mb-2">
                  <UIButton
                    variant="text"
                    title={"Add Integration"}
                    onClick={() => {
                      setIntegrationTypeAddModalOpen(true);
                    }}
                    disabled={
                      propsTestingTypeData.length === cloudTypeList.length
                        ? true
                        : false
                    }
                  />
                  {goToIntegrationTypeAddModal(testTypeTxt)}
                </div>
              </div>
            ) : null}
            {/*Integration End */}
            {/*Full Suite Begins */}
            {testTypeTxt !== "" && "FullSuite" in testTypeData ? (
              <EditSiteTestSuiteModEnv
                projId={props.projId}
                siteId={props.siteId}
                category="FullSuite"
                itemData={testTypeData}
                checkedItems={fullSuiteChecked}
                listItems={fullSuite}
                setSuiteModEnvData={setSuiteModEnvData}
                setPropsTestingTypeData={setPropsTestingTypeData}
                setTestTypeData={setTestTypeData}
              />
            ) : null}

            {/*Full Suite Ends */}
            {/*Module Begins */}
            {testTypeTxt !== "" && "Module" in testTypeData ? (
              <EditSiteTestSuiteModEnv
                projId={props.projId}
                siteId={props.siteId}
                category="Module"
                itemData={testTypeData}
                checkedItems={moduleChecked}
                listItems={module}
                setSuiteModEnvData={setSuiteModEnvData}
                setPropsTestingTypeData={setPropsTestingTypeData}
                setTestTypeData={setTestTypeData}
              />
            ) : null}
            {/*Module Ends */}
            {/*Environment Begins */}
            {testTypeTxt !== "" && "Environment" in testTypeData ? (
              <EditSiteTestSuiteModEnv
                projId={props.projId}
                siteId={props.siteId}
                category="Environment"
                itemData={testTypeData}
                checkedItems={envChecked}
                listItems={env}
                setSuiteModEnvData={setSuiteModEnvData}
                setPropsTestingTypeData={setPropsTestingTypeData}
                setTestTypeData={setTestTypeData}
              />
            ) : null}
            {/*Environment Ends */}
          </div>
        </div>
      </UIAccordionDetails>
    </UIAccordion>
  );
}

export default EditSiteTestDetails;
