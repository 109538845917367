import React, { Component } from 'react';
import './Queue.css';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import TablePagination from '@material-ui/core/TablePagination';
import axios from 'axios';
import { capitalize } from '@material-ui/core';
class Queue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queueresult: [],
      filter: '',
      offset: 0,
      parPage: 5,
      currentPage: 0,
      rowsPerPage: 5,
      page: 0,
      filteredData: [],
      is_Queue_data: false,
      datanew: [],
    };
    // this.deleteRow = this.deleteRow.bind(this);
  }
  deleteRow(index) {
    let queueresult = [...this.state.queueresult];
    var selectedSite = sessionStorage.getItem('selectedSite');
    var os = this.state.queueresult[index].osName;
    var execution_id = this.state.queueresult[index].executionId;
    axios.get(
      window.configs.TRIGGER_SERVICE_URL +
        'v2/cancel-job/' +
        selectedSite +
        '/' +
        os +
        '/' +
        execution_id
    );
    //console.log(this.state.queueresult)
    queueresult.splice(index, 1);
    this.setState({ queueresult: queueresult });
    this.setState({ filteredData: queueresult });
  }
  formateJson = () => {
    const allData = [];
    const data = this.state.datanew;
    const os = Object.keys(data);
    os.forEach((key) => {
      //console.log("os keys", data[key]);
      const ecArr = data[key];
      data[key].Env = ecArr.environmentInfo.environment;
      data[key].Suite = ecArr.environmentInfo.suite;
      data[key].Browser = ecArr.environmentInfo.browserName;
      data[key].osName = ecArr.environmentInfo.os;
      if(data[key].status.toLowerCase() !== "started"){
        allData.push(data[key]);
      }
    });
    this.setState({ queueresult: allData });
    this.setState({ filteredData: allData });
  };
  storealldata = () => {
    const dashboard = [];
    this.state.queueresult.forEach((val, ind, arr) => {
      dashboard.push(val);
    });
  };

  handleChange = (event) => {
    this.setState({ filter: event.target.value }, ()=>{
      const lowercasedFilter = this.state.filter.toLowerCase();
      let filteredData = [];
      // console.log("queue->", JSON.stringify(this.state.queueresult));
      filteredData = this.state.queueresult.filter((item) => {
        return Object.keys(item).some((key) =>
          typeof item[key] == "string"
          ?((key != "site" && key != "project" && key != "executionCombination") && item[key].toLowerCase().includes(lowercasedFilter))
          :(key == "environmentInfo"
          && Object.keys(item[key]).some((i) =>
           (i != "siteShortName" && new String(item[key][i]).toLowerCase().includes(lowercasedFilter)))
          )
        );
      });
      this.setState({ filteredData: filteredData });
    });
  };
  handleSortAsc = (event, sortKey) => {
    const data = this.state.queueresult;
    data.sort((a, b) => a[sortKey].localeCompare(b[sortKey]));
    this.setState({ queueresult: data });
  };
  handleSortDsc = (event, sortKey) => {
    const data = this.state.queueresult;
    data.sort((a, b) => b[sortKey].localeCompare(a[sortKey]));
    this.setState({ queueresult: data });
  };
  handleChangePage = (event, newPage) => {
    //console.log("newPage", newPage);
    this.setState({ page: newPage });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value, page: 0 });
  };

  componentDidMount() {
    this.state.queueresult.forEach((val) => {
      //console.log("dddddd", val);
    });
    var site = sessionStorage.getItem('selectedSite');
    axios
      .get(window.configs.BASE_API_URL + '/report-service/v3/queued-status/' + site)
      .then((result) => {
        var data = result.data;
        this.setState({
          datanew: data,
          is_Queue_data: true,
        },() => {
          this.formateJson();
          this.storealldata();
        });
      })
      .catch((error) =>
        {
          if (error.response) {
            this.setState({
              error,
              is_Queue_data: false,
            })
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
        } else if (error.request) {
          this.setState({
            error,
            is_Queue_data: false,
          })
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the 
            // browser and an instance of
            // http.ClientRequest in node.js
            console.log(error.request);
        } else {
          this.setState({
            error,
            is_Queue_data: false,
          })
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        }
       
      );
  }
  render() {
    let filteredData = [...this.state.filteredData];
    const myStyel = {
      fontSize: 'small',
      color: 'rgba(0, 0, 0, 0.87)',
      display: 'inline-block',
      cursor: 'pointer'
    };
    return (
      <React.Fragment>
        <div className="card  custcard">
          <div className="cord-body">
            <div className="testHeading">
              <label className="headermsg1" id="TestResult">
                View Scheduled Tests
              </label>
            </div>
          </div>
        </div>

        {this.state.is_Queue_data === true && (
          <div className="card">
            <div className="card-body" style={{ padding: '0px' }}>
              <div className="MainSearch">
                <label>
                  <input
                    type="search"
                    className="search"
                    placeholder="Search"
                    value={this.state.filter}
                    onChange={this.handleChange}
                  />
                </label>
              </div>
              <TableContainer component={Paper}>
                <table arial-label="simple table" className="QueueTable">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        align="center"
                        style={{ position: 'relative' }}
                      >
                        Env
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'Env')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'Env')}
                          />
                        </span>
                        
                      </TableCell>
                      <TableCell align="center">
                        Suite
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'Suite')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'Suite')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        OS
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'osName')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'osName')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Browser
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'Browser')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'Browser')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Form Factor
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'Formfactor')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'Formfactor')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Type
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'type')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'type')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Device
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'DeviceName')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'DeviceName')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        User
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'user')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'user')}
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Request Time
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) =>
                              this.handleSortAsc(e, 'triggeredDateTime')
                            }
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) =>
                              this.handleSortDsc(e, 'triggeredDateTime')
                            }
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Scheduled Time
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) =>
                              this.handleSortAsc(e, 'scheduledDateTime')
                            }
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) =>
                              this.handleSortDsc(e, 'scheduledDateTime')
                            }
                          />
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        Status
                        <span className='sorting-icon-holder'>
                          <ArrowDropUpIcon
                            viewBox="0 -8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortAsc(e, 'status')}
                          />
                          <ArrowDropDownIcon
                            viewBox="0 8 24 24"
                            style={myStyel}
                            onClick={(e) => this.handleSortDsc(e, 'status')}
                          />
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredData
                      .slice(
                        this.state.page * this.state.rowsPerPage,
                        this.state.page * this.state.rowsPerPage +
                          this.state.rowsPerPage
                      )
                      .map(
                        (result, i) =>
                          result.status.toLowerCase() !== 'started' && (
                            <TableRow key={i}>
                              <TableCell>
                                <span className="env"> {result.Env}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="tag">{result.Suite}</span>
                              </TableCell>
                              <TableCell align="center">
                              {result.testType != "api" && 
                                <img
                                  className="queuepageimg"
                                  alt="os"
                                  src={`../images/${
                                    result.osName
                                      .toLowerCase()
                                      .search('windows') !== -1
                                      ? 'windows'
                                      : result.osName
                                          .toLowerCase()
                                          .search('linux') !== -1
                                      ? 'linux'
                                      : result.osName
                                        .toLowerCase()
                                        .search('android') !== -1
                                      ? 'android'
                                      : result.osName
                                        .toLowerCase()
                                        .search('mac') !== -1
                                      ? 'mac'
                                      : 'ios'
                                  }.png `}
                                  title={`${
                                    result.osName
                                      .toLowerCase()
                                      .search('windows') !== -1
                                      ? 'Windows'
                                      : result.osName
                                          .toLowerCase()
                                          .search('linux') !== -1
                                      ? 'Linux'
                                      : result.osName
                                        .toLowerCase()
                                        .search('android') !== -1
                                      ? 'Android'
                                      : result.osName
                                        .toLowerCase()
                                        .search('mac') !== -1
                                      ? 'Mac'
                                      : 'Ios'
                                  }`}
                                />
                              }
                              </TableCell>
                              <TableCell align="center">
                              {result.testType != "api" && result.Browser != "" &&
                                <img
                                  className="queuepageimg"
                                  alt="browser"
                                  src={`../images/${
                                    result.Browser.charAt(0).toLowerCase() +
                                    result.Browser.slice(1)
                                  }.png`}
                                  title={`${
                                    capitalize(result.Browser.charAt(0).toLowerCase() +
                                    result.Browser.slice(1))
                                  }`}
                                />
                                }
                              </TableCell>
                              <TableCell align="center">
                                  {result.testType != "api" && 
                                    <img
                                    className={((typeof result.environmentInfo.deviceOrientation) == "undefined")?"queuepageimg":("queuepageimg " + result.environmentInfo.deviceOrientation.toLowerCase())}  
                                    alt="browser"
                                    src={`../images/${
                                      result.environmentInfo.formFactor
                                    }.png`}
                                    title={`${
                                      capitalize(result.environmentInfo.formFactor +  (result.environmentInfo.deviceOrientation===""?" ":(typeof result.environmentInfo.deviceOrientation=="undefined"?"":(", "+capitalize(result.environmentInfo.deviceOrientation)))))
                                    }`}
                                    />
                                }
                              </TableCell>
                              <TableCell align="center">
                                  {result.testType == "api" && 
                                    <img
                                      className={"queuepageimg"}  
                                      alt="browser"
                                      src={`../images/${
                                        result.testType
                                      }.png`}
                                      title={`${
                                        capitalize(result.testType)
                                      }`}
                                      />
                                  }
                              </TableCell>
                              <TableCell align="center">
                                <span className="tag">{result.environmentInfo.deviceName}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span className="tag">{result.user}</span>
                              </TableCell>
                              <TableCell align="center">
                                {result.triggeredDateTime}
                              </TableCell>
                              <TableCell align="center">
                                {result.scheduledDateTime}
                              </TableCell>
                              <TableCell align="center">
                                <Button
                                  variant="contained"
                                  color="secondary"
                                  style={{
                                    width: '110px',
                                    height: '38px',
                                    color: '#1976D2 !important',
                                  }}
                                  onClick={() => {
                                    this.deleteRow(i);
                                  }}
                                  className="btn-cust"
                                >
                                  {result.status}
                                  <HighlightOffIcon className="cancel_svg" />
                                </Button>
                              </TableCell>
                            </TableRow>
                          )
                      )}
                  </TableBody>
                </table>
              </TableContainer>
              <div></div>

              <TablePagination
                rowsPerPageOptions={[5, 10]}
                component="div"
                count={this.state.filteredData.length}
                rowsPerPage={this.state.rowsPerPage}
                page={this.state.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
        {this.state.is_Queue_data === false && (
          <div className="card error_queue">Waiting to get Queue list...</div>
        )}
      </React.Fragment>
    );
  }
}
export default Queue;
