import React, { Component } from "react";
import Tour from "reactour";

class ReacTour extends Component {
    constructor() {
        super();
        this.state = {
            isTourOpen: true
        };
    }

    closeTour = () => {
        this.setState({ isTourOpen: false });
    };

    openTour = () => {
        this.setState({ isTourOpen: true });
    };

    render() {
        const { isTourOpen } = this.state;
        const accentColor = "#5cb7b7";
        return (
            <div>
               
                <Tour
                    onRequestClose={this.closeTour}
                    steps={tourConfig}
                    isOpen={isTourOpen}
                    className="helper"
                    rounded={5}
                    accentColor={accentColor}
                />
            </div>
        );
    }
}

const tourConfig = [
    {
        selector: '.navbar-toggler',
        content: 'Click here to access the user Profile',
    },
    {
        selector: '#siteSelect .list-group-item.active',
        content: 'Click here to change the site',
    }
];

export default ReacTour;
