import React, { Component } from 'react'
import Grid from '@material-ui/core/Grid';

class ApiAssertion extends Component {
    constructor(props) {
        super(props)
        this.state = {
            panel: "panel",
            req_switched: false,
            res_switched: false,
        }
    }
    render() {
        let { apiresponse } = this.props;
        return (
            <Grid container spacing={0} >
                <Grid item xs={12} >
                    {
                        apiresponse &&

                        <Grid container spacing={0} >
                            <Grid item xs={12} className='small-text'>
                                <div>
                                    <table className='assertion'>
                                        <tbody>
                                            <tr>
                                                <th>Assertion type</th>
                                                <th>Assertion Message</th>
                                                <th>Status</th>
                                            </tr>
                                            {Object.keys(apiresponse.apiAssertions.assertions).map((item, key) => {
                                                return <tr><td>{apiresponse.apiAssertions.assertions[item].assertionType}</td><td>{apiresponse.apiAssertions.assertions[item].assertionMessage}</td><td className={apiresponse.apiAssertions.assertions[item].assertionStatus}><span>{apiresponse.apiAssertions.assertions[item].assertionStatus}</span></td></tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Grid>
        )
    }
}

export default ApiAssertion
