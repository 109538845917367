import React, { Component } from 'react';
import axios from 'axios';
import {Redirect } from 'react-router-dom';
export class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passcode: this.props.match.params.passcode,
      isValide: false,
      Expired: false
    };

  }
  componentDidMount() {
    const validatepasscode = axios.get(window.configs.VALIDATE_PASSCODE + this.state.passcode);
    validatepasscode.then((res) => {
      this.setState({isValide: (res.status===202 || res.status===200)})
      localStorage.setItem('isPasswordReset', true)
      localStorage.setItem('isValidPasscode', (res.status===202 || res.status===200))
      localStorage.setItem('Passcode', this.props.match.params.passcode)
    })
    .catch((err) => {
      console.log(err);
      this.setState({
        Expired: true,
      });
    });
  }
  clickhandler(){
    localStorage.removeItem('isPasswordReset')
    localStorage.removeItem('isValidPasscode')
    localStorage.removeItem('Passcode')
    this.props.history.push('/login');
  }
  render() {
    if(this.state.isValide){
      this.props.history.push('/login');
    }
    return (
    <div>
      {this.state.isValide && <div>
        Valid
        </div>}
      {this.state.Expired && <div style={{marginTop:"50px", backgroundColor: "#fff", display: "inline-block", padding:"50px 30px 50px 30px", fontSize:"16px", borderRadius:"5px", boxShadow:"0px 0px 5px rgba(0,0,0,0.15)"}}>
        The link you are trying to access is expired. Please <a href="javascript:;" onClick={(e) => this.clickhandler()}>click here</a>.
        </div>}
    </div>
    );
  }
}
export default ResetPassword;